export const numbers = [
  {
    value: "30.000",
    text: "Immobili a tua disposizione",
  },
  {
    value: "100%",
    text: "Sicurezza acquisto",
  },
  {
    value: "25%",
    text: "Risparmio medio ottenuto",
  },
  {
    value: 107,
    text: "Consulenti immobiliari dedicati",
  },
];
