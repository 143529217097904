import axios from "axios";
import api from "api/subscriptions";
import moment from "moment";

const GET_SUBSCRIPTION_REQUEST = "ASTAEXPERT/GET_SUBSCRIPTION_REQUEST";
const GET_SUBSCRIPTION_SUCCESS = "ASTAEXPERT/GET_SUBSCRIPTION_SUCCESS";
const GET_SUBSCRIPTION_FAILURE = "ASTAEXPERT/GET_SUBSCRIPTION_FAILURE";

const SUBSCRIBE_REQUEST = "ASTAEXPERT/SUBSCRIBE_REQUEST";
const SUBSCRIBE_SUCCESS = "ASTAEXPERT/SUBSCRIBE_SUCCESS";
const SUBSCRIBE_FAILURE = "ASTAEXPERT/SUBSCRIBE_FAILURE";

const RENEW_SUBSCRIPTION_REQUEST = "ASTAEXPERT/RENEW_SUBSCRIPTION_REQUEST";
const RENEW_SUBSCRIPTION_SUCCESS = "ASTAEXPERT/RENEW_SUBSCRIPTION_SUCCESS";
const RENEW_SUBSCRIPTION_FAILURE = "ASTAEXPERT/RENEW_SUBSCRIPTION_FAILURE";

const DELETE_SUBSCRIPTION_REQUEST = "ASTAEXPERT/DELETE_SUBSCRIPTION_REQUEST";
const DELETE_SUBSCRIPTION_SUCCESS = "ASTAEXPERT/DELETE_SUBSCRIPTION_SUCCESS";
const DELETE_SUBSCRIPTION_FAILURE = "ASTAEXPERT/DELETE_SUBSCRIPTION_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const reducerUser = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        data: initialState.data,
        error: action.payload,
      };
    case SUBSCRIBE_REQUEST:
      return { ...state, loading: true, error: false };
    case SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SUBSCRIBE_FAILURE:
      return {
        ...state,
        loading: false,
        data: initialState.data,
        error: action.payload,
      };
    case RENEW_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true, error: false };
    case RENEW_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          validTo: moment(state.data.validTo).add(1, "M"),
        },
      };
    case RENEW_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        data: initialState.data,
        error: action.payload,
      };
    case DELETE_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true, error: false };
    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, numeroContratto: null },
      };
    case DELETE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducerUser;

export const getSubscription = () => async (dispatch) => {
  dispatch({
    type: GET_SUBSCRIPTION_REQUEST,
  });
  try {
    const { url, method, headers } = api.getSubscription();

    const response = await axios({
      url,
      method,
      headers,
    });

    if (response) {
      dispatch({
        type: GET_SUBSCRIPTION_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: GET_SUBSCRIPTION_FAILURE,
        payload: "Couldn't find subscription",
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SUBSCRIPTION_FAILURE,
      payload: error,
    });
  }
};

export const subscribe = (subscriptionId) => async (dispatch) => {
  dispatch({
    type: SUBSCRIBE_REQUEST,
  });
  try {
    const { url, method, headers, data } = api.subscribe(subscriptionId);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });

    if (response) {
      dispatch({
        type: SUBSCRIBE_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: SUBSCRIBE_FAILURE,
        payload: "Couldn't find subscription",
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SUBSCRIPTION_FAILURE,
      payload: error,
    });
  }
};
export const renewSubscription = () => async (dispatch) => {
  dispatch({
    type: RENEW_SUBSCRIPTION_REQUEST,
  });
  try {
    const { url, method, headers } = api.renewSubscription();

    const response = await axios({
      url,
      method,
      headers,
    });

    if (response) {
      dispatch({
        type: RENEW_SUBSCRIPTION_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: RENEW_SUBSCRIPTION_FAILURE,
        payload: "Couldn't find subscription",
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SUBSCRIPTION_FAILURE,
      payload: error,
    });
  }
};

export const deleteSubscription = () => async (dispatch) => {
  dispatch({
    type: DELETE_SUBSCRIPTION_REQUEST,
  });
  try {
    const { url, method, headers } = api.deleteSubscription();

    const response = await axios({
      url,
      method,
      headers,
    });
    if (response?.statusText !== "KO") {
      dispatch({
        type: DELETE_SUBSCRIPTION_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: DELETE_SUBSCRIPTION_FAILURE,
        payload:
          "Errore durante l'annullamento dell'abbonamento, riprova tra qualche minuto. Se il problema persiste contattata il supporto clienti.",
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_SUBSCRIPTION_FAILURE,
      payload: error.response.status,
    });
  }
};
