import { Fade } from "react-reveal";
import "./ComparisonSavings.scss";
import Plans from "pages/home/plans/Plans";
import { hero, services } from "./items";
import Hero from "./hero/Hero";
import Services from "pages/why-asta/components/services/Services";

const ComparisonSavings = () => {
  return (
    <div className="comparison-savings-page">
      {/* <Fade> */}
      <Hero hero={hero} />

      <Services services={services} />

      <Plans />
      {/* </Fade> */}
    </div>
  );
};

export default ComparisonSavings;
