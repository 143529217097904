import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

const PrivateRoute = ({ children, ...rest }) => {
  const user = useSelector(({ user }) => user.data.user);

  return (
    <Route
      {...rest}
      render={() => (user ? children : <Redirect to="/accedi" />)}
    />
  );
};

export default PrivateRoute;
