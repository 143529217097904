import BannerBenefits from "components/banner-benefits/BannerBenefits";
import PricePlans from "components/price-plans/PricePlans";
import { benefits, services, hero } from "./items";
import Hero from "../components/hero/Hero";
import Services from "../components/services/Services";
import Contacts from "../components/contacts/Contacts";
import ContactUs from "../components/contact-us/ContactUs";
// import { Fade } from "react-reveal";

const ForAgents = () => (
  <div className="for-agents-page">
    {/* <Fade> */}
    <Hero hero={hero} />

    <BannerBenefits benefits={benefits} />

    <Services services={services} />

    <Contacts />

    <PricePlans target="agent" />

    <ContactUs />
    {/* </Fade> */}
  </div>
);

export default ForAgents;
