import "./Account.scss";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  NavLink,
} from "react-router-dom";
import { useSelector } from "react-redux";
import AccountWishlist from "./wishlist/AccountWishlist";
import AccountResearches from "./researches/AccountResearches";
import AccountVisits from "./visits/AccountVisits";
import AccountAuctions from "./auctions/AccountAuctions";
import AccountData from "./data/AccountData";
import AccountSubscription from "./subscription/AccountSubscription";
import configurations from "../../configurations/index";
// import AccountPreferences from "./preferences/AccountPreferences";

const Account = () => {
  let { path, url } = useRouteMatch();
  const isPaying =
    useSelector(({ user }) => user.data.user.role) === "paying_user";
  const isAgent = useSelector(({ user }) => user.data.user.role === "agent");

  // const filterLinks = (list) =>
  //   isPaying ? list.filter(({ paying }) => paying) : list;

  return (
    <div className="account-page">
      <div className="container pad-responsive">
        <div className="account-heading">
          <h1 className="general">Il mio account</h1>

          <div className="navigation">
            {isAgent && (
              <a
                href={configurations.adminUrl}
                className="btn orange uppercase"
              >
                Admin
              </a>
            )}
            {links
              .filter((link) => (!isPaying ? !link.paying : true))
              .map((element, i) => (
                <NavLink key={i} activeClassName="active" to={url + element.to}>
                  {element.name}
                </NavLink>
              ))}
          </div>
        </div>

        <div className="section">
          <Switch>
            <Route
              path={`${path}/abbonamento`}
              component={AccountSubscription}
            />
            <Route path={`${path}/wishlist`} component={AccountWishlist} />
            <Route path={`${path}/ricerche`} component={AccountResearches} />
            {/* <Route
              path={`${path}/preferenze-immobile`}
              component={AccountPreferences}
            /> */}
            <Route path={`${path}/visite`} component={AccountVisits} />
            <Route path={`${path}/aste`} component={AccountAuctions} />
            <Route path={`${path}/dati-utente`} component={AccountData} />
            <Redirect to={`${url}/${isPaying ? "dati-utente" : "wishlist"}`} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Account;

const links = [
  {
    name: "Preferiti",
    to: "/wishlist",
  },
  {
    name: "Ricerche Salvate",
    to: "/ricerche",
  },
  // {
  //   name: "Preferenze immobile da ricerca",
  //   to: "/preferenze-immobile",
  // },
  {
    name: "Richieste Visita Immobile",
    to: "/visite",
    paying: true,
  },
  {
    name: "Richieste Partecipazione Asta",
    to: "/aste",
    paying: true,
  },
  {
    name: "Abbonamento",
    to: "/abbonamento",
    // paying: true,
  },
  {
    name: "Dati utente",
    to: "/dati-utente",
    // paying: true,
  },
];
