import "./Plans.scss";
import PricePlans from "components/price-plans/PricePlans";

const Plans = () => (
  <div className="home-page__plans section pad-responsive">
    <h2 className="general">I nostri pacchetti</h2>

    <PricePlans target="user" />
  </div>
);

export default Plans;
