import "./Hero.scss";
import { Fade } from "react-reveal";

const Hero = () => (
  <Fade>
    <div className="why-asta__hero container-fluid pad-responsive">
      <h1 className="big">Perché astaéxpert</h1>
      <p>
        Migliaia di immobili all'asta ricercabili da un portale unico in
        costante aggiornamento e fruibile sia da web che da app che garantisce a
        investitori e agenti immobiliari il più completo servizio per monitorare
        e cogliere le opportunità immobiliari del mercato. Ottieni il massimo
        risparmio senza rischi ne pensieri: comprare casa in asta può diventare
        con noi davvero semplice. Che tu sia un cliente, un avvocato o un agente
        immobiliare, astaéxpert ti offre tutti gli strumenti per lavorare in
        serenità con l'obiettivo di realizzare un vantaggio significativo, sia
        che si tratti di comprare casa, che di monitorare il mercato piuttosto
        che di trovare potenziali acquirenti.
      </p>
    </div>
  </Fade>
);

export default Hero;
