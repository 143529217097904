import "./MapDensity.scss";
import { useState } from "react";
import { ReactComponent as Map } from "assets/map.svg";

const MapDensity = () => {
  const [active, setActive] = useState(false);

  return (
    <div className="map-density-component">
      <Map className={active ? "active" : ""} />

      <div className="change-view">
        <div
          className={`background ${!active ? "active" : ""}`}
          onClick={() => setActive(false)}
        >
          <div className="text">Senza astaéxpert</div>
        </div>

        <div
          className={`background ${active ? "active" : ""}`}
          onClick={() => setActive(true)}
        >
          <div className="text">Con astaéxpert</div>
        </div>
      </div>
    </div>
  );
};

export default MapDensity;
