export function headLineRole(title) {
  switch (title) {
    case "agent":
      return "Ag.";

    case "lawyer":
      return "Avv.";
    case "operator":
      return "Op.";
    default:
      return "";
  }
}

export function headLineCity(city) {
  switch (city) {
    case "Bergamo":
      return "HQ - ";
    default:
      return "";
  }
}
