import "./PricePlans.scss";
import { setCart } from "reducers/cart";
import { Check, X } from "phosphor-react";
import Advice from "./advice/Advice";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import requests from "api";
import useComponentVisible from "utils/useComponentVisible";

const cardFree = {
  type: "Free",
  price: "Gratuito",
  baseFeatures: [
    "Aggiungi gli immobili alla tua lista dei desideri personale",
    "Salva le tue ricerche preferite",
    "Email settimanale con riassunto aste disponibili",
  ],
};

const PricePlans = ({ target }) => {
  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(false, true);
  const dispatch = useDispatch();
  const history = useHistory();
  const [cards, setCards] = useState([]);
  const user = useSelector(({ user }) => user?.data?.user);

  useEffect(() => {
    const getPricePlans = async () => {
      let response = await requests.getPricePlans(target);
      if (response) {
        if (target === "user") {
          cardFree.features = response[0]?.features;
          response[0].baseFeatures = cardFree.baseFeatures;
          setCards([cardFree, response[0]]);
          // setCards([response[0]]);
        } else {
          setCards(response);
        }
      }
    };
    getPricePlans();
  }, [target]);

  const openBanner = () => {
    document.body.classList.add("no-scroll");
    setIsComponentVisible(true);
  };

  const closeBanner = () => {
    document.body.classList.remove("no-scroll");
    setIsComponentVisible(false);
  };

  const handleBuyClick = async (cardData) => {
    document.body.classList.toggle("no-scroll");
    await dispatch(setCart(cardData));
    history.push("/carrello");
  };

  const handleBuyClickNoScroll = async (cardData) => {
    await dispatch(setCart(cardData));
    history.push("/carrello");
  };

  const handleBuy = () => {
    const id = target === "user" ? 1 : 0;
    // handleBuyClickNoScroll(...cards);
    handleBuyClickNoScroll(cards[1]);
  };

  return (
    <div
      // className="price-plans-component section"
      className={
        target === "agent"
          ? "price-plans-component section pad-responsive"
          : "price-plans-component section"
      }
      style={{
        gridTemplateColumns: `repeat(${cards.length}, minmax(0, 450px))`,
      }}
    >
      {isComponentVisible && (
        <div className="overlay">
          <Advice
            ref={ref}
            cards={cards}
            handleBuyClick={handleBuyClick}
            closeBanner={closeBanner}
          />
        </div>
      )}

      {cards.map((element, i) => (
        <div
          key={i}
          className={`card ${element?.type === "Premium" ? "premium" : ""}`}
        >
          <h3>
            {target === "agent" && "Licenza"} astaéxpert{" "}
            <span>{element?.type}</span>
          </h3>

          <div className="bottom">
            {element?.type !== "Free" ? (
              <div>
                <h4 className="premium" style={{ marginBottom: 8 }}>
                  Vuoi un'assistenza personalizzata?
                </h4>
                <h2 className="premium" style={{ position: "relative" }}>
                  <span>€ </span>
                  <span>
                    {element?.price}
                    {element?.target === "agent" && (
                      <span className="iva"> (+ iva)</span>
                    )}
                  </span>

                  <span>
                    al mese <div className="cancel">*Disdici quando vuoi</div>
                  </span>
                </h2>
              </div>
            ) : (
              <h2 className="free">{element?.price}</h2>
            )}

            <div className="info-list">
              {element?.freeFeatures?.map((info, i) => (
                <div key={i} className="info">
                  <Check weight="bold" />
                  <span>{info}</span>
                </div>
              ))}

              {element?.baseFeatures
                .filter((element) => !!element)
                .map((info, i) => (
                  <div key={i} className="info">
                    <Check weight="bold" />
                    <span>{info}</span>
                  </div>
                ))}
              {element?.features
                .filter((element) => !!element)
                .map((info, i) =>
                  element?.price === "Gratuito" ? (
                    <div key={i} className="info not-checked">
                      <X weight="bold" />
                      <span>{info}</span>
                    </div>
                  ) : (
                    <div key={i} className="info">
                      <Check weight="bold" />
                      <span>{info}</span>
                    </div>
                  )
                )}
            </div>

            {element?.price === "Gratuito" ? (
              <div
                className="btn empty center"
                onClick={() => !user && openBanner()}
              >
                {!!user ? "Attivo" : "Inizia"}
              </div>
            ) : (
              <div onClick={handleBuy} className="btn orange center">
                Inizia la consulenza
              </div>
            )}

            {/* <div className="cancel">*Disdici quando vuoi</div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricePlans;
