import { useEffect, useState } from "react";
import Carousel from "pages/home/carousel/Carousel";
import BannerPremium from "components/banner-premium/BannerPremium";
import axios from "axios";
import Hero from "./hero/Hero";
import AppSection from "./app-section/AppSection";
import Why from "./why/Why";
import Plans from "./plans/Plans";
import { useSelector } from "react-redux";
import api from "api/real-estate";

const Home = () => {
  // const [chosen, setChosen] = useState([]);
  const [expiring, setExpiring] = useState([]);
  const [loading, setLoading] = useState(true);
  // const userNotPaying =
  //   useSelector(({ user }) => user?.data?.user?.role) !== "paying_user";
  const isAgent = useSelector(({ user }) => user?.data?.user?.role) === "agent";

  useEffect(() => {
    if (!isAgent) {
      const getRealEstatesHome = async () => {
        const { url, method, headers } = api.getRealEstatesHome();
        const { data } = await axios({ url, method, headers });
        // setChosen(data.chosen);
        setExpiring(data.expiring);
      };
      getRealEstatesHome();
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="home-page">
      <Hero />

      <Carousel
        title="Vetrina aste in prossima scadenza"
        loading={loading}
        list={expiring}
      />

      {/* {userNotPaying && <Plans />} */}

      {/* {chosen.length > 0 && (
        <Carousel
          refArrow="chosen"
          list={chosen}
          loading={loading}
          title="Vetrina aste più viste"
          isChosen
          sort="views:desc"
        />
      )} */}

      <AppSection />

      <Why />

      {/* {userNotPaying && <BannerPremium />} */}
      <BannerPremium />
    </div>
  );
};

export default Home;
