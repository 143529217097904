import "./LegalList.scss";
import { Fade } from "react-reveal";

const LegalList = () => (
  <div className="services-page__legal-list container section short pad-responsive">
    <Fade left>
      <div className="illustration"></div>
    </Fade>

    <Fade>
      <div className="description">
        <h5>Il servizio di assistenza e consulenza asta prevede:</h5>

        <ul>
          <li>
            Presa in carico della richiesta da parte di un avvocato astaéxpert
            specializzato che sarà il punto di riferimento per la partecipazione
            all'asta
          </li>
          <li>
            Analisi perizia e valutazione tecnico legale pre-asta dell'immobile
            scelto
          </li>
          <li>
            Analisi economica /business plan di dettaglio personalizzato per la
            partecipazione all'asta con indicazione dei rilanci massimi
            possibili in base al proprio limite di spesa
          </li>
          <li>
            Consulenza informativa pre-asta e preparazione tecnico/informatica
            per la partecipazione all'asta telematica
          </li>
          <li>Assistenza partecipazione all'asta telematica e/o mista</li>
          <li>Richiesta verbale di aggiudicazione provvisoria</li>
        </ul>
      </div>
    </Fade>
  </div>
);

export default LegalList;
