import { Checkbox, Input } from "components/form/Form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createUser, loginUser } from "reducers/user";
import "./SignUp.scss";
import Spinner, { SpinnerSmall } from "components/spinner/Spinner";

const inputs = [
  { target: "firstname", placeholder: "Nome", type: "text" },
  { target: "lastname", placeholder: "Cognome", type: "text" },
  { target: "email", placeholder: "Email", type: "email" },
  { target: "password", placeholder: "Password", type: "password" },
];

const checkboxes = [
  {
    id: "privacy",
    target: "hasAcceptedPrivacyPolicy",
    label: "Accetto il trattamento dei dati sulla privacy",
  },
  {
    id: "marketing",
    target: "hasAcceptedMarketing",
    label: "Accetto il trattamento dei dati per finalità di marketing",
  },
];

export const SignUp = ({ history }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.data);
  const loading = useSelector(({ user }) => user.loading);
  const error = useSelector(({ user }) => user.error);
  const [form, setForm] = useState({ errorMessage: false });

  useEffect(() => {
    document.body.classList.remove("no-scroll");

    if (user.authToken) {
      history.push("/");
    } else {
      dispatch(loginUser());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user.authToken) {
      if (localStorage.getItem("cartItem")) {
        history.push({
          pathname: "/carrello",
          state: { cart: true },
        });
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line
  }, [user.authToken]);

  useEffect(() => {
    if (error && error !== "NO_USER_IN_LOCAL_STORAGE") {
      setForm({ ...form, errorMessage: true });
    }
    // eslint-disable-next-line
  }, [error]);

  const handleChangeInput = (target, value) => {
    setForm({ ...form, [target]: value });
  };

  const isDisabled = () => {
    const inputStatus = inputs.map(
      (input) => !!form[input.target] && form[input.target] !== ""
    );
    const inputAreValid = inputStatus.every((input) => !!input);
    const checkboxesAreValid = form.hasAcceptedPrivacyPolicy;
    return !inputAreValid || !checkboxesAreValid;
  };

  const handleSubmit = async () => {
    try {
      const {
        email,
        password,
        firstname,
        lastname,
        hasAcceptedPrivacyPolicy,
        hasAcceptedMarketing,
      } = form;
      dispatch(
        createUser({
          email: email,
          password: password,
          firstname: firstname,
          lastname: lastname,
          hasAcceptedPrivacyPolicy: hasAcceptedPrivacyPolicy,
          hasAcceptedMarketing: hasAcceptedMarketing
            ? hasAcceptedMarketing
            : false,
        })
      );
    } catch (err) {
      console.log(err);
      console.log(err.response);
      console.log(err.message);
    }
  };

  return (
    <div className="sign-up-page pad-responsive">
      <div className="intro section">
        <h1 className="big">Registrati</h1>

        <div className="description">
          <h3 className="general">
            Sei già registrato?
            <Link className="link" to="/accedi">
              Effettua l'accesso qui
            </Link>
          </h3>
        </div>
      </div>

      <div className="form-component section">
        {inputs.map((input, i) => (
          <Input
            key={`input_${i}`}
            target={input.target}
            type={input.type}
            placeholder={input.placeholder}
            handleChangeInput={handleChangeInput}
          />
        ))}

        {form.errorMessage && <div className="error">{error}</div>}

        {checkboxes.map((checkbox, i) => (
          <Checkbox
            id={`checkbox_${i}`}
            key={`checkbox_${i}`}
            target={checkbox.target}
            label={checkbox.label}
            handleChangeInput={handleChangeInput}
          />
        ))}

        <button
          onClick={handleSubmit}
          className="btn orange center"
          disabled={isDisabled() || loading}
        >
          {loading ? <SpinnerSmall /> : "Conferma"}
        </button>
      </div>
    </div>
  );
};

export default SignUp;
