import "./Benefits.scss";
import Img2 from "assets/scene/8.svg";
import { CaretDown } from "phosphor-react";
import { useState } from "react";
import { services } from "./items";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";

const Benefits = () => {
  const [active, setActive] = useState(null);

  const handleToggle = (i) => setActive(i === active ? null : i);

  return (
    <Fade>
      <div className="why-asta__benefits container section standard pad-responsive">
        <div className="img">
          <img src={Img2} width="100%" alt="" />
        </div>

        <div className="description">
          <h2 className="general">I servizi che possiamo offrirti</h2>

          <div className="benefits-list">
            {services.map((element, i) => (
              <div
                className={`benefit ${active === i ? "active" : ""}`}
                key={i}
              >
                <div
                  className="benefit__heading"
                  onClick={() => handleToggle(i)}
                >
                  <h5>{element.title}</h5>

                  <CaretDown weight="bold" />
                </div>

                <p>{element.text}</p>
              </div>
            ))}
          </div>

          <Link to="/servizi" className="btn orange">
            Scopri di più
          </Link>
        </div>
      </div>
    </Fade>
  );
};

export default Benefits;
