// import { Fade } from "react-reveal";
import "./AboutUs.scss";
// import Customers from "./customers/Customers";
import Hero from "./hero/Hero";
import Story from "./story/Story";
import Values from "./values/Values";

const AboutUs = () => (
  <div className="about-us-page">
    {/* <Fade> */}
    <Hero />

    <Story />

    <Values />

    {/* TEMPORALY HIDDEN
      <Customers /> */}
    {/* </Fade> */}
  </div>
);

export default AboutUs;
