import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { getAllParticipations } from "reducers/participations";
import "./AccountAuctions.scss";

const AccountAuctions = () => {
  const dispatch = useDispatch();
  const data = useSelector(({ participations }) => participations.data);

  useEffect(() => dispatch(getAllParticipations()), [dispatch]);

  return (
    <Fade>
      {data.length > 0 ? (
        <div className="account__auction-list">
          {data.map((element, i) => (
            <div key={i} className="auction">
              <div className="left">
                <Link to={"/immobile/" + element.realEstate.id}>
                  <h3>{element.realEstate.title}</h3>
                </Link>
              </div>

              <div className="right">
                <span>{checkName(element.status)}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-list">Nessuna partecipazione ad asta</div>
      )}
    </Fade>
  );
};

export default AccountAuctions;

const checkName = (text) => {
  switch (text) {
    case "accepted":
      return "Accettata";
    case "pending":
    case "Pending":
      return "In attesa di conferma";
    case "refused":
      return "Rifiutata";
    default:
      return text;
  }
};
