import { filters } from "components/search-bar/filters";

const cleanParams = (params) => {
  const copy = { ...params };
  delete copy.utm_campaign;
  delete copy.utm_medium;
  delete copy.utm_source;
  // return !!Object.values(copy).length ? copy : null;
  return copy;
};

export const getParamsFromUrl = (string) => {
  string = string.slice(1);
  const array = string.split("&");
  if (array[0] === "") {
    return {};
  }

  return cleanParams(
    array.reduce((acc, curr) => {
      const option = curr.split("=");

      return {
        ...acc,
        [option[0]]: {
          label: checkLabel(option[0], option[1]),
          value: option[1],
        },
      };
    }, {})
  );
};

const checkLabel = (key, value) => {
  switch (key) {
    case "realEstate.city":
    case "realEstateCity":
    case "squarefeetMin":
    case "startingPriceMin":
    case "swimmingPool":
    case "garage":
    case "isChosen":
    case "lift":
    case "cellar":
    case "furnished":
    case "virtualTour":
    case "acousticCertification":
    case "balcony":
    case "garden":
    case "auction.dateMin":
    case "auction.dateMax":
    case "auction.courthouseId":
    // case "utm_campaign":
    // case "utm_medium":
    // case "utm_source":
    case "sort":
      return null;

    default:
      const filter = filters?.find(({ name }) => name === key);
      const matching = filter?.options.find(
        (element) => element.value === value
      );
      return matching?.label;
  }
};
