const Placeholder = () => (
  <div className="ph-item">
    <div className="ph-col-8">
      <div className="ph-picture" style={{ height: 300 }}></div>
    </div>
    <div className="ph-col-4">
      <div className="ph-row">
        <div className="ph-col-12"></div>
        <div className="ph-col-12 empty"></div>
        <div className="ph-col-12"></div>
        <div className="ph-col-12 empty"></div>
        <div className="ph-col-12"></div>
        <div className="ph-col-12 empty"></div>
        <div className="ph-col-12"></div>
        <div className="ph-col-12 empty"></div>
        <div className="ph-col-12"></div>
        <div className="ph-col-12 empty"></div>
        <div className="ph-col-12"></div>
        <div className="ph-col-12 empty"></div>
        <div className="ph-col-12"></div>
        <div className="ph-col-12"></div>
      </div>
    </div>
  </div>
);

export default Placeholder;
