import "./SingleFaq.scss";
import { Minus, Plus } from "phosphor-react";
import { useState } from "react";

const SingleFaq = ({ element }) => {
  const [active, setActive] = useState(false);

  return (
    <div
      className="single-faq-page"
      onClick={() => setActive((state) => !state)}
    >
      <div className="question">
        <p>{element.question}</p>
        {!active ? <Plus weight="bold" /> : <Minus weight="bold" />}
      </div>

      {active && <p className={`answer`}>{element.answer}</p>}
    </div>
  );
};

export default SingleFaq;
