import "./Intro.scss";
import Img1 from "assets/blog.png";
import moment from "moment";
import Carousel from "./carousel/Carousel";

const Intro = ({ post }) => (
  <div className="blog-single__intro pad-responsive only-bottom">
    <div className="section container">
      <Carousel images={post.images || [Img1]} />

      <div className="description">
        <div className="heading-blog-singola">
          <span className="category">Blog</span>

          <span className="date">{moment(post.createdAt).format("L")}</span>
        </div>

        <h1 className="general">{post.title}</h1>
      </div>
    </div>
  </div>
);

export default Intro;
