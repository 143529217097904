import "./Hero.scss";
import { Fade } from "react-reveal";
import ImgLeft from "assets/scene/5.svg";
import ImgRight from "assets/scene/3b.svg";
const Hero = ({ hero }) => (
  <Fade>
    <div className="comparison__hero pad-responsive container section sub-navbar bg-container">
      {/* <div className="img" style={{ backgroundImage: `url(${hero.img})` }}></div>

    <div className="description ">
      {hero.destination && <span className="category">{hero.destination}</span>}

      <h2 className="general">{hero.title}</h2>

      <p>{hero.description}</p>
    </div> */}
      <h1 className="big">{hero.title}</h1>
      <div className="description ">
        {hero.destination && (
          <span className="category">{hero.destination}</span>
        )}
        <p>{hero.description}</p>
      </div>

      <div className="illustration">
        <div className="illustration-left">
          <img src={ImgLeft} alt="" />
        </div>

        <div className="illustration-right">
          <img src={ImgRight} alt="" />
        </div>
      </div>
    </div>
  </Fade>
);

export default Hero;
