import Cheap from "assets/benefits/icona-massimo-risparmio.svg";
import Complete from "assets/benefits/icona-un-servizio-360.svg";
import Time from "assets/benefits/icona-il-tempo-e-denaro.svg";
import Always from "assets/benefits/icona-sempre-con-te.svg";

export const benefits = [
  {
    img: Cheap,
    title: "Massimo Risparmio",
    text: `La compravendita di immobili battuti in asta permette di trovare vere e proprie occasioni: noi di astaéxpert ti aiutamo a trasformare quelle occasioni in risultati concreti garantendo un risparmio sostanziale. `,
  },
  {
    img: Complete,
    title: "Un servizio a 360°",
    text: `Nuovo nel mondo delle aste? Oppure già navigato? Con astaéxpert ti affianchiamo dalla fase iniziale fino all'acquisto con un team di esperti per aiutarti a prendere sempre la decisione giusta. `,
  },
  {
    img: Time,
    title: "Il tempo è denaro!",
    text: "Ogni ora decine di case vengono vendute oppure vengono caricate sui nostri portali: grazie alla nostra area clienti monitori i tuoi immobili preferiti e sai sempre come e quando muoverti.",
  },
  {
    img: Always,
    title: "Sempre con te",
    text: `Dal tuo computer all'app sul tuo telefono: astaéxpert è sempre a portata di mano, gli affari vanno colti al volo!`,
  },
];
