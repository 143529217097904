import { NavigationArrowRight } from "components/navigation/NavigationArrow";
import apartmentPlaceholder from "assets/scene/casa.svg";
import moment from "moment";
import { Clock, File } from "phosphor-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fade from "react-reveal";
import { Link } from "react-router-dom";
import { getAllFavorites } from "reducers/favorites";
import "./AccountWishlist.scss";

const AccountWishlist = () => {
  const dispatch = useDispatch();
  const data = useSelector(({ favorites }) => favorites.data);

  useEffect(() => dispatch(getAllFavorites()), [dispatch]);

  return (
    <Fade>
      {data.length > 0 ? (
        <div className="wishlist">
          {data.map((element, i) => (
            <Link to={"/immobile/" + element.id} key={i}>
              <div className="wishlist-item">
                <div className="left">
                  {element.gallery.length === 0 ? (
                    <div
                      className="apartment-placeholder"
                      style={{
                        background:
                          element.saleType === "free"
                            ? "var(--colorLightBlue)"
                            : "var(--colorOrange)",
                      }}
                    >
                      <img src={apartmentPlaceholder} alt="" />
                    </div>
                  ) : (
                    <img src={element.gallery[0]} alt="" />
                  )}
                  <div className="info-item">
                    <div className="top">
                      <h3>{element.title}</h3>
                    </div>

                    <div className="bottom">
                      {element.auction && (
                        <div className="date-container">
                          <Clock weight="bold" />

                          <span className="date">
                            {moment(element.auction.date).format("L")}
                          </span>
                        </div>
                      )}

                      <span className="general">
                        {element.courthouse &&
                          `${element.courthouse.name} : ${element.courthouse.code}`}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="allegati-list">
                    {element.attachments &&
                      element.attachments.map((item, i) => (
                        <button className="allegato" key={i}>
                          <File weight="bold" />

                          <span>{item.title}</span>
                        </button>
                      ))}
                  </div>

                  <NavigationArrowRight orange />
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="empty-list">
          Non hai ancora inserito nessun immobile tra i preferiti
        </div>
      )}
    </Fade>
  );
};

export default AccountWishlist;
