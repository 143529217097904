export const filters = [
  {
    type: "select2",
    name: "startingPriceMax",
    name2: "startingPriceMin",
    delta: 50000,
    options: [
      { value: "50000", label: "0 - 50.000€" },
      { value: "100000", label: "50.000€ - 100.000€" },
      { value: "150000", label: "100.000€ - 150.000€" },
      { value: "200000", label: "150.000€ - 200.000€" },
      { value: "250000", label: "200.000€ - 250.000€" },
      { value: "300000", label: "250.000€ - 300.000€" },
      { value: "350000", label: "300.000€ - 350.000€" },
      { value: "400000", label: "350.000€ - 400.000€" },
      { value: "450000", label: "400.000€ - 450.000€" },
      { value: "500000", label: "450.000€ - 500.000€" },
    ],
  },
  {
    type: "select2",
    name: "squarefeetMax",
    name2: "squarefeetMin",
    delta: 30,
    options: [
      { value: "30", label: "0 - 30m²" },
      { value: "60", label: "30m² - 60m²" },
      { value: "90", label: "60m² - 90m²" },
      { value: "120", label: "90m² - 120m²" },
      { value: "150", label: "120m² - 150m²" },
      { value: "180", label: "150m² - 180m²" },
      { value: "210", label: "180m² - 210m²" },
      { value: "240", label: "210m² - 240m²" },
      { value: "270", label: "240m² - 270m²" },
    ],
  },
  {
    type: "select",
    advanced: true,
    name: "type",
    options: [
      { value: "appartamento", label: "Appartamento" },
      { value: "attico", label: "Attico" },
      { value: "loft", label: "Loft" },
      { value: "casale", label: "Casale" },
      { value: "villa", label: "Villa" },
      { value: "villetta", label: "Villetta" },
    ],
  },
  {
    type: "select",
    advanced: true,
    name: "rooms",
    options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
    ],
  },
  {
    type: "select",
    advanced: true,
    name: "bathrooms",
    options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
    ],
  },
  {
    type: "select",
    advanced: true,
    name: "heating",
    options: [
      { value: "autonomo", label: "Autonomo" },
      { value: "centralizzato", label: "Centralizzato" },
    ],
  },
  {
    type: "select",
    advanced: true,
    name: "energyEfficiency",
    options: [
      { value: "A", label: "A" },
      { value: "B", label: "B" },
      { value: "C", label: "C" },
    ],
  },
  {
    type: "input-check",
    advanced: true,
    name: "swimmingPool",
  },
  {
    type: "input-check",
    advanced: true,
    name: "garage",
  },
  {
    type: "input-check",
    advanced: true,
    name: "lift",
  },
  {
    type: "input-check",
    advanced: true,
    name: "cellar",
  },
  {
    type: "input-check",
    advanced: true,
    name: "furnished",
  },
  {
    type: "input-check",
    advanced: true,
    name: "virtualTour",
  },
  {
    type: "input-check",
    advanced: true,
    name: "acousticCertification",
  },
  {
    type: "input-check",
    advanced: true,
    name: "balcony",
  },
  {
    type: "input-check",
    advanced: true,
    name: "garden",
  },
  {
    type: "select",
    advanced: true,
    name: "saleType",
    logged: true,
    options: [
      { value: "auction", label: "All'asta" },
      { value: "free", label: "Non in asta" },
    ],
  },
  {
    type: "input-check",
    advanced: true,
    name: "isChosen",
  },
];
