import React from "react";
import "./Popup.scss";

export const Popup = (props) => {
  return (
    <div className="popup-container" {...props}>
      <div className="popup-card">{props.children}</div>
    </div>
  );
};
