import { Fade } from "react-reveal";
import "./Footer.scss";

const Footer = () => (
  <div className="services-page__footer container section short only-bottom pad-responsive">
    <Fade>
      <div className="description">
        <p>
          Grazie alla convenzione con avvocati affiliati al network, questo
          servizio viene proposto ai clienti astaéxpert Premium ad un prezzo
          convenzionato di 299€ (iva inclusa) e si attiva su richiesta del
          cliente. Il servizio studiato a tutela del cliente si rende necessario
          per poter organizzare nei minimi particolari la partecipazione
          all’asta per immobile scelto (per maggiori dettagli visita la sezione
          termini e condizioni).
        </p>
        <p>
          Solo in caso di aggiudicazione/acquisto dell'immobile scelto, è
          previsto nei confronti di astaéxpert, il pagamento di una success-fee
          premio/compenso variabile in funzione del prezzo di perizia
          dell'immobile se in asta, o del prezzo di vendita in caso di immobile
          sul mercato libero (maggiori dettagli visita la sezione termini e
          condizioni).
        </p>
      </div>
    </Fade>

    <Fade right>
      <div className="illustration"></div>
    </Fade>
  </div>
);

export default Footer;
