import "./Why.scss";
import AppIllustration2 from "assets/scene/02smart.png";

const Why = () => (
  <div className="home-page__why section container pad-responsive">
    <div className="app-illustration">
      <img src={AppIllustration2} alt="" />
    </div>

    <div className="app-description">
      <h2 className="general">Perché scegliere astaéxpert?</h2>

      <p>
        Compra la casa al miglior prezzo con astaéxpert: tramite il nostro
        software e la nostra App mobile puoi ricercare sul mercato il miglior
        affare immobiliare, seguito in tutte le fasi da un consulente
        immobiliare esperto. Una volta trovato l’affare, nel servizio, è
        previsto il supporto di un avvocato dedicato per tutti i controlli
        tecnico/legali così da garantire una partecipazione “senza pensieri”.{" "}
        <br />
        In caso di aggiudicazione dell'immobile le commissioni previste dal
        servizio si ripagano con una piccola parte del risparmio generato.
        <br />
      </p>

      <a href="/perche-astaexpert" className="btn orange big center">
        Scopri di più
      </a>
    </div>
  </div>
);

export default Why;
