import "./Intro.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavigationArrowRight } from "components/navigation/NavigationArrow";
import moment from "moment";
import Img1 from "assets/blog.png";
import requests from "api";

const Intro = () => {
  const [post, setPost] = useState({});

  useEffect(() => {
    const getPostChosen = async () => {
      const response = await requests.getPostChosen();
      setPost(response);
    };
    getPostChosen();
  }, []);

  return (
    <div className="blog-page__blog-intro">
      {post && (
        <div className="container section sub-navbar pad-responsive">
          <h1 className="big">Blog</h1>

          <div className="first-new">
            {post.images?.length === 0 ? (
              <img className="placeholder" src={Img1} alt="" />
            ) : (
              <img src={post.images && post?.images[0]} alt="" />
            )}

            <div className="description">
              <div className="heading-first-new">
                <span className="category">News in evidenza</span>
                <span>{moment(post.createdAt).format("L")}</span>
              </div>

              <h2 className="general">{post.title}</h2>

              <p dangerouslySetInnerHTML={{ __html: post.description }} />

              <Link to={"/blog/" + post.id}>
                <span className="uppercase">Leggi di più</span>

                <NavigationArrowRight />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Intro;
