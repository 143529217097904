const SET_CART = "ASTAEXPERT/SET_CART";
const GET_CART = "ASTAEXPERT/GET_CART";
const DELETE_CART = "ASTAEXPERT/DELETE_CART";

const initialState = {
  cart: null,
};

const reducerCart = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART:
      return { ...state, cart: action.payload };

    case GET_CART:
      return { ...state, cart: action.payload };

    case DELETE_CART:
      return { ...state, cart: null };

    default:
      return state;
  }
};

export default reducerCart;

export const setCart = (cardData) => (dispatch) => {
  dispatch({
    type: SET_CART,
    payload: cardData,
  });
  localStorage.setItem("cartItem", true);
  localStorage.setItem("subscription", JSON.stringify(cardData));
};

export const getCart = () => (dispatch) => {
  const cart = localStorage.getItem("subscription");
  dispatch({
    type: GET_CART,
    payload: JSON.parse(cart),
  });
};

export const deleteCart = () => (dispatch) => {
  localStorage.removeItem("subscription");
  dispatch({
    type: DELETE_CART,
  });
};
