import { Fade } from "react-reveal";
import "./Hero.scss";

const Hero = () => (
  <Fade>
    <div className="our-team-page__hero container-fluid section pad-responsive">
      <h1 className="big">La nostra rete</h1>

      <p>
        Il cuore pulsante dietro ogni successo e soddisfazione dei nostri
        clienti.
        <br />
        Un gruppo di professionisti appassionati e dedicati che lavorano insieme
        per fornire servizi immobiliari di altissima qualità.
        <br />
        La nostra missione è quella di aiutarti a realizzare i tuoi sogni in
        modo efficiente e affidabile.
      </p>
    </div>
  </Fade>
);

export default Hero;
