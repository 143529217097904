import { CaretDown } from "phosphor-react";
import { useState } from "react";
import "./Specs.scss";
import { isEmpty } from "lodash";
import { capitalize } from "utils/general";

const Specs = ({ singleRealEstate }) => {
  const [active, setActive] = useState(false);

  return isEmpty(filterRealEstateData(singleRealEstate)) ? null : (
    <div className="info-real-estate__specs">
      <div
        onClick={() => setActive((state) => !state)}
        className="heading-section"
      >
        <h4>Caratteristiche</h4>
        <CaretDown weight="bold" className={active ? "active" : ""} />
      </div>

      {active && (
        <div className="specs-list">
          {singleRealEstate &&
            Object.entries(filterRealEstateData(singleRealEstate))
              .filter((value) => formatValue(value[1]) !== null)
              .map((elem, i) => (
                <div key={i} className="spec">
                  <span>{formatName(elem[0])}</span>
                  <span className="right-spec">{formatValue(elem[1])}</span>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default Specs;

const formatName = (name) => {
  switch (name) {
    case "acousticCertification":
      return "Certificazione acustica";
    case "balcony":
      return "Terrazzo";
    case "bathrooms":
      return "Bagni";
    case "cellar":
      return "Cantina";
    case "energyEfficiency":
      return "Effienca Energetica";
    case "floors":
      return "Piani";
    case "furnished":
      return "Arredata";
    case "garage":
      return "Posto auto";
    case "garden":
      return "Giardino";
    case "heating":
      return "Riscaldamento";
    case "lift":
      return "Ascensore";
    case "rooms":
      return "Locali";
    case "squarefeet":
      return "Superficie";
    case "swimmingPool":
      return "Piscina";
    case "type":
      return "Tipologia";
    case "virtualToor":
      return "Virtual tour";
    default:
      return name;
  }
};

const filterRealEstateData = (data) => {
  const allowed = [
    "acousticCertification",
    "balcony",
    "bathrooms",
    "cellar",
    "energyEfficiency",
    "floors",
    "furnished",
    "garage",
    "garden",
    "heating",
    "lift",
    "rooms",
    "squarefeet",
    "swimmingPool",
    "type",
    // "virtualToor",
  ];

  return Object.keys(data)
    .filter((key) => allowed.includes(key))
    .filter((value) => data[value])
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});
};

const formatValue = (value) => {
  if (typeof value === "boolean") {
    if (value) {
      return "Sì";
    }
    // return "N/D";
  }
  // if (typeof value === "number") {
  //   if (value === 0) {
  //     return "N/D";
  //   }
  // }
  if (typeof value === "string") {
    return capitalize(value);
  }
  return value;
};
