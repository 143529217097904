import { Link } from "react-router-dom";
import "./RealEstateNotFound.scss";
import apartmentPlaceholder from "assets/scene/casa.svg";

const RealEstateNotFound = () => {
  return (
    <div className="real-estate-not-found-page">
      <img
        alt="immagine di una casa"
        src={apartmentPlaceholder}
        style={{ height: "25vh" }}
      />
      <p style={{ marginTop: "2rem" }}>Immobile non trovato</p>
      <span style={{ marginTop: "1rem" }}>
        Ci dispiace ma l'immobile che stai cercando non è stato trovato o non è
        più disponibile.
      </span>
      <Link to="/immobili">Cercane un altro!</Link>
    </div>
  );
};

export default RealEstateNotFound;
