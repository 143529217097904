import { Fade } from "react-reveal";
import "./Procedure.scss";

const Procedure = () => (
  <div className="services-page__procedure container section short pad-responsive">
    <Fade left>
      <div className="illustration"></div>
    </Fade>
    <Fade>
      <div className="description">
        <p>
          Le agenzie facenti parte del network astaéxpert possono cosi dare
          maggiore visibilità al proprio portafoglio, aumentando le conversioni
          proprio grazie al confronto tra prezzi in asta e prezzi sul mercato
          libero.
        </p>
        <p>
          Controllando le offerte sul mercato libero rispetto a quello delle
          aste immobiliari, il cliente potrà ottenere il miglior affare
          immobiliare nella propria zona. 
        </p>
        <p>
          Con astaéxpert sinergia è la parola chiave: rendere il mercato
          immobiliare competitivo rispetto al mercato delle aste è il nostro
          obiettivo generale.
        </p>
      </div>
    </Fade>
  </div>
);

export default Procedure;
