import "./Pagination.scss";
import ReactPaginate from "react-paginate";
import {
  NavigationArrowLeft,
  NavigationArrowRight,
} from "components/navigation/NavigationArrow";

const Pagination = (props) => {
  return (
    <div className="pagination-section container-fluid pad-responsive">
      <ReactPaginate
        previousLabel={<NavigationArrowLeft />}
        nextLabel={<NavigationArrowRight />}
        pageCount={props.totalPages}
        initialPage={props.pageNumber}
        marginPagesDisplayed={0}
        onPageChange={({ selected }) => props.handlePageClick(selected)}
        containerClassName="pagination"
        pageClassName="paginationItem"
        activeLinkClassName="active"
      />
    </div>
  );
};

export default Pagination;
