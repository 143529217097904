import Mission from "assets/chi-siamo/icona-chisiamo-la-nostra-missione.svg";
import Vision from "assets/chi-siamo/icona-chisiamo-la-nostra-vision.svg";
import Valori from "assets/chi-siamo/icone-chisiamo-i-nostri-valori.svg";

export const values = [
  {
    title: "La nostra missione",
    icon: Mission,
    text: "Con astaéxpert sinergia è la parola chiave: rendere il mercato immobiliare competitivo rispetto al mercato delle aste è la nostra mission.",
  },
  {
    title: "La nostra vision",
    icon: Vision,
    text: "Crediamo fermamente che il confronto crei opportunità: pertanto mettiamo a disposizione degli utenti tutte le offerte disponibili sul mercato, per offrire la giusta visibilità di immobili in asta e sul mercato libero, così da assicurare una scelta consapevole per garantire sempre di trovare soluzioni immobiliari al miglior prezzo.",
  },
  {
    title: "I nostri valori",
    icon: Valori,
    text: "Trasparenza, chiarezza e competenza sono i valori che ci contraddistinguono: una casa per noi è un punto di partenza e non di arrivo.",
  },
];
