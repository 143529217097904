import CardRealEstateExtended from "components/card-real-estate/CardRealEstateExtended";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./ResultsList.scss";

const ResultsList = () => {
  const realEstates = useSelector(({ realEstate }) => realEstate.data);
  const user =
    useSelector(({ user }) => user?.data?.user?.role) === "paying_user";

  return (
    <div className="research-results-list pad-responsive container-fluid">
      {realEstates.map((element, i) => (
        <CardRealEstateExtended key={i} extended element={element} index={i} />
      ))}

      {!user && realEstates.length === 6 && (
        <div className="subscribe-for-more">
          <Link to="/inizia" className="btn orange">
            Iscriviti per vedere altri immobili
          </Link>
        </div>
      )}
    </div>
  );
};

export default ResultsList;
