import SearchBar from "components/search-bar/SearchBar";
import videoSrc from "assets/intro-video.mp4";
import { Play, X } from "phosphor-react";
import { useEffect } from "react";
import useComponentVisible from "utils/useComponentVisible";
import "./Hero.scss";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";

const Hero = () => {
  const videoRef = useRef(null);
  const videoRefMobile = useRef(null);
  const isDesktop = useMediaQuery({ minWidth: 576 });

  const closeVideo = () => {
    document.body.classList.remove("no-scroll");
  };

  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(false, closeVideo());

  const openVideo = () => {
    document.body.classList.add("no-scroll");
    setIsComponentVisible(!isComponentVisible);
  };

  useEffect(() => {
    if (!localStorage.getItem("video")) {
      setIsComponentVisible(true);
      // isDesktop && videoRef.current.play();
      // !isDesktop && videoRefMobile.current.play();
      localStorage.setItem("video", true);
    }
  }, [setIsComponentVisible]);

  return (
    <div className="home-page__hero container-fluid pad-responsive">
      {isDesktop && (
        <div className={`overlay-video ${isComponentVisible ? "active" : ""}`}>
          {isComponentVisible && (
            <div className="window-video" ref={ref}>
              <X onClick={() => setIsComponentVisible(false)} />
              <video ref={videoRef} controls autoPlay>
                <source src={videoSrc} type="video/mp4" />
              </video>
            </div>
          )}
        </div>
      )}

      <div className="container section">
        <div className="intro">
          <h1>
            La community per trovare la casa dei tuoi sogni anche all'asta
          </h1>

          <div className="intro-bottom">
            <p>
              Risparmia tempo e denaro grazie ad astaéxpert: grazie al servizio
              offerto dalla nostra piattaforma di ricerca, comprare casa non è
              mai stato così facile. <br />
              Tu scegli l'immobile, noi pensiamo al resto.
            </p>

            <div title="Riproduci video" className="video">
              <Play className="video-button" onClick={openVideo} />
              {!isDesktop && (
                <video
                  className="video-mobile"
                  ref={videoRefMobile}
                  controls
                  // autoPlay
                  // autoPlay={!localStorage.getItem("video")}
                >
                  <source src={videoSrc} type="video/mp4" />
                </video>
              )}
            </div>
          </div>
        </div>

        <SearchBar homepage isDesktop={isDesktop} />
      </div>
    </div>
  );
};

export default Hero;
