import CardRealEstate from "components/card-real-estate/CardRealEstate";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./ResultsBox.scss";

const ResultsBox = () => {
  const realEstates = useSelector(({ realEstate }) => realEstate.data);
  const user =
    useSelector(({ user }) => user?.data?.user?.role) === "paying_user";

  return (
    <div className="research-real-estate__results-box pad-responsive container-fluid">
      {realEstates.map((element, i) => (
        <CardRealEstate key={i} element={element} index={i} />
      ))}

      {!user && realEstates.length === 6 && (
        <div className="subscribe-for-more">
          <Link to="/inizia" className="btn orange">
            Iscriviti per vedere altri immobili
          </Link>
        </div>
      )}
    </div>
  );
};

export default ResultsBox;
