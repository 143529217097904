import axios from "axios";
import api from "api/real-estate";

const GET_ALL_REAL_ESTATES_REQUEST = "ASTAEXPERT/GET_ALL_REAL_ESTATES_REQUEST";
const GET_ALL_REAL_ESTATES_SUCCESS = "ASTAEXPERT/GET_ALL_REAL_ESTATES_SUCCESS";
const GET_ALL_REAL_ESTATES_FAILURE = "ASTAEXPERT/GET_ALL_REAL_ESTATES_FAILURE";

const GET_ALL_REAL_ESTATES_MAP_REQUEST =
  "ASTAEXPERT/GET_ALL_REAL_ESTATES_MAP_REQUEST";
const GET_ALL_REAL_ESTATES_MAP_SUCCESS =
  "ASTAEXPERT/GET_ALL_REAL_ESTATES_MAP_SUCCESS";
const GET_ALL_REAL_ESTATES_MAP_FAILURE =
  "ASTAEXPERT/GET_ALL_REAL_ESTATES_MAP_FAILURE";

const GET_REAL_ESTATE_REQUEST = "ASTAEXPERT/GET_REAL_ESTATE_REQUEST";
const GET_REAL_ESTATE_SUCCESS = "ASTAEXPERT/GET_REAL_ESTATE_SUCCESS";
const GET_REAL_ESTATE_FAILURE = "ASTAEXPERT/GET_REAL_ESTATE_FAILURE";

const initialState = {
  loading: false,
  loadingMap: false,
  error: false,
  data: [],
  mapData: [],
  pagination: {},
  researchId: null,
  singleRealEstate: null,
};

const reducerRealEstate = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REAL_ESTATES_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_ALL_REAL_ESTATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.realEstates.map((element, i) => {
          return {
            ...element,
            nextId:
              i < action.payload.realEstates.length - 1
                ? action.payload.realEstates[i + 1].id
                : null,
            prevId: i !== 0 ? action.payload.realEstates[i - 1].id : null,
          };
        }),
        pagination: action.payload.pagination,
        researchId: action.payload.researchId
          ? action.payload.researchId
          : null,
      };
    case GET_ALL_REAL_ESTATES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    case GET_ALL_REAL_ESTATES_MAP_REQUEST:
      return { ...state, loadingMap: true, error: false, mapData: [] };
    case GET_ALL_REAL_ESTATES_MAP_SUCCESS:
      return {
        ...state,
        loadingMap: false,
        mapData: action.payload,
      };
    case GET_ALL_REAL_ESTATES_MAP_FAILURE:
      return {
        ...state,
        loadingMap: false,
        mapData: [],
        error: action.payload,
      };

    case GET_REAL_ESTATE_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_REAL_ESTATE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleRealEstate: action.payload,
      };
    case GET_REAL_ESTATE_FAILURE:
      return {
        ...state,
        loading: false,
        singleRealEstate: {},
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducerRealEstate;

export const getAllRealEstates = (par, single) => async (dispatch) => {
  dispatch({
    type: GET_ALL_REAL_ESTATES_REQUEST,
  });

  try {
    const { url, method, headers, params } = api.getAllRealEstates(par);

    const response = await axios({
      url,
      method,
      headers,
      params,
    });

    let realEstateResponse = response.data;

    if (single) {
      realEstateResponse = {
        ...response.data,
        realEstates: [single, ...response.data?.realEstates],
      };
    }

    dispatch({
      type: GET_ALL_REAL_ESTATES_SUCCESS,
      // payload: {
      //   ...realEstateResponse,
      //   realEstates: realEstateResponse.realEstates.map((element) => ({
      //     ...element,
      //     lat: +element.lat + getRandomArbitrary(),
      //     lon: +element.lon + getRandomArbitrary(),
      //   })),
      // },
      payload: realEstateResponse,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_REAL_ESTATES_FAILURE,
      payload: error,
    });
  }
};

export const getAllRealEstatesMap = (par) => async (dispatch) => {
  dispatch({ type: GET_ALL_REAL_ESTATES_MAP_REQUEST });

  try {
    const { url, method, headers, params } = api.getAllRealEstatesMap(par);

    const response = await axios({
      url,
      method,
      headers,
      params,
    });

    dispatch({
      type: GET_ALL_REAL_ESTATES_MAP_SUCCESS,
      payload: response.data.map((element) => ({
        ...element,
        lat: +element.lat + getRandomArbitrary(),
        // lon: +element.lon + getRandomArbitrary(),
      })),
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_REAL_ESTATES_MAP_FAILURE,
      payload: error,
    });
  }
};

export const getRealEstateById = (id) => async (dispatch) => {
  dispatch({ type: GET_REAL_ESTATE_REQUEST });

  try {
    const { url, method, headers } = api.getRealEstateById(id);

    const response = await axios({
      url,
      method,
      headers,
    });

    dispatch({
      type: GET_REAL_ESTATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_REAL_ESTATE_FAILURE,
      payload: error,
    });
  }
};

const getRandomArbitrary = () =>
  (Math.round(Math.random() * 10) / 100000) *
  (Math.round(Math.random()) ? 1 : -1);
