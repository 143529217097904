import "./ContactUs.scss";
import FormContact from "components/form-contact/FormContact";

const ContactUs = () => (
  <div className="contact-us-page container section sub-navbar pad-responsive">
    <div className="left-side">
      <h1>Contattaci</h1>

      <div style={{ marginTop: 32, marginBottom: 16 }}>
        <p style={{ fontSize: 18, fontWeight: 500 }}>
          astaéxpert è un marchio di proprietà di Astaexpert S.R.L. - P.iva
          04586470165
        </p>
      </div>
      <div className="contact-section">
        <h3>Dove siamo</h3>
        <p>
          Via Autostrada, 32 <br />
          24126, Bergamo
        </p>{" "}
      </div>
      <div className="contact-section">
        <h3>Email</h3>
        <a
          style={{ textDecoration: "underline", color: "#FF580A" }}
          href="mailto:info@astaexpert.it"
        >
          info@astaexpert.it
        </a>
      </div>
    </div>
    <FormContact />
  </div>
);

export default ContactUs;
