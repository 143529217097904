import requests from "api/users";
import axios from "axios";

const GET_USERS_REQUEST = "GET_USERS_REQUEST";
const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
const GET_USERS_FAILURE = "GET_USERS_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: {
    operators: [],
    agentsAndLawyers: [],
  },
};

const reducerUsers = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_USERS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_USERS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default reducerUsers;

export const getOurTeamUSers = () => async (dispatch) => {
  dispatch({ type: GET_USERS_REQUEST });
  try {
    const { url, method, headers } = requests.getOurTeamUsers();
    const response = await axios({ url, method, headers });
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USERS_FAILURE,
      payload: err,
    });
  }
};
