import { Fade } from "react-reveal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./OurTeam.scss";
import Hero from "./hero/Hero";
import { getOurTeamUSers } from "reducers/users";
import TeamCard from "./teamCards/TeamCard";

const OurTeam = () => {
  const dispatch = useDispatch();
  const operators = useSelector(({ users }) => users.data.operators);
  const agentsAndLawyers = useSelector(
    ({ users }) => users.data.agentsAndLawyers
  );

  useEffect(() => dispatch(getOurTeamUSers()), [dispatch]);

  return (
    <div className="our-team-page">
      <Fade>
        <Hero />
        <TeamCard operators={operators} agentsAndLawyers={agentsAndLawyers} />
      </Fade>
    </div>
  );
};
export default OurTeam;
