import { API_ROOT_URL, getAuthHeaders, getHeaders } from "./index";

const requests = {
  getAllRealEstates: (params) => {
    return {
      url: `${API_ROOT_URL}/real_estate`,
      method: "GET",
      headers: getAuthHeaders(),
      params: {
        ...params,
        "realEstate.city": params["realEstate.city"]
          ? decodeURIComponent(params["realEstate.city"])
          : null,
      },
    };
  },

  getAllRealEstatesMap: (params) => {
    return {
      url: `${API_ROOT_URL}/real_estate/map`,
      method: "GET",
      headers: getHeaders(),
      params: {
        ...params,
        "realEstate.city": params["realEstate.city"]
          ? decodeURIComponent(params["realEstate.city"])
          : null,
      },
    };
  },

  getRealEstateById: (id) => {
    return {
      url: `${API_ROOT_URL}/real_estate/${id}`,
      method: "GET",
      headers: getHeaders(),
    };
  },

  getRealEstatesHome: () => {
    return {
      url: `${API_ROOT_URL}/real_estate/home`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
};

export default requests;
