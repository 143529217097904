import axios from "axios";
import api from "api/participations";

const GET_ALL_PARTICIPATIONS_REQUEST = "GET_ALL_PARTICIPATIONS_REQUEST";
const GET_ALL_PARTICIPATIONS_SUCCESS = "GET_ALL_PARTICIPATIONS_SUCCESS";
const GET_ALL_PARTICIPATIONS_FAILURE = "GET_ALL_PARTICIPATIONS_FAILURE";

// const GET_PARTICIPATION_REQUEST = "GET_PARTICIPATION_REQUEST";
// const GET_PARTICIPATION_SUCCESS = "GET_PARTICIPATION_SUCCESS";
// const GET_PARTICIPATION_FAILURE = "GET_PARTICIPATION_FAILURE";

const REQUEST_PARTICIPATION_REQUEST = "REQUEST_PARTICIPATION_REQUEST";
const REQUEST_PARTICIPATION_SUCCESS = "REQUEST_PARTICIPATION_SUCCESS";
const REQUEST_PARTICIPATION_FAILURE = "REQUEST_PARTICIPATION_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const reducerPartecipations = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PARTICIPATIONS_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_ALL_PARTICIPATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_ALL_PARTICIPATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REQUEST_PARTICIPATION_REQUEST:
      return { ...state, loading: true, error: false };
    case REQUEST_PARTICIPATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state, action.payload],
      };
    case REQUEST_PARTICIPATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducerPartecipations;

export const requestPartecipation = (id) => async (dispatch) => {
  dispatch({
    type: REQUEST_PARTICIPATION_REQUEST,
  });
  try {
    const { url, method, headers } = api.requestParticipation(id);

    const response = await axios({ url, method, headers });
    // if (response) {
    window.dataLayer.push({
      event: "auction_partecipation_request",
    });
    // }
    dispatch({
      type: REQUEST_PARTICIPATION_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: REQUEST_PARTICIPATION_FAILURE,
      payload: err,
    });
  }
};

export const getAllParticipations = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_PARTICIPATIONS_REQUEST,
  });

  try {
    const { url, method, headers } = api.getAllParticipations();

    const response = await axios({
      url,
      method,
      headers,
    });
    dispatch({
      type: GET_ALL_PARTICIPATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_PARTICIPATIONS_FAILURE,
      payload: err,
    });
  }
};
