import { updateUser } from "reducers/user";
import Form from "components/form/Form";
import "./AccountData.scss";
import { useState } from "react";
import { Fade } from "react-reveal";
import { useDispatch, useSelector } from "react-redux";
import Payment from "./payment/Payment";

export const AccountData = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.data.user);
  const [userData, setUserData] = useState({});
  const [password, setPassword] = useState({});
  const [userFeedback, setUserFeedback] = useState(null);
  const [passwordFeedback, setPasswordFeedback] = useState(null);

  const handleDataSubmit = async () => {
    try {
      const { email, firstname, lastname } = userData;
      await dispatch(
        updateUser({
          email,
          firstname,
          lastname,
        })
      );
      setUserData({ firstname: "", lastname: "", email: "" });
      showFeedbackUser();
    } catch (err) {
      console.log(err);
      console.log(err.response);
      console.log(err.message);
    }
  };

  const handlePreferenceSubmit = async () => {
    try {
      const {
        researchPreferenceCity,
        researchPreferenceMaxPrice,
        researchPreferenceMinSquarefeet,
        researchPreferenceType,
        researchPreferenceNotes,
        availableMoney,
        availableMortgage,
      } = userData;
      await dispatch(
        updateUser({
          researchPreferenceCity,
          researchPreferenceMaxPrice,
          researchPreferenceMinSquarefeet,
          researchPreferenceType,
          researchPreferenceNotes,
          availableMoney,
          availableMortgage,
        })
      );
      setUserData({
        researchPreferenceCity: "",
        researchPreferenceMaxPrice: "",
        researchPreferenceMinSquarefeet: "",
        researchPreferenceType: "",
        researchPreferenceNotes: "",
        availableMoney: "",
        availableMortgage: "",
      });
      showFeedbackUser();
    } catch (err) {
      console.log(err);
      console.log(err.response);
      console.log(err.message);
    }
  };

  const showFeedbackUser = () => {
    setUserFeedback("Dati modificati con successo!");
    setTimeout(() => {
      setUserFeedback(null);
    }, [2000]);
  };

  const showFeedbackPassword = () => {
    setPasswordFeedback("Dati modificati con successo!");
    setTimeout(() => {
      setPasswordFeedback(null);
    }, [2000]);
  };

  const handlePasswordSubmit = async () => {
    try {
      const { newPassword } = password;
      await dispatch(
        updateUser({
          password: newPassword,
        })
      );
      showFeedbackPassword();
      setPassword({ oldPassword: "", newPassword: "", repeatPassword: "" });
    } catch (err) {
      console.log(err);
      console.log(err.response);
      console.log(err.message);
    }
  };

  const changeDataInputs = [
    {
      target: "firstname",
      placeholder: user.firstname ? `Nome - ${user.firstname}` : "Nome",
      type: "text",
      value: userData.firstname,
    },
    {
      target: "lastname",
      placeholder: user.lastname ? `Cognome - ${user.lastname}` : "Cognome",
      type: "text",
      value: userData.lastname,
    },
    {
      target: "email",
      placeholder: user.email ? `Email - ${user.email}` : "Email",
      type: "email",
      value: userData.email,
    },
  ];

  const researchPreferenceInputs = [
    {
      target: "researchPreferenceCity",
      placeholder: `Comune ricerca ${
        user.researchPreferenceCity ? "- " + user.researchPreferenceCity : ""
      }`,
      type: "text",
    },
    {
      target: "researchPreferenceMaxPrice",
      placeholder: `Prezzo massimo ${
        user.researchPreferenceMaxPrice
          ? "- " + user.researchPreferenceMaxPrice
          : ""
      }`,
      type: "number",
    },
    {
      target: "researchPreferenceMinSquarefeet",
      placeholder: `Superficie minima ${
        user.researchPreferenceMinSquarefeet
          ? "- " + user.researchPreferenceMinSquarefeet
          : ""
      }`,
      type: "number",
    },
    {
      target: "researchPreferenceType",
      placeholder: `Tipologia ${
        user.researchPreferenceType ? "- " + user.researchPreferenceType : ""
      }`,
      type: "text",
    },
    {
      target: "researchPreferenceNotes",
      placeholder: `Note ${
        user.researchPreferenceNotes ? "- " + user.researchPreferenceNotes : ""
      }`,
      type: "text",
    },
    {
      target: "availableMoney",
      placeholder: `Capitale disponibile ${
        user.availableMoney ? "- " + user.availableMoney : ""
      }`,
      type: "number",
    },
  ];

  const changePasswordInputs = [
    {
      target: "oldPassword",
      placeholder: "Vecchia Password",
      type: "password",
    },
    { target: "newPassword", placeholder: "Nuova Password", type: "password" },
    {
      target: "repeatPassword",
      placeholder: "Ripeti Password",
      type: "password",
    },
  ];

  const changeDataButton = [
    {
      text: "Modifica Dati",
      handleClick: handleDataSubmit,
    },
  ];

  const changePreferenceButton = [
    {
      text: "Modifica Dati",
      handleClick: handlePreferenceSubmit,
    },
  ];

  const changePasswordButton = [
    {
      text: "Cambia password",
      handleClick: handlePasswordSubmit,
    },
  ];

  const handleChangeInputUser = (target, value) =>
    setUserData({ ...userData, [target]: value });

  const handleChangeInputPassword = (target, value) =>
    setPassword({ ...password, [target]: value });

  const isDisabled = () => {
    const inputStatus = changeDataInputs.map(
      (input) => !!userData[input.target] && userData[input.target] !== ""
    );
    const inputAreValid = inputStatus.some((input) => !!input);
    return !inputAreValid;
  };

  const isPreferenceDisabled = () => {
    const inputStatus = researchPreferenceInputs.map(
      (input) => !!userData[input.target] && userData[input.target] !== ""
    );
    const inputAreValid = inputStatus.some((input) => !!input);
    return !inputAreValid;
  };

  const isPassDisabled = () => {
    const inputStatus = changePasswordInputs.map(
      (input) => !!password[input.target] && password[input.target] !== ""
    );
    const inputAreValid = inputStatus.every((input) => !!input);
    const passwordMatch = password.newPassword === password.repeatPassword;
    return !inputAreValid || !passwordMatch;
  };

  return (
    <Fade>
      <div className="account-data-page">
        <div className="user-data">
          <Form
            title="Preferenze ricerca immobile"
            inputs={researchPreferenceInputs}
            checkboxes={[
              {
                target: "availableMortgage",
                label: "Disponibilità ad aprire mutuo",
                checked:
                  typeof userData.availableMortgage !== "undefined"
                    ? userData.availableMortgage
                    : user.availableMortgage,
              },
            ]}
            handleChangeInput={handleChangeInputUser}
            buttons={changePreferenceButton}
            disabled={isPreferenceDisabled}
            feedback={userFeedback}
          />
          <Form
            title="Modifica Dati"
            inputs={changeDataInputs}
            handleChangeInput={handleChangeInputUser}
            buttons={changeDataButton}
            disabled={isDisabled}
            feedback={userFeedback}
          />
        </div>
        <div className="user-data">
          <Form
            title="Cambia Password"
            inputs={changePasswordInputs}
            handleChangeInput={handleChangeInputPassword}
            buttons={changePasswordButton}
            disabled={isPassDisabled}
            feedback={passwordFeedback}
          />
          <div style={{ alignSelf: "flex-start" }}>
            <Payment />
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default AccountData;
