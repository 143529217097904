import Intro from "./intro/Intro";
import BlogList from "components/blog-list/BlogList";
import { Fade } from "react-reveal";

const Blog = () => (
  <div className="bg-pink">
    <Fade>
      <Intro />

      <BlogList standard={true} />
    </Fade>
  </div>
);

export default Blog;
