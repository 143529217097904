import {
  NavigationArrowLeft,
  NavigationArrowRight,
} from "components/navigation/NavigationArrow";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./HeadingTop.scss";

const HeadingTop = (props) => {
  const history = useHistory();
  const location = useLocation();
  const user =
    useSelector(({ user }) => user?.data?.user?.role) === "paying_user";

  return (
    <div className="heading-immobile-page">
      {history.action === "PUSH" && !location.state?.from ? (
        <div className="back" onClick={history.goBack}>
          <NavigationArrowLeft small />

          <span>Indietro</span>
        </div>
      ) : (
        <Link className="back" to="/immobili">
          <NavigationArrowLeft small />

          <span>Immobili</span>
        </Link>
      )}

      {props.singleRealEstate.auction && props.daysToAuction > 0 && (
        <div className="auction-date">
          <span>
            Da bandire tra {props.daysToAuction}{" "}
            {props.daysToAuction > 1 ? "giorni" : "giorno"}
          </span>
          <div
            className="dot"
            style={{
              background: renderColor(props.daysToAuction),
              color: renderColor(props.daysToAuction),
            }}
          ></div>
        </div>
      )}

      <div className="navigation">
        {location.state && props.current && (
          <>
            <Link
              className={`left ${
                props.current.prevId === null ? "disabled" : ""
              }`}
              to={{
                pathname: "/immobile/" + props.current.prevId,
                state: {
                  from: location.state.from,
                  search: location.state.search,
                  index: location.state.index - 1,
                },
              }}
            >
              <NavigationArrowLeft small />
              <span>Precedente</span>
            </Link>

            <Link
              className={`right ${
                props.current.nextId === null ||
                (location.state.index > 4 && !user)
                  ? "disabled"
                  : ""
              }`}
              to={{
                pathname: "/immobile/" + props.current.nextId,
                state: {
                  from: location.state.from,
                  search: location.state.search,
                  index: location.state.index + 1,
                },
              }}
            >
              <span>Successivo</span>
              <NavigationArrowRight small />
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default HeadingTop;

const renderColor = (days) => {
  if (days > 30) {
    return "limegreen";
  } else if (days > 15) {
    return "gold";
  } else if (days > 5) {
    return "orangered";
  } else if (days > 0) {
    return "red";
  }
};
