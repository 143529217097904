import { File } from "phosphor-react";
import "./Attachments.scss";

const Attachments = ({ attachments }) => (
  <div className="info-real-estate-page__attachments">
    <h4 className="mb">Allegati</h4>

    <div className="attachments-list">
      {attachments.map((element, i) => (
        <a
          href={element.file}
          target="_blank"
          rel="noreferrer"
          className="attachment"
          key={i}
        >
          <File />

          <span>{element.title}</span>
        </a>
      ))}
    </div>
  </div>
);

export default Attachments;
