import requests from "api";
import Form, { Checkbox, Input } from "components/form/Form";
import { NavigationArrowLeft } from "components/navigation/NavigationArrow";
import { Trash } from "phosphor-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCart, getCart } from "reducers/cart";
import { ReactComponent as Nexi } from "assets/nexi-logo.svg";
import { ReactComponent as Paypal } from "assets/paypal-logo.svg";
import "./Cart.scss";
import { updateUser } from "reducers/user";
import { Link } from "react-router-dom";

const inputs = [
  // { target: "name", placeholder: "Nome", type: "text" },
  // { target: "surname", placeholder: "Cognome", type: "text" },
  { target: "address", placeholder: "Indirizzo", type: "text" },
  { target: "city", placeholder: "Città", type: "text" },
  { target: "zipcode", placeholder: "CAP", type: "text" },
  { target: "province", placeholder: "Provincia", type: "text" },
  { target: "country", placeholder: "Paese", type: "text" },
  { target: "phone", placeholder: "Telefono", type: "tel" },
  {
    target: "taxCode",
    placeholder: "Codice fiscale (opzionale)",
    type: "text",
  },
  { target: "vatCode", placeholder: "Partita IVA (opzionale)", type: "text" },
  { target: "sdiCode", placeholder: "Codice SDI (opzionale)", type: "text" },
  // { target: "email", placeholder: "Email", type: "email" },
];

const requiredInputs = [
  { target: "address" },
  { target: "city" },
  { target: "zipcode" },
  { target: "province" },
  { target: "country" },
  { target: "phone" },
];

const checkboxesPayment = [
  {
    id: "nexi",
    name: "nexi",
    target: "nexi",
    defaultValue: "default",
    label: (
      <>
        <Nexi />
        <Paypal />
      </>
    ),
  },
];

const formatSubType = (text) => {
  switch (text) {
    case "Premium":
      return "Abbonamento mensile astaèxpert Premium";
    case "Pro":
      return "Licenza mensile astaèxpert PRO";
    default:
      return text;
  }
};

const Cart = ({ location, history }) => {
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  const checkboxes = [
    {
      id: "privacy",
      name: "privacy",
      target: "privacy",
      label: (
        <span>
          Accetto{" "}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#ff580a", textDecoration: "underline" }}
            to={{
              pathname: `/termini-e-condizioni-${subscription?.type.toLowerCase()}`,
              state: { title: subscription?.type.toLowerCase() },
            }}
          >
            i termini e Condizioni {subscription?.type}
          </Link>
        </span>
      ),
    },
  ];

  const dispatch = useDispatch();
  const cart = useSelector(({ cart }) => cart.cart);
  const [cartPage, setCartPage] = useState(location?.state?.cart || false);
  const [form, setForm] = useState({ nexi: true });
  const selectedSubscription = {
    subscriptionId: localStorage.getItem("subscription")
      ? JSON.parse(localStorage.getItem("subscription")).id
      : null,
  };
  const isCartFull = localStorage.getItem("subscription");
  const [coupon, setCoupon] = useState(null);
  const [couponChecked, setCouponChecked] = useState(null);

  useEffect(() => dispatch(getCart()), [dispatch]);

  useEffect(() => setCouponChecked(false), [form.promo]);

  const handleChangeInput = (target, value) =>
    setForm({ ...form, [target]: value });

  const isDisabled = () => {
    const allInputs = [...requiredInputs, ...checkboxes];
    const inputStatus = allInputs.map(
      (input) => !!form[input.target] && form[input.target] !== ""
    );
    const inputAreValid = inputStatus.every((input) => !!input);
    return !inputAreValid;
  };

  const handleProceedClick = () => {
    if (localStorage.getItem("user")) {
      setCartPage(true);
      window.scrollTo(0, 0);
    } else {
      history.push("/registrati");
    }
  };

  const goBackCart = () => {
    setCartPage(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const lsCoupon = localStorage.getItem("couponCode");
    if (lsCoupon) {
      setForm((form) => ({
        ...form,
        promo: lsCoupon,
      }));
    }
  }, []);

  useEffect(() => {
    if (cartPage && !!form.promo) {
      applyCoupon();
    }
  }, [form]);

  const applyCoupon = async () => {
    const checkedCoupon = await requests.verifyCoupon(form.promo);
    if (checkedCoupon) {
      localStorage.setItem("couponCode", checkedCoupon.code);
    }
    setCoupon(checkedCoupon);
    setCouponChecked(true);
  };

  const handlePaymentClick = async () => {
    const code = localStorage.getItem("couponCode");
    await handleDataSubmit();
    const link = await requests.buySub(selectedSubscription, code);
    window.location.href = link;
  };

  const handleRemoveCartItem = async () => {
    await dispatch(deleteCart());
    dispatch(getCart());
    history.push("/");
  };

  const handleDataSubmit = async () => {
    try {
      const {
        taxCode,
        address,
        city,
        zipcode,
        province,
        country,
        phone,
        sdiCode,
        vatCode,
      } = form;
      await dispatch(
        updateUser({
          taxCode,
          address,
          city,
          zipcode,
          province,
          country,
          phone,
          sdiCode,
          vatCode,
        })
      );
      setForm({
        taxCode: "",
        address: "",
        city: "",
        zipcode: "",
        province: "",
        country: "",
        phone: "",
        sdiCode: "",
        vatCode: "",
      });
    } catch (err) {
      console.log(err);
      console.log(err.response);
      console.log(err.message);
    }
  };

  return (
    <div className="cart-page">
      <div className="container section sub-navbar pad-responsive">
        {!cartPage ? (
          <div className="cart">
            <h1 className="general">Carrello</h1>

            {cart && isCartFull ? (
              <div className="details section">
                <div className="details__heading">
                  <div title="Svuota carrello" className="trash-icon">
                    <Trash
                      onClick={handleRemoveCartItem}
                      className="delete-cart-btn"
                    />
                  </div>
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>Prodotto</th>
                      <th>Prezzo</th>
                      <th>Quantità</th>
                      <th>Subtotale</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>{formatSubType(subscription.type)}</td>
                      <td>€ {subscription.price}</td>
                      <td>1</td>
                      <td>€ {subscription.price}</td>
                    </tr>
                  </tbody>
                </table>

                <div className="table-bottom">
                  <div>
                    <div className="coupon">
                      <Input
                        placeholder="Codice Promozionale"
                        target="promo"
                        type="text"
                        value={form.promo}
                        handleChangeInput={handleChangeInput}
                      />
                      <div className="btn orange" onClick={applyCoupon}>
                        APPLICA
                      </div>
                    </div>

                    {couponChecked && (
                      <div
                        className={`coupon-checked ${coupon ? "valid" : ""}`}
                      >
                        {!coupon
                          ? "Coupon non valido"
                          : "Coupon inserito correttamente!"}
                      </div>
                    )}
                  </div>

                  <div className="right">
                    <div className="total">
                      TOTALE
                      <span
                        style={{
                          textDecoration: coupon ? "line-through" : "unset",
                        }}
                      >
                        € {subscription.price}
                      </span>
                      {coupon && (
                        <span>
                          €{" "}
                          {coupon.isPerc
                            ? Math.round(
                                ((subscription.price * (100 - coupon.value)) /
                                  100 +
                                  Number.EPSILON) *
                                  100
                              ) / 100
                            : Math.max(0, subscription.price - coupon.value)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <button className="btn orange" onClick={handleProceedClick}>
                  Procedi con l'ordine
                </button>
              </div>
            ) : (
              <div className="empty-cart">Carrello vuoto</div>
            )}
          </div>
        ) : (
          <div className="checkout">
            <h1 className="general">Metodo di pagamento</h1>

            <div className="container section short pad-responsive form-container">
              <Form
                title="Dati fatturazione"
                inputs={inputs}
                widthClass="large"
                handleChangeInput={handleChangeInput}
              />

              <div className="right-box">
                <Form checkboxes={checkboxesPayment} />

                <div className="total-container">
                  <div className="total">
                    TOTALE
                    <span
                      style={{
                        textDecoration: coupon ? "line-through" : "unset",
                      }}
                    >
                      € {subscription.price}
                    </span>
                    {coupon && (
                      <span>
                        €{" "}
                        {coupon.isPerc
                          ? Math.round(
                              ((subscription.price * (100 - coupon.value)) /
                                100 +
                                Number.EPSILON) *
                                100
                            ) / 100
                          : Math.max(0, subscription.price - coupon.value)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="recap container section short">
              <div className="recap-row">
                <div className="plan-type">PACCHETTO {subscription.type}</div>

                <div>€ {subscription.price}</div>
              </div>
              <div className="recap-row">
                <div className="total">TOTALE</div>

                <div className="prices-plans">
                  <span
                    style={{
                      textDecoration: coupon ? "line-through" : "unset",
                    }}
                  >
                    € {subscription.price}
                  </span>

                  {coupon && (
                    <span>
                      €{" "}
                      {coupon.isPerc
                        ? Math.round(
                            ((subscription.price * (100 - coupon.value)) / 100 +
                              Number.EPSILON) *
                              100
                          ) / 100
                        : Math.max(0, subscription.price - coupon.value)}
                    </span>
                  )}
                </div>
              </div>

              <div className="checkbox-container">
                {checkboxes.map((checkbox, i) => (
                  <Checkbox
                    key={`checkbox_${i}`}
                    id={checkbox.id}
                    target={checkbox.target}
                    label={checkbox.label}
                    handleChangeInput={handleChangeInput}
                  />
                ))}
              </div>

              <div className="buttons-container">
                <div className="back-to-cart">
                  <NavigationArrowLeft small handleClick={goBackCart} />
                  <span>Torna al carrello</span>
                </div>

                <button
                  disabled={isDisabled()}
                  className="btn orange"
                  onClick={handlePaymentClick}
                >
                  continua il pagamento
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
