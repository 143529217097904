import axios from "axios";
import api from "api/user";

const CREATE_USER_REQUEST = "ASTAEXPERT/CREATE_USER_REQUEST";
const CREATE_USER_SUCCESS = "ASTAEXPERT/CREATE_USER_SUCCESS";
const CREATE_USER_FAILURE = "ASTAEXPERT/CREATE_USER_FAILURE";

const LOGIN_USER_REQUEST = "ASTAEXPERT/LOGIN_USER_REQUEST";
const LOGIN_USER_SUCCESS = "ASTAEXPERT/LOGIN_USER_SUCCESS";
const LOGIN_USER_FAILURE = "ASTAEXPERT/LOGIN_USER_FAILURE";

const GET_USER_REQUEST = "ASTAEXPERT/GET_USER_REQUEST";
const GET_USER_SUCCESS = "ASTAEXPERT/GET_USER_SUCCESS";
const GET_USER_FAILURE = "ASTAEXPERT/GET_USER_FAILURE";

const REFRESH_PAYING_USER_REQUEST = "ASTAEXPERT/REFRESH_PAYING_USER_REQUEST";
const REFRESH_PAYING_USER_SUCCESS = "ASTAEXPERT/REFRESH_PAYING_USER_SUCCESS";
const REFRESH_PAYING_USER_FAILURE = "ASTAEXPERT/REFRESH_PAYING_USER_FAILURE";

const UPDATE_USER_REQUEST = "ASTAEXPERT/UPDATE_USER_REQUEST";
const UPDATE_USER_SUCCESS = "ASTAEXPERT/UPDATE_USER_SUCCESS";
const UPDATE_USER_FAILURE = "ASTAEXPERT/UPDATE_USER_FAILURE";

const DELETE_USER_REQUEST = "ASTAEXPERT/DELETE_USER_REQUEST";
const DELETE_USER_SUCCESS = "ASTAEXPERT/DELETE_USER_SUCCESS";
const DELETE_USER_FAILURE = "ASTAEXPERT/DELETE_USER_FAILURE";

const LOGOUT_USER = "ASTAEXPERT/LOGOUT_USER";

const initialState = {
  loading: false,
  error: false,
  data: {
    authToken: null,
    refreshToken: null,
    user: null,
    expiresIn: null,
  },
  cart: null,
};

const reducerUser = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case LOGIN_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        data: initialState.data,
        error: action.payload,
      };
    case GET_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          user: action.payload,
        },
      };
    case REFRESH_PAYING_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case REFRESH_PAYING_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          user: action.payload,
        },
      };
    case REFRESH_PAYING_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            ...action.payload,
          },
        },
      };
    case UPDATE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DELETE_USER_REQUEST:
      return { ...state, loading: true, error: false };
    case DELETE_USER_SUCCESS:
      return { ...state, loading: false, data: initialState.data };
    case DELETE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case LOGOUT_USER:
      return { ...initialState };

    default:
      return state;
  }
};

export default reducerUser;

export const createUser = (userData) => async (dispatch) => {
  dispatch({
    type: CREATE_USER_REQUEST,
  });

  try {
    const { url, method, headers, data } = api.signUp(userData);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    if (response) {
      window.dataLayer.push({
        event: "sign_up",
      });
    }
    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: response.data,
    });
    localStorage.setItem("user", JSON.stringify(response.data));
  } catch (error) {
    if (error.response.status === 409) {
      dispatch({
        type: CREATE_USER_FAILURE,
        payload: "Registrazione fallita: mail già associata ad account",
      });
    } else {
      dispatch({
        type: CREATE_USER_FAILURE,
        payload: "Errore durante la registrazione",
      });
    }
  }
};

export const loginUser = (credentials) => async (dispatch) => {
  dispatch({
    type: LOGIN_USER_REQUEST,
  });

  try {
    if (!credentials) {
      const userLs = localStorage.getItem("user");
      if (userLs) {
        const user = JSON.parse(userLs);
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: user,
        });
      } else {
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: "NO_USER_IN_LOCAL_STORAGE",
        });
      }
    } else {
      const { url, method, headers, data } = api.login(credentials);

      const response = await axios({
        url,
        method,
        headers,
        data,
      });

      if (response) {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: response.data,
        });

        localStorage.setItem("user", JSON.stringify(response.data));
      } else {
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: "no_user_found",
        });
      }
    }
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message === "user_not_approved"
    ) {
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: "user_not_approved",
      });
    } else {
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: "error_login",
      });
    }
  }
};

export const getUser = () => async (dispatch) => {
  dispatch({
    type: GET_USER_REQUEST,
  });
  try {
    const { url, method, headers } = api.getUser();

    const response = await axios({
      url,
      method,
      headers,
    });

    if (response) {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: GET_USER_FAILURE,
        payload: "Couldn't find user",
      });
    }
  } catch (error) {
    dispatch({
      type: GET_USER_FAILURE,
      payload: error,
    });
  }
};

export const refreshPayingUser = () => async (dispatch) => {
  dispatch({
    type: REFRESH_PAYING_USER_REQUEST,
  });
  try {
    const { url, method, headers } = api.refreshPayingUser();

    const response = await axios({
      url,
      method,
      headers,
    });

    dispatch({
      type: REFRESH_PAYING_USER_SUCCESS,
      payload: response.data,
    });
    localStorage.setItem("user", JSON.stringify(response.data));
  } catch (error) {
    dispatch({
      type: REFRESH_PAYING_USER_FAILURE,
      payload: error,
    });
  }
};

export const updateUser = (userUpdates) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    //Task: STXPRT-26
    for (let update in userUpdates) {
      if (userUpdates[update] === "") {
        delete userUpdates[update];
      }
    }

    const { url, method, headers, data } = api.updateUser(userUpdates);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });

    if (response) {
      const userLs = localStorage.getItem("user");
      const user = JSON.parse(userLs);
      localStorage.removeItem("user");
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          user: {
            ...user.user,
            ...response.data,
          },
        })
      );
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
      return "success";
    } else {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: "Couldn't update user",
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error,
    });
  }
};

export const deleteUser = () => async (dispatch) => {
  dispatch({
    type: DELETE_USER_REQUEST,
  });
  try {
    const { url, method, headers } = api.deleteUser();

    const response = await axios({
      url,
      method,
      headers,
    });

    if (response) {
      dispatch({
        type: DELETE_USER_SUCCESS,
      });

      localStorage.removeItem("user");
    } else {
      dispatch({
        type: DELETE_USER_FAILURE,
        payload: "Couldn't delete user",
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAILURE,
      payload: error,
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT_USER,
  });
  localStorage.clear();
  localStorage.setItem("video", true);
};
