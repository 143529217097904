import Map from "./map/Map";
import CardRealEstate from "components/card-real-estate/CardRealEstate";
import "./ResultsMap.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ResultsMap = () => {
  const realEstates = useSelector(({ realEstate }) => realEstate.data);
  const user =
    useSelector(({ user }) => user?.data?.user?.role) === "paying_user";

  return (
    <div className="resultsMap">
      <Map />

      <div className="apartments-list">
        {realEstates.map((element, i) => (
          <CardRealEstate key={i} element={element} index={i} />
        ))}

        {!user && realEstates.length === 6 && (
          <div className="subscribe-for-more">
            <Link to="/inizia" className="btn orange">
              Iscriviti per vedere altri immobili
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultsMap;
