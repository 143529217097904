import requests from "api";

const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const reducerCities = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITIES_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_CITIES_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_CITIES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default reducerCities;

export const getCities = (text) => async (dispatch) => {
  dispatch({ type: GET_CITIES_REQUEST });

  try {
    const { data } = await requests.getCities(text);

    dispatch({ type: GET_CITIES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_CITIES_FAILURE, payload: error });
  }
};
