import { NavLink } from "react-router-dom";
import { ShoppingBag } from "phosphor-react";
import "./HamburgerMenu.scss";
import Dropdown from "./dropdown/Dropdown";

const HamburgerMenu = (props) => (
  <div
    className={`hamburger-menu ${props.visibility ? "active" : ""}`}
    style={{ background: props.color }}
  >
    {props.voices
      .filter((value) => (!props.logged ? !value.logged : value))
      .map((element, i) => (
        <div key={i} className="item-menu">
          {element.dropdown ? (
            <Dropdown
              key={i}
              element={element}
              function={props.toggleVisibility}
            />
          ) : (
            <NavLink onClick={() => props.toggleVisibility()} to={element?.to}>
              <div className="voice">{element.name}</div>
            </NavLink>
          )}
        </div>
      ))}

    <div className="item-menu">
      <NavLink
        onClick={() => props.toggleVisibility()}
        title="Carrello"
        to="/carrello"
        className="cart-link"
      >
        {props.user?.user?.role !== "paying_user" &&
          props.user?.user?.role !== "agent" && (
            <>
              <div className="voice">
                {props.cart && <div className="cart-dot">1</div>}
                <ShoppingBag weight="bold" />
              </div>
            </>
          )}
      </NavLink>
    </div>

    {props.user?.user?.role !== "paying_user" &&
      props.user?.user?.role !== "agent" && (
        <NavLink onClick={() => props.toggleVisibility()} to="/inizia">
          <div
            className="item-menu"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className=" inizia btn orange">
              {props.user?.user?.role === "user" ? "Iscriviti" : "Inizia"}
            </div>
          </div>
        </NavLink>
      )}
  </div>
);

export default HamburgerMenu;
