import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Favicon from "assets/favicon-circle.png";
import { useSelector } from "react-redux";

const getDescription = (path, realEstateDescription) => {
  if (!!path && path.includes("Immobile") && realEstateDescription) {
    return realEstateDescription?.substring(0, 150);
  }
  switch (path) {
    case "Trova il tuo affare - astaéxpert":
      return "Il servizio per trovare la casa dei tuoi sogni all'asta. Risparmia tempo e denaro con Astaéxpert";
    case "Chi Siamo - astaéxpert":
      return "Astaéxpert ti aiuta a trovare l'immobile giusto al miglior prezzo. Comprare casa tramite asta immobiliare non é mai stato così facile";
    case "Servizi - astaéxpert":
      return "Scopri tutti i servizi che offre Astaéxpert ai propri partner e clienti. Con Astaéxpert la sinergia é la parola chiave.";
    case "Perche Astaexpert - astaéxpert":
      return "Ottieni il massimo risparmio senza pensieri: comprare casa in asta é davvero semplice. Scopri come possiamo aiutarti a trovare l'occasione giusta";
    case "Immobili - astaéxpert":
      return "In questa sezione puoi trovare tutte le proposte di Astaéxpert. Scegli l'immobile che fa al tuo caso";
    case "Blog - astaéxpert":
      return "Non sai come iniziare? Scopri come funziona l'asta immobiliare leggendo i nostri articoli.";
    case "Calendario Aste - astaéxpert":
      return "Consulta il calendario di Astaéxpert e trova l'asta che fa per te! Tu scegli l'immobile al resto ci pensiemo noi";
    case "Contattaci - astaéxpert":
      return "Entra in contatto con astaéxpert. Ci trovi in Via Autostrada 32 - Bergamo (BG) - 24126 – Italia. Email: info@astaexpert.it";
    case "Confronto e risparmio - astaéxpert":
      return "Il confronto crea opportunità. Sulla base di questa considerazione abbiamo sviluppato il portale e l’App Astaexpert.";
    case "Inizia - astaéxpert":
      return "Inizia da qui! Abbonati ad Astaexpert Premium e rimani aggiornato sulle ultime novità delle aste disponibili.";
    case "Carrello - astaéxpert":
      return "Entra nel mondo Astaexpert. Scopri il nostro abbonamento annuale per rimanere sempre aggiornato.";
    case "Faq - astaéxpert":
      return "Hai domande? Scopri tutte le curiosità su Astaexpert e conosci meglio il nostro mondo.";
    case "Modulistica - astaéxpert":
      return "Hai bisogno della modulistica? Su Astaexpert trovi tutti i moduli per il tuo tribunale.";

    default:
      return "";
  }
};
const MyHelmet = () => {
  const realEstateDescription = useSelector(
    ({ realEstate }) => realEstate.singleRealEstate?.description
  );
  const { pathname } = useLocation();
  const [path, setPath] = useState();

  useEffect(() => {
    if (pathname) {
      const formattedPath = format(pathname);

      // GOOGLE TAG MANAGER
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        originalLocation:
          document?.location?.protocol +
          "//" +
          document?.location?.hostname +
          document?.location?.pathname +
          document?.location?.search,
      });
      window.dataLayer.push({
        event: "Pageview",
        page_title: formattedPath || "",
        page_URL: window?.location?.href || "",
        page_path: window?.location?.pathname || "",
        page_hash: window?.location?.hash || "",
        page_query: window?.location?.search || "",
        page_location:
          (window?.location?.href || "") + (window?.location?.search || ""),
      });

      // PIXEL FACEBOOK
      // window.fbq("track", "PageView");

      setPath(formattedPath);
    }
  }, [pathname]);

  return (
    <Helmet>
      <title>{path}</title>
      <meta
        name="description"
        content={getDescription(path, realEstateDescription)}
      />
      <link rel="icon" href={Favicon} />
    </Helmet>
  );
};

export default MyHelmet;

const format = (pathname) => {
  const address = pathname.split("/")[1];

  if (address !== "" && address !== "immobile") {
    const exludeWords = ["e", "di"];
    const arr = address.split("-");
    return (
      arr
        .map((word, i) => {
          return exludeWords.includes(word) && i !== 0
            ? [word]
            : word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ") + " - astaéxpert"
    );
  } else if (address === "immobile") {
    if (!pathname.split("/")[3]) {
      return `Immobile ${pathname.split("/")[2]} - astaéxpert`;
    } else {
      return `Immobile ${pathname.split("/")[3]} - astaéxpert`;
    }
  } else {
    return "Trova il tuo affare - astaéxpert";
  }
};
