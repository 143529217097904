import { useDispatch, useSelector } from "react-redux";
import { getAllVisits } from "reducers/visits";
import "./AccountVisits.scss";
import moment from "moment";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Fade } from "react-reveal";

const AccountVisits = () => {
  const dispatch = useDispatch();
  const visits = useSelector(({ visits }) => visits.data);

  useEffect(() => dispatch(getAllVisits()), [dispatch]);

  return (
    <Fade>
      {visits.length > 0 ? (
        <div className="visits-list">
          {visits.map((element, i) => (
            <div key={i} className="visit">
              <div className="left">
                <Link to={"/immobile/" + element.realEstate.id}>
                  <h3>{element.realEstate.title}</h3>
                </Link>
              </div>

              <div className="right">
                <span>
                  {checkName(element.status)}
                  {element.status === "scheduled" &&
                    ` ${moment(element.scheduledDate).format("L")} alle ${
                      element.scheduledSlot
                    }`}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-list">Nessuna visita richiesta</div>
      )}
    </Fade>
  );
};

export default AccountVisits;

const checkName = (text) => {
  switch (text) {
    case "pending":
    case "Pending":
      return "In attesa di conferma";
    case "scheduled":
      return "Pianificata per il ";
    case "refused":
      return "Rifiutata";
    default:
  }
};
