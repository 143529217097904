import { CaretDown, X } from "phosphor-react";
import useComponentVisible from "utils/useComponentVisible";
import "./InputSelect.scss";

export const InputSelect = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible, toggling } =
    useComponentVisible(false);

  const handleChangeSimple = (option) => {
    props.onChange(option);
    setIsComponentVisible(false);
  };

  const handleChange = (option) => {
    if (props.target) {
      props.onChange(props.target, option);
    } else {
      props.onChange(option);
    }
    setIsComponentVisible(false);
  };

  const handleResetSimple = (event) => {
    console.log(event)
    event.stopPropagation();
    props.onReset({ label: "", value: "" });
  };

  const handleReset = (event) => {
    console.log(event)
    event.stopPropagation();
    props.onReset(props.target, { label: "", value: "" });
  };

  return (
    <div
      ref={ref}
      className="input-select-component"
      style={{
        minWidth: isComponentVisible && !!props.isDesktop ? 360 : "",
      }}
    >
      <div className={`placeholder ${isComponentVisible ? "active" : ""}`}>
        <input
          onClick={() => setIsComponentVisible(true)}
          type="text"
          placeholder={props.placeholder}
          value={props.value.label}
          onChange={(event) =>
            props.target
              ? props.onChange(props.target, {
                  value: event.target.value,
                  label: event.target.value,
                })
              : props.onChange({
                  value: event.target.value,
                  label: event.target.value,
                })
          }
        />

        {isComponentVisible ? null : props.value.value ? (
          <X
            onClick={props.simple ? handleResetSimple : handleReset}
            className="icon-select"
          />
        ) : (
          <CaretDown
            onClick={toggling}
            weight="bold"
            className={`icon-select ${isComponentVisible ? "active" : ""}`}
          />
        )}
      </div>

      {isComponentVisible && (
        <div className="options-container">
          {props.options &&
            props.options.map((option, i) => (
              <div
                key={i}
                onClick={() =>
                  props.simple
                    ? handleChangeSimple(option)
                    : handleChange(option)
                }
                className="option"
              >
                {option.fullLabel || option.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
