import { Select } from "components/inputs/select/Select";
import { GridFour, Heart, List, MapPin, Question, X } from "phosphor-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { addFavoriteResearch } from "reducers/researches";
import { setViewResearch } from "reducers/view-research";
import "./StatusBar.scss";
import { initialSearchParams } from "components/search-bar/SearchBar";
import { getCities } from "reducers/cities";
import { setParams } from "reducers/researchParams";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { InputCheck } from "components/inputs/inputs";
import { InputCheck2 } from "components/inputs/input-check/InputCheck";
import { getAllRealEstates } from "reducers/real-estate";
import moment from "moment";

const StatusBar = ({
  assuredPartecipation,
  setAssuredPartecipation,
  ...props
}) => {
  const dispatch = useDispatch();
  const [favorite, setFavorite] = useState(false);
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const viewResearch = useSelector(({ viewResearch }) => viewResearch);
  const researchId = useSelector(({ realEstate }) => realEstate.researchId);
  const filters = useSelector(({ researchParams }) => researchParams);
  const user = useSelector(({ user }) => user.data.user);
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: 678 });
  const isValid = (val) => !!val && val !== "";
  useEffect(() => {
    setFavorite(false);
  }, [researchId]);
  const savedParams = useSelector(({ researchParams }) => researchParams);

  const handleFavorite = () => {
    if (user) {
      dispatch(addFavoriteResearch(researchId));
      setFavorite(true);
    } else {
      history.push("/inizia");
    }
  };

  const handleTotalReset = () => {
    dispatch(getCities());
    dispatch(setParams(initialSearchParams));
    const params = Object.entries(initialSearchParams)
      .filter((arr) => isValid(arr[1].value))
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr[0]]: curr[1].value,
        };
      }, {});
    props.search(params);
  };

  const handleSetAssuredPartecipation = () => {
    setAssuredPartecipation(!assuredPartecipation);
    // props.search({
    //   ...props.params,
    //   ...(!assuredPartecipation
    //     ? { "auction.dateMin": moment().add(1, "month").format("YYYY-MM-DD") }
    //     : {}),
    // });
  };

  return (
    <div id="wrapper-status-bar" className="container-fluid">
      {filters && Object.values(filters).some((filter) => !!filter.value) && (
        <div className="top">
          <div className="advanced-search" onClick={handleTotalReset}>
            <span>Resetta filtri</span>
            <X />
          </div>
        </div>
      )}
      {/* <div className="top">
        {props.advancedSearch ? (
          <div className="advanced-search" onClick={props.toggleAdvanced}>
            <span>Ricerca semplice</span>
            <Minus />
          </div>
        ) : (
          <div className="advanced-search" onClick={props.toggleAdvanced}>
            {props.activeAdvancedFilters !== 0 && (
              <div className="advanced-active-filters">
                {props.activeAdvancedFilters}{" "}
                {props.activeAdvancedFilters > 1
                  ? "filtri attivi"
                  : "filtro attivo"}
              </div>
            )}
            <span>Ricerca avanzata</span>
            <Plus />
          </div>
        )}
      </div> */}

      <div className="bottom">
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* {isDesktop && researchId && (
            <div onClick={() => handleFavorite()}>
              <Heart
                size={24}
                style={{ marginRight: 8, cursor: "pointer" }}
                className={favorite ? "active" : ""}
              />
            </div>
          )} */}
          <div className="legenda">
            <>
              {props.pagination.totalResultsAuction > 0 && (
                <div>
                  <div className="orangeDot"></div>
                  <div>
                    {props.pagination.totalResultsAuction} Immobili all'asta
                  </div>
                </div>
              )}
              {props.pagination.totalResultsFree > 0 && (
                <div>
                  <div className="azureDot"></div>
                  <div>
                    {props.pagination.totalResultsFree} Immobili in vendita
                    libera
                  </div>
                  {/* <>
                      <Question
                        onMouseEnter={() => setIsTooltipShown(true)}
                        onMouseLeave={() => setIsTooltipShown(false)}
                        style={{ marginBottom: 12, marginLeft: 0 }}
                        size={16}
                      />
                      {isTooltipShown && (
                        <div className="tooltip">
                          Registrati per vedere tutti gli immobili in vendita
                          libera
                        </div>
                      )}
                    </> */}
                </div>
              )}
            </>
          </div>
          {isDesktop && researchId && (
            <div onClick={() => handleFavorite()}>
              <Heart
                size={24}
                style={{ marginLeft: 8, cursor: "pointer" }}
                className={favorite ? "active" : ""}
              />
            </div>
          )}
        </div>

        <div className="sort-option">
          <span>Ordina per</span>

          <Select
            simple
            noReset
            value={props.sort}
            onChange={props.onChange}
            placeholder="Data asta"
            target="sort"
            focused={isMobile}
            options={options}
          />
        </div>

        <div style={{ marginBottom: isDesktop ? 0 : 16 }}>
          <InputCheck2
            placeholder="Partecipazione sicura"
            active={assuredPartecipation}
            handleChange={handleSetAssuredPartecipation}
          />
        </div>

        {isDesktop ? (
          <div className="view-options">
            <MapPin
              className={viewResearch === "map" ? "active" : ""}
              weight="bold"
              onClick={() => dispatch(setViewResearch("map"))}
            />

            <GridFour
              className={viewResearch === "box" ? "active" : ""}
              weight="bold"
              onClick={() => dispatch(setViewResearch("box"))}
            />

            <List
              className={viewResearch === "list" ? "active" : ""}
              weight="bold"
              onClick={() => dispatch(setViewResearch("list"))}
            />
          </div>
        ) : (
          <div className="view-options-mobile">
            <GridFour
              className={viewResearch !== "map" ? "active" : ""}
              weight="bold"
              onClick={() => dispatch(setViewResearch("box"))}
            />

            <MapPin
              className={viewResearch === "map" ? "active" : ""}
              weight="bold"
              onClick={() => dispatch(setViewResearch("map"))}
            />
          </div>
        )}

        {!isDesktop && (
          <div className="number-results-mobile">
            {researchId && (
              <div style={{ display: "flex" }} onClick={() => handleFavorite()}>
                <Heart
                  style={{ height: 16, width: 16, marginRight: 8 }}
                  className={favorite ? "active" : ""}
                />
                <div style={{ color: favorite ? "#46bbc0" : "" }}>
                  Salva ricerca
                </div>
              </div>
            )}
          </div>
        )}
        {/* 
        <div className="advanced-search" onClick={props.toggleAdvanced}>
          {props.activeAdvancedFilters !== 0 && (
            <span className="advanced-active-filters">
              {props.activeAdvancedFilters}{" "}
              {props.activeAdvancedFilters > 1
                ? "filtri attivi"
                : "filtro attivo"}
            </span>
          )}
          <span>Ricerca avanzata</span>
          <Plus />
        </div> */}
        <div className="advanced-search">
          {props.activeAdvancedFilters !== 0 && (
            <span className="advanced-active-filters">
              {props.activeAdvancedFilters}{" "}
              {props.activeAdvancedFilters > 1
                ? "filtri attivi"
                : "filtro attivo"}
            </span>
          )}

          {filters &&
            Object.values(filters).some((filter) => !!filter.value) && (
              <div className="advanced-search" onClick={handleTotalReset}>
                <span>Resetta filtri</span>
                <X />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default StatusBar;

const options = [
  { value: "auction.date:asc", label: "Data asta" },
  { value: "sortPrice:asc", label: "Prezzo crescente" },
  { value: "sortPrice:desc", label: "Prezzo decrescente" },
  { value: "views:desc", label: "Più viste" },
];
