import axios from "axios";
import configurations from "../configurations/index";

export const API_ROOT_URL = configurations.apiUrl;

export const getHeaders = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
};

export const getAuthHeaders = () => {
  const userAS = localStorage.getItem("user");
  let token = "";

  if (userAS) {
    const user = JSON.parse(userAS);
    token = user.authToken;
  }

  return {
    ...getHeaders(),
    Authorization: "Bearer " + token,
  };
};

const requests = {
  // PRICE PLANS

  getPricePlans: async (target) => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/subscription`,
        method: "GET",
        headers: getHeaders(),
        params: {
          target,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  // CUSTOMERS
  getClients: async () => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/business_client`,
        method: "GET",
        headers: getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  // PAYMENTS
  buySub: async (subId, couponCode) => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/subscription/subscribe`,
        method: "POST",
        headers: getAuthHeaders(),
        data: {
          subscriptionId: subId,
          couponCode,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  verifyTransaction: async (id) => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/subscription/verify`,
        method: "GET",
        headers: getAuthHeaders(),
        params: {
          transactionId: id,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  verifyCoupon: async (code) => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/coupon/check?code=${code}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  },

  // CONTACT
  createContact: async (data) => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/contact`,
        method: "POST",
        headers: getAuthHeaders(),
        data,
      });
      // if (response) {
      console.log("contact");
      window.dataLayer.push({
        event: "contact_form",
      });
      // }
      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.message);
      console.log(error.response);
      return null;
    }
  },

  // CITIES
  // getCities: (text) => {
  //   try {
  //     return axios({
  //       url: `${API_ROOT_URL}/cities`,
  //       method: "GET",
  //       headers: getHeaders(),
  //       params: {
  //         comune: text !== "" ? text : null,
  //         onlyAvailable: true,
  //       },
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },

  getCities: (text) => {
    try {
      return axios({
        url: `${API_ROOT_URL}/cities/fast`,
        method: "GET",
        headers: getHeaders(),
        params: {
          comune: text !== "" ? text : null,
          // onlyAvailable: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  },

  // COURTHOUSES
  getCourthouses: async () => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/courthouse`,
        method: "GET",
        headers: getHeaders(),
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  // FORMS
  getForms: async (id) => {
    console.log("2", id);
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/form`,
        method: "GET",
        headers: getHeaders(),
        params: {
          courthouseId: id,
        },
      });
      console.log('1',response)
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  // FAQS
  getCategoriesFaqs: async () => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/faq/categories`,
        method: "GET",
        headers: getHeaders(),
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  getFaqsFromCategory: async (id) => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/faq`,
        method: "GET",
        headers: getHeaders(),
        params: {
          categoryId: id,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  // POSTS
  getPostChosen: async () => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/post/chosen`,
        method: "GET",
        headers: getHeaders(),
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  getPostSingle: (id) => {
    try {
      return axios({
        url: `${API_ROOT_URL}/post/${id}`,
        method: "GET",
        headers: getHeaders(),
      });
    } catch (err) {
      console.log(err);
    }
  },

  getBlogList: async () => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/post`,
        method: "GET",
        headers: getHeaders(),
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  // CALENDAR
  getCalendar: async (params) => {
    try {
      const response = await axios({
        url: `${API_ROOT_URL}/real_estate`,
        method: "GET",
        headers: getAuthHeaders(),
        params,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
};

export default requests;
