import axios from "axios";
import api from "api/favorites";

const GET_ALL_FAVORITE_REAL_ESTATES_REQUEST =
  "GET_ALL_FAVORITE_REAL_ESTATES_REQUEST";
const GET_ALL_FAVORITE_REAL_ESTATES_SUCCESS =
  "GET_ALL_FAVORITE_REAL_ESTATES_SUCCESS";
const GET_ALL_FAVORITE_REAL_ESTATES_FAILURE =
  "GET_ALL_FAVORITE_REAL_ESTATES_FAILURE";

const ADD_FAVORITE_REAL_ESTATE_REQUEST = "ADD_FAVORITE_REAL_ESTATE_REQUEST";
const ADD_FAVORITE_REAL_ESTATE_SUCCESS = "ADD_FAVORITE_REAL_ESTATE_SUCCESS";
const ADD_FAVORITE_REAL_ESTATE_FAILURE = "ADD_FAVORITE_REAL_ESTATE_FAILURE";

const DELETE_FAVORITE_REAL_ESTATE_REQUEST =
  "DELETE_FAVORITE_REAL_ESTATE_REQUEST";
const DELETE_FAVORITE_REAL_ESTATE_SUCCESS =
  "DELETE_FAVORITE_REAL_ESTATE_SUCCESS";
const DELETE_FAVORITE_REAL_ESTATE_FAILURE =
  "DELETE_FAVORITE_REAL_ESTATE_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const reducerFavorites = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FAVORITE_REAL_ESTATES_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_ALL_FAVORITE_REAL_ESTATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_ALL_FAVORITE_REAL_ESTATES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    case ADD_FAVORITE_REAL_ESTATE_REQUEST:
      return { ...state, loading: true, error: false };
    case ADD_FAVORITE_REAL_ESTATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
      };
    case ADD_FAVORITE_REAL_ESTATE_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    case DELETE_FAVORITE_REAL_ESTATE_REQUEST:
      return { ...state, loading: true, error: false };
    case DELETE_FAVORITE_REAL_ESTATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((element) => element.id !== action.payload),
      };
    case DELETE_FAVORITE_REAL_ESTATE_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    default:
      return state;
  }
};

export default reducerFavorites;

export const getAllFavorites = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_FAVORITE_REAL_ESTATES_REQUEST,
  });

  try {
    const { url, method, headers } = api.getAllFavorites();

    const response = await axios({
      url,
      method,
      headers,
    });
    dispatch({
      type: GET_ALL_FAVORITE_REAL_ESTATES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_FAVORITE_REAL_ESTATES_FAILURE,
      payload: error,
    });
  }
};

export const addFavorite = (id) => async (dispatch) => {
  dispatch({
    type: ADD_FAVORITE_REAL_ESTATE_REQUEST,
  });

  try {
    const { url, method, headers, data } = api.addFavorite(id);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: ADD_FAVORITE_REAL_ESTATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_FAVORITE_REAL_ESTATE_FAILURE,
      payload: error,
    });
  }
};

export const deleteFavorite = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_FAVORITE_REAL_ESTATE_REQUEST,
  });

  try {
    const { url, method, headers, data } = api.deleteFavorite(id);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    if (response.data.deleted) {
      dispatch({
        type: DELETE_FAVORITE_REAL_ESTATE_SUCCESS,
        payload: id,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_FAVORITE_REAL_ESTATE_FAILURE,
      payload: error,
    });
  }
};
