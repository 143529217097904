import requests from "api";
import SingleFaq from "pages/faq/single-faq/SingleFaq";
import { useEffect, useState } from "react";

const Faqs = () => {
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    const getFaqsFromCategory = async () => {
      const response = await requests.getFaqsFromCategory(1);
      setFaqList(response);
    };
    getFaqsFromCategory();
  }, []);

  return (
    <div className="info-real-estate-page__faqs">
      <h4 className="mb">FAQ</h4>

      <div className="list-vertical">
        {faqList.map((faq, i) => (
          <SingleFaq key={i} element={faq} />
        ))}
      </div>
    </div>
  );
};

export default Faqs;
