import "./PaymentFeedback.scss";
import { Link } from "react-router-dom";
import apartmentPlaceholder from "../../assets/check-orange.svg";
import { refreshPayingUser, getUser } from "reducers/user";
import requests from "api";
import { deleteCart } from "reducers/cart";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppleLogo, GooglePlayLogo } from "phosphor-react";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const transactionId = useQuery().get("transactionId") || null;
  const isAgent = useSelector(({ user }) => user.data?.user?.role === "agent");
  useEffect(async () => {
    const feedback = () => {
      dispatch(refreshPayingUser());
      dispatch(deleteCart());
      dispatch(getUser());
    };
    feedback();
    const response = await requests.verifyTransaction(transactionId);
    if (response) {
      window.dataLayer.push({
        conversionValue: !isAgent ? 9.99 : 249,
        event: !isAgent ? "subscription_user" : "subscription_agent",
      });
    }
    localStorage.removeItem("couponCode");
  }, [dispatch]);

  return (
    <div className="payment-feedback section space">
      <img src={apartmentPlaceholder} alt="" />

      <h1 className="general" style={{ textAlign: "center" }}>
        Pagamento avvenuto con successo!
      </h1>

      <p style={{ textAlign: "center" }}>
        Il tuo abbonamento mensile è ora attivo!
      </p>

      {isAgent && (
        <>
          <p style={{ textAlign: "center" }}>
            Per poter iniziare a ricevere i primi contatti commerciali scarica
            subito l'App astaéxpert.
          </p>
          <div className="app-description__download">
            <a
              className="apple-store"
              href="https://apps.apple.com/us/app/asta%C3%A9xpert/id1587909501"
              target="_blank"
              rel="noopener noreferrer"
            ></a>

            <a
              className="play-store"
              href="https://play.google.com/store/apps/details?id=com.astaexpert.app"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </div>
        </>
      )}

      {!isAgent ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ textAlign: "center" }}>
            Compila le tue preferenze di ricerche per aiutarci a proporti
            risultati compatibili!
          </p>

          <Link to="/account/dati-utente" className="btn orange">
            VAI ALLA SCHEDA DI PREFERENZE
          </Link>
        </div>
      ) : (
        <Link to="/" className="btn orange">
          TORNA ALLA HOME
        </Link>
      )}
    </div>
  );
};

export default PaymentSuccess;
