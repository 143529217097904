import requests from "api";
import BlogList from "components/blog-list/BlogList";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Fade } from "react-reveal";
import { useParams } from "react-router";
import Content from "./content/Content";
import Intro from "./intro/Intro";

const BlogSingle = () => {
  const [post, setPost] = useState();
  const idParams = useParams().id;
  const id = idParams?.split("-")[0];

  useEffect(() => {
    const getPostSingle = async () => {
      const { data } = await requests.getPostSingle(id);
      setPost(data);
    };
    getPostSingle();
  }, [id]);

  return (
    <div className="bg-pink">
      <Helmet>
        <title>{upperFirst(post?.title)}</title>
        <meta name="description" content={post?.description} />
      </Helmet>
      <Fade>
        {post && (
          <>
            <Intro post={post} />

            <Content post={post} />

            <BlogList related id={id} />
          </>
        )}
      </Fade>
    </div>
  );
};

const upperFirst = (string) => {
  if (!string) return "";
  return string[0].toUpperCase() + string.substring(1).toLowerCase();
};

export default BlogSingle;
