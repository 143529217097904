import "./Faq.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import Img1 from "assets/scene/immobili-carousel.svg";
import { ArrowRight } from "phosphor-react";
import SingleFaq from "./single-faq/SingleFaq";
import { splitNumbers } from "./functions";
import { Fade } from "react-reveal";
import requests from "api";

const Faq = () => {
  const [faqList, setFaqList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    const getCategoriesFaqs = async () => {
      const response = await requests.getCategoriesFaqs();
      setCategoryList(response);
    };
    getCategoriesFaqs();
  }, []);

  useEffect(() => {
    setFaqList([]);
    const getFaqsFromCategory = async () => {
      const response = await requests.getFaqsFromCategory(activeCategory);
      setFaqList(response);
    };
    getFaqsFromCategory();
  }, [activeCategory]);

  return (
    <div className="faq-page">
      <div className="bg-pink">
        <Fade>
          <div className="hero bg-section">
            <div className="container section sub-navbar pad-responsive">
              <h1 className="big">FAQ</h1>
              {/* <div className="all-faq-btn"></div> */}
              <div className="filter-faq">
                <button
                  style={{ maxWidth: 200, minWidth: "19%" }}
                  className={`btn ${
                    activeCategory === null ? "orange" : "empty"
                  }`}
                  onClick={() => setActiveCategory(null)}
                >
                  Tutte le FAQ
                </button>
                {categoryList.map((element, i) => (
                  <button
                    style={{ maxWidth: 200, minWidth: "19%" }}
                    className={`btn ${
                      activeCategory === element.id ? "orange" : "empty"
                    }`}
                    key={i}
                    onClick={() => setActiveCategory(element.id)}
                  >
                    {element.title}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="bg-grey">
            <div className="container">
              <div className="faqs section short">
                {/* <div className="heading-faqs pad-responsive">
                  <h2 className="general">Tutte le FAQ</h2>
                  <img src={Img1} alt="" />
                </div> */}
                <div className="faq-list pad-responsive">
                  {splitNumbers(faqList).map((element, i) => (
                    <div className="list-vertical" key={i}>
                      {element.map((faq, i) => (
                        <SingleFaq key={i} element={faq} />
                      ))}
                    </div>
                  ))}
                </div>
              </div>

              <div className="link-related section only-bottom pad-responsive">
                <div className="link">
                  <h4>Vuoi saperne di più?</h4>
                  <Link to="/contattaci" className="btn orange">
                    <span>Contattaci</span>
                    <ArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Faq;
