import "./Carousel.scss";
import AsteScadenza from "assets/scene/immobili-carousel.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import CardRealEstate from "components/card-real-estate/CardRealEstate";
import { NavigationArrow } from "components/navigation/NavigationArrow";
import { paramsToString } from "utils/paramsToString";
import Placeholder from "./Placeholder";
import { useSelector } from "react-redux";

const Carousel = (props) => {
  const user =
    useSelector(({ user }) => user?.data?.user?.role) !== "paying_user";
  const isAgent = useSelector(({ user }) => user?.data?.user?.role) === "agent";

  return !isAgent ? (
    <div className="home-page__carousel section">
      <div className="list">
        <div className="title pad-responsive">
          <h3 dangerouslySetInnerHTML={{ __html: props.title }} />
          <img src={AsteScadenza} alt="" />
        </div>

        <div className="carousel-list">
          <Swiper
            navigation={{
              prevEl: `.prev-${props.refArrow}`,
              nextEl: `.next-${props.refArrow}`,
            }}
            spaceBetween={24}
            slidesPerView="auto"
            slidesPerGroup={1}
            slidesOffsetBefore={24}
            slidesOffsetAfter={24}
            breakpoints={{
              992: {
                slidesPerGroup: 3,
              },
            }}
          >
            {props.loading ? (
              [...Array(6)].map((x, i) => (
                <SwiperSlide className="home-slider" key={i}>
                  <Placeholder />
                </SwiperSlide>
              ))
            ) : (
              <>
                {props.list.map((element, i) => (
                  <SwiperSlide className="home-slider" key={i}>
                    <CardRealEstate element={element} />
                  </SwiperSlide>
                ))}
                {user && props.list.length === 6 && (
                  <SwiperSlide className="get-premium-card">
                    <Link className="btn orange" to="/inizia">
                      Iscriviti
                    </Link>
                    <div>per vedere altri immobili</div>
                  </SwiperSlide>
                )}
              </>
            )}
          </Swiper>
        </div>
      </div>

      <div className="arrow-container">
        {<NavigationArrow refArrow={props.refArrow} />}

        {!props.doNotShowAll && (
          <Link
            className="btn big"
            to={
              props.sort
                ? {
                    pathname: "/immobili",
                    state: true,
                    search: paramsToString({ sort: props.sort }),
                  }
                : props.simple
                ? {
                    pathname: "/immobili",
                    state: true,
                  }
                : {
                    pathname: "/immobili",
                    search: paramsToString(
                      props.isChosen
                        ? {
                            isChosen: true,
                          }
                        : { saleType: "auction" }
                    ),
                    state: true,
                  }
            }
          >
            Vedi Tutti
          </Link>
        )}
      </div>
    </div>
  ) : null;
};

export default Carousel;
