import Number from "assets/benefits/icona-oltre-30000-immobili.svg";
import Grow from "assets/benefits/icona-metti-a-fuoco-le-tue-competenze.svg";
import Customer from "assets/benefits/icona-aumenta-la-tua-clientela.svg";
import Easy from "assets/benefits/icona-un-sistema-semplice-facile-e-chiaro.svg";
import heroImg from "assets/scene/7.svg";
import Img1 from "assets/scene/9.svg";
import Img2 from "assets/scene/8.svg";

export const hero = {
  img: heroImg,
  destination: "Per gli Avvocati",
  title: "Per te sempre nuovi clienti",
  description: `Metti le tue competenze a disposizione di chi sta comprando casa in
  asta entrando nel network astaéxpert. Fai crescere il giro d'affari,
  apri un nuovo canale di ricavo e diventa un punto di riferimento nella
  tua zona.`,
};

export const services = [
  {
    img: Img1,
    title:
      "Analisi tecnico/legale perizia e ulteriori approfondimenti sulla procedura",
    text: `Una volte che l'utente ha scelto l'immobile e l'agente immobiliare l'ha guidato nella prima visita, entri in gioco tu.`,
  },
  {
    img: Img2,
    title: "Preparazione della domanda di partecipazione e assistenza pre-Asta",
    text: `La visita è andata a buon fine: è il momento di preparsi per la partecipazione all'asta fornendo al cliente le linee guida necessarie per poter iniziare.`,
  },
  {
    img: Img1,
    title:
      "Svolgimento dell’Asta telematica nello studio legale di riferimento",
    text: `Partecipazione telematica all'asta direttamente dal tuo studio.`,
  },
  {
    img: Img2,
    title: "Raggiungimento dell’obiettivo",
    text: `La trattativa può dirsi finalmente conclusa: hai fatto il tuo lavoro ed il tuo cliente ha trovato l'immobile perfetto.`,
  },
];

export const benefits = [
  {
    img: Number,
    title: "Oltre 30.000 immobili",
    text: `Le liste immobili di astaéxpert sono in continuo aggiornamento ed ogni immobile rappresenta una opportunità`,
  },
  {
    img: Grow,
    title: " Metti a frutto le tue compentenze: ai contatti pensiamo noi",
    text: `astaéxpert unisce le competenze di studi legali con agenzie immobiliari per portare il cliente dritto al punto. Alla generazione dei contatti pensiamo noi, tu dovrai occuparti degli aspetti legali della partecipazione all'asta`,
  },
  {
    img: Customer,
    title: "Aumenta la tua clientela in un mercato in espansione",
    text: `Il mercato delle aste è in costante crescita nel nostro paese. L'incremento del numero di immobili battuti in asta anno dopo supera il +25%`,
  },
  {
    img: Easy,
    title: "Un sistema semplice, facile e chiaro",
    text: `Con astaéxpert avrai accesso ad un'area riservata dove troverai i tuoi clienti, la documentazione del caso e quanto ti servirà per poter portare a termine il tuo lavoro`,
  },
];
