import axios from "axios";
import api from "api/researches";

const GET_ALL_FAVORITE_RESEARCHES_REQUEST = "GET_ALL_FAVORITE_RESEARCHES";
const GET_ALL_FAVORITE_RESEARCHES_SUCCESS =
  "GET_ALL_FAVORITE_RESEARCHES_SUCCESS";
const GET_ALL_FAVORITE_RESEARCHES_FAILURE =
  "GET_ALL_FAVORITE_RESEARCHES_FAILURE";

const ADD_FAVORITE_RESEARCH_REQUEST = "ADD_FAVORITE_RESEARCH_REQUEST";
const ADD_FAVORITE_RESEARCH_SUCCESS = "ADD_FAVORITE_RESEARCH_SUCCESS";
const ADD_FAVORITE_RESEARCH_FAILURE = "ADD_FAVORITE_RESEARCH_FAILURE";

const DELETE_FAVORITE_RESEARCH_REQUEST = "DELETE_FAVORITE_RESEARCH_REQUEST";
const DELETE_FAVORITE_RESEARCH_SUCCESS = "DELETE_FAVORITE_RESEARCH_SUCCESS";
const DELETE_FAVORITE_RESEARCH_FAILURE = "DELETE_FAVORITE_RESEARCH_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const reducerResearches = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FAVORITE_RESEARCHES_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_ALL_FAVORITE_RESEARCHES_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_ALL_FAVORITE_RESEARCHES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case ADD_FAVORITE_RESEARCH_REQUEST:
      return { ...state, loading: true, error: false };
    case ADD_FAVORITE_RESEARCH_SUCCESS:
      return { ...state, loading: false, data: [...state, action.payload] };
    case ADD_FAVORITE_RESEARCH_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case DELETE_FAVORITE_RESEARCH_REQUEST:
      return { ...state, loading: true, error: false };
    case DELETE_FAVORITE_RESEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((element) => element.id !== action.payload),
      };
    case DELETE_FAVORITE_RESEARCH_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default reducerResearches;

export const getAllFavoriteResearches = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_FAVORITE_RESEARCHES_REQUEST,
  });

  try {
    const { url, method, headers } = api.getAllFavoriteResearches();

    const response = await axios({
      url,
      method,
      headers,
    });
    dispatch({
      type: GET_ALL_FAVORITE_RESEARCHES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_FAVORITE_RESEARCHES_FAILURE,
      payload: error,
    });
  }
};

export const addFavoriteResearch = (id) => async (dispatch) => {
  dispatch({
    type: ADD_FAVORITE_RESEARCH_REQUEST,
  });

  try {
    const { url, method, headers, data } = api.addFavoriteResearch(id);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    dispatch({
      type: ADD_FAVORITE_RESEARCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_FAVORITE_RESEARCH_FAILURE,
      payload: error,
    });
  }
};

export const deleteFavoriteResearch = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_FAVORITE_RESEARCH_REQUEST,
  });

  try {
    const { url, method, headers, data } = api.deleteFavoriteResearch(id);

    const response = await axios({
      url,
      method,
      headers,
      data,
    });
    if (response.data) {
      dispatch({
        type: DELETE_FAVORITE_RESEARCH_SUCCESS,
        payload: id,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_FAVORITE_RESEARCH_FAILURE,
      payload: error,
    });
  }
};
