const SET_VIEW = "SET_VIEW";

const initialView = () => {
  let view = localStorage.getItem("view");
  if (!view) {
    view = "map";
    localStorage.setItem("view", view);
  }
  return view;
};

const initialState = initialView();

export default function reducerViewResearch(state = initialState, action) {
  switch (action.type) {
    case SET_VIEW:
      return action.payload;
    default:
      return state;
  }
}

export const setViewResearch = (view) => async (dispatch) => {
  localStorage.setItem("view", view);
  window.scrollTo(0, 220);
  dispatch({
    type: SET_VIEW,
    payload: view,
  });
};
