import BannerBenefits from "components/banner-benefits/BannerBenefits";
import { benefits, hero, services } from "./items";
import Hero from "../components/hero/Hero";
import Services from "../components/services/Services";
import App from "./app/App";
import ContactUs from "../components/contact-us/ContactUs";
import How from "./how/How";
// import { Fade } from "react-reveal";

const ForCustomers = () => (
  <div className="for-customers-page">
    {/* <Fade> */}
    <Hero hero={hero} />

    <Services services={services} />

    <How />

    <BannerBenefits benefits={benefits} />

    <App />

    <ContactUs />
    {/* </Fade> */}
  </div>
);

export default ForCustomers;
