import { Input } from "components/form/Form";
import { Popup } from "components/popup/Popup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { loginUser } from "reducers/user";
import "./Login.scss";
import { ReactComponent as Close } from "../../assets/close.svg";
import { API_ROOT_URL } from "api";

const inputs = [
  { target: "email", placeholder: "Email", type: "text" },
  { target: "password", placeholder: "Password", type: "password" },
];

export const Login = ({ history }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.data);
  const error = useSelector(({ user }) => user.error);
  const [form, setForm] = useState({});
  const [isPopupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    user.authToken ? history.push("/") : dispatch(loginUser());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (user.authToken) {
      if (localStorage.getItem("cartItem")) {
        history.push({
          pathname: "/carrello",
          state: { cart: true },
        });
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line
  }, [user.authToken]);

  const handleChangeInput = (target, value) =>
    setForm({ ...form, [target]: value });

  const handleSubmit = () => {
    const { email, password } = form;
    dispatch(loginUser({ email, password }));
  };

  const isDisabled = () => {
    const inputStatus = inputs.map(
      (input) => !!form[input.target] && form[input.target] !== ""
    );
    const inputAreValid = inputStatus.every((input) => !!input);
    return !inputAreValid;
  };

  const handleKeyPress = (event) => {
    const { email, password } = form;

    if (event.key === "Enter") {
      dispatch(loginUser({ email, password }));
    }
  };

  return (
    <div className="login-page pad-responsive">
      {isPopupOpen && (
        <PopupForgottenPassword handleClose={() => setPopupOpen(false)} />
      )}
      <div className="container section intro">
        <h1 className="big">Accedi</h1>

        <h3 className="general">
          Sei nuovo qui?{" "}
          <Link className="link" to="/registrati">
            Registrati
          </Link>
        </h3>
      </div>

      <div className="form-component section">
        {inputs.map((input, i) => (
          <Input
            key={`input_${i}`}
            target={input.target}
            type={input.type}
            placeholder={input.placeholder}
            handleChangeInput={handleChangeInput}
            functionEnter={handleKeyPress}
          />
        ))}
        {error === "error_login" && (
          <div className="error">
            Accesso fallito: l'email o la password sono errati
          </div>
        )}
        <p
          className="forgotten-password"
          onClick={() => {
            setPopupOpen(true);
          }}
        >
          Password dimenticata?{" "}
        </p>

        <button
          type="button"
          className="btn orange center"
          onClick={handleSubmit}
          disabled={isDisabled()}
        >
          Accedi
        </button>
      </div>
    </div>
  );
};

export default Login;

const PopupForgottenPassword = (props) => {
  const [form, setForm] = useState({ email: null });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChangeInput = (target, value) =>
    setForm({ ...form, [target]: value });

  const handleSubmit = async () => {
    setError(null);
    setSuccess(null);
    if (!!form.email) {
      try {
        const result = await axios({
          url: API_ROOT_URL + "/account/reset-password",
          method: "POST",
          data: form,
        });
        // props.handleClose();
        setSuccess(true);
      } catch (err) {
        if (err.response?.data.statusCode === 404) {
          setError("Email non trovata");
        } else {
          setError("Errore generico durante la richiesta");
        }
      }
    } else {
      setError("Inserisci un'email valida");
    }
  };

  return (
    <Popup>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          position: "absolute",
          top: "16px",
          right: "16px",
        }}
      >
        <Close onClick={() => props.handleClose()} width={24} height={24} />
      </div>
      <h2
        style={{
          color: "#ff580a",
          fontWeight: 500,
          paddingBottom: "1rem",
          fontSize: "1.5rem",
        }}
      >
        Password dimenticata?
      </h2>
      <p>
        Inserisci il tuo indirizzo e-mail e ti invieremo un link per reimpostare
        la tua password e accedere di nuovo al tuo account.
      </p>
      <div style={{ padding: "1rem 0" }}>
        <Input
          style={{ padding: "1rem 0" }}
          target={"email"}
          type="text"
          placeholder="Indirizzo email"
          handleChangeInput={handleChangeInput}
          functionEnter={() => console.log("send request")}
        />
      </div>
      {error && (
        <div
          style={{
            textAlign: "center",
            color: "red",
            marginBottom: 16,
            fontSize: 14,
          }}
        >
          {error}
        </div>
      )}
      {!!success ? (
        <div
          style={{
            textAlign: "center",
            color: "green",
          }}
        >
          Una email è stata inviata all'indirizzo indicato
        </div>
      ) : (
        <button
          type="button"
          className="btn orange center"
          onClick={handleSubmit}
          disabled={!form.email}
        >
          Richiedi Link
        </button>
      )}
    </Popup>
  );
};
