import { API_ROOT_URL, getAuthHeaders, getHeaders } from "./index";

const requests = {
  // SESSIONS

  signUp: (newUserData) => {
    return {
      url: `${API_ROOT_URL}/account/signup`,
      method: "POST",
      headers: getHeaders(),
      data: newUserData,
    };
  },

  login: (credentials) => {
    return {
      url: `${API_ROOT_URL}/account`,
      method: "POST",
      headers: getHeaders(),
      data: credentials,
    };
  },

  // USER

  getUser: () => {
    return {
      url: `${API_ROOT_URL}/account`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  refreshPayingUser: () => {
    return {
      url: `${API_ROOT_URL}/account/refresh`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },

  updateUser: (updates) => {
    return {
      url: `${API_ROOT_URL}/account`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updates,
    };
  },

  deleteUser: () => {
    return {
      url: `${API_ROOT_URL}/account`,
      method: "DELETE",
      headers: getAuthHeaders(),
    };
  },
};

export default requests;
