import { API_ROOT_URL, getAuthHeaders } from "./index";

const requests = {
  getAllParticipations: () => {
    return {
      url: `${API_ROOT_URL}/auction`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  requestParticipation: (id) => {
    return {
      url: `${API_ROOT_URL}/auction/${id}`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
};

export default requests;
