import { useEffect } from "react";
import "./Form.scss";

export const Form = (props) => {
  return (
    <div
      className={`form-component ${props.widthClass ? props.widthClass : ""}`}
    >
      {props.title && <h2>{props.title}</h2>}

      {props.inputs?.map((input, i) => (
        <Input
          key={`input_${i}_${props.feedback}`}
          target={input.target}
          type={input.type}
          placeholder={input.placeholder}
          handleChangeInput={props.handleChangeInput}
          error={input.error}
          value={input.value}
        />
      ))}

      {props.textareas?.map((textarea, i) => (
        <Textarea
          id={textarea.id}
          key={`textarea_${i}_${props.feedback}`}
          target={textarea.target}
          placeholder={textarea.placeholder}
          handleChangeInput={props.handleChangeInput}
        />
      ))}

      {props.checkboxes?.map((checkbox, i) => (
        <Checkbox
          defaultValue={checkbox.defaultValue}
          checked={checkbox.checked}
          // checked={checkbox.defaultChecked}
          // value={checkbox.defaultChecked}
          id={`checkbox_${i}`}
          key={`checkbox_${i}_${props.feedback}`}
          target={checkbox.target}
          label={checkbox.label}
          handleChangeInput={props.handleChangeInput}
        />
      ))}

      {props.radiobuttons?.map((radiobutton, i) => (
        <Radiobutton
          id={radiobutton.id}
          name={radiobutton.name}
          key={`radiobutton_${i}_${props.feedback}`}
          target={radiobutton.target}
          label={radiobutton.label}
          handleChangeInput={props.handleChangeInput}
          logo={radiobutton.logo}
        />
      ))}

      {props.feedback && (
        <p
          style={{ textAlign: "center", color: "#00d1ce" }}
          className="feedback-message"
        >
          {props.feedback}
        </p>
      )}

      {props.buttons?.map((button, i) => (
        <button
          onClick={button.handleClick}
          className="btn orange center"
          key={i}
          disabled={props.disabled ? props.disabled() : false}
        >
          {button.text}
        </button>
      ))}
    </div>
  );
};

export const Input = (props) => (
  <>
    <input
      onChange={(event) =>
        props.handleChangeInput(props.target, event.target.value)
      }
      type={props.type}
      value={props.value}
      placeholder={props.placeholder}
      style={{ border: props.error ? "2px solid red" : "none" }}
      onKeyPress={props.functionEnter}
    ></input>
    {props.error && <p className="error-message">{props.error}</p>}
  </>
);

export const Checkbox = (props) => (
  <div className="form-component__checkbox">
    <input
      className={props.defaultValue}
      // value={props.defaultValue}
      checked={props.checked}
      onChange={(event) =>
        props.handleChangeInput &&
        props.handleChangeInput(props.target, event.target.checked)
      }
      type="checkbox"
      id={props.id}
    ></input>

    <label htmlFor={props.id}>{props.label}</label>
  </div>
);

const Textarea = (props) => (
  <textarea
    name={props.name}
    id={props.id}
    cols="30"
    rows="10"
    placeholder={props.placeholder}
    onChange={(event) =>
      props.handleChangeInput(props.target, event.target.value)
    }
  />
);

const Radiobutton = (props) => (
  <div className="form-container__radio">
    <div className="radio__radio">
      <input
        type="radio"
        id={props.id}
        name={props.name}
        onChange={() => props.handleChangeInput(props.name, props.target)}
      />

      <label htmlFor={props.id}>{props.label}</label>
    </div>

    <div className="radio__icons">{props.logo}</div>
  </div>
);

export default Form;
