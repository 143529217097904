import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Dropdown.scss";

const Dropdown = (props) => {
  const [dropdown, setDropdown] = useState(false);

  return (
    <div className="navbar__dropdown" onMouseLeave={() => setDropdown(false)}>
      <div
        className={`link-dropdown ${dropdown ? "active" : ""}`}
        to={props.element?.to}
        onMouseEnter={() => setDropdown(true)}
      >
        {props.element.icon && props.element.icon}
        <span>{props.element.name}</span>
      </div>

      {dropdown && (
        <div className="menu">
          {props.element.dropdown.map((element, i) =>
            element.function ? (
              <div
                key={`${i}_${element.name}`}
                className="logout"
                onClick={element.function}
              >
                {element.name}
              </div>
            ) : (
              <NavLink
                exact
                onClick={() => setDropdown(false)}
                to={element.to}
                key={`${i}_${element.name}`}
                dangerouslySetInnerHTML={{ __html: element.name }}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
