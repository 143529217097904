import FormContact from "components/form-contact/FormContact";
import { Fade } from "react-reveal";
import "./ContactUs.scss";

const ContactUs = () => (
  <Fade>
    <div className="why-asta__contact-us section pad-responsive">
      <h2 className="general">Contattaci per maggiori informazioni</h2>

      <FormContact />
    </div>
  </Fade>
);

export default ContactUs;
