import "./AccountSubscription.scss";
import requests from "api";
import { useDispatch, useSelector } from "react-redux";
import Fade from "react-reveal";
import moment from "moment";
import { Popup } from "components/popup/Popup";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { ReactComponent as Close } from "../../../assets/close.svg";

import { useEffect, useState } from "react";
import {
  deleteSubscription,
  getSubscription,
  renewSubscription,
  // subscribe,
} from "reducers/subscriptions";
import Plans from "pages/home/plans/Plans";

const AccountSubscription = () => {
  const dispatch = useDispatch();
  useEffect(() => dispatch(getSubscription()), []);

  const [isPopupOpen, setPopupOpen] = useState(false);

  const subscription = useSelector(({ subscriptions }) => subscriptions.data);
  const error = useSelector(({ subscriptions }) => subscriptions.error);

  let isSubscriptionExpired =
    moment(subscription?.validTo) < moment() && !!subscription?.numeroContratto;

  let isSubscriptionCancelled = !subscription?.numeroContratto;

  let isSubscriptionForever = false;

  if (moment(subscription?.validTo) > moment().add(10, "years")) {
    isSubscriptionExpired = false;
    isSubscriptionCancelled = false;
    isSubscriptionForever = true;
  }

  const _deleteSubscription = () => {
    dispatch(deleteSubscription());
    setPopupOpen(false);
  };

  const _subscribe = async () => {
    const code = localStorage.getItem("couponCode");
    const link = await requests.buySub(
      { subscriptionId: subscription.subscriptionId },
      code
    );
    window.location.href = link;
  };

  const _renewSubscription = () => {
    dispatch(renewSubscription());
  };

  if (!subscription) {
    return <Plans />;
  }

  return (
    <>
      {isPopupOpen && (
        <PopupConfirmDelete
          handleClose={() => setPopupOpen(false)}
          handleConfirm={_deleteSubscription}
        />
      )}
      <Fade>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            // backgroundColor: "red",
            width: "100%",
          }}
        >
          <div className="subscription-container">
            <h4>Tipo abbonamento</h4>
            <div className="data-box">
              {subscription && (
                <>
                  <div className="title-container">
                    {isSubscriptionExpired ? (
                      <div className="inactive-subscription">{`${subscription?.subscription.type}`}</div>
                    ) : isSubscriptionCancelled ? (
                      <div className="inactive-subscription">{`${subscription?.subscription.type}`}</div>
                    ) : (
                      <div className="active-subscription">{`${subscription?.subscription.type}`}</div>
                    )}
                    {!isSubscriptionForever && (
                      <div>
                        <span
                          style={{ fontSize: 24, fontWeight: 500 }}
                        >{`${subscription?.subscription.price}€`}</span>
                        {`/mese`}
                      </div>
                    )}
                  </div>
                  {isSubscriptionForever ? (
                    <div
                      className="subscription-date"
                      style={{ padding: 0, paddingTop: 16 }}
                    >
                      Siamo felici di comunicarvi che il nostro sito
                      astaexpert.it sta crescendo grazie alla vostra fiducia.
                      <br />
                      <br />
                      Siamo consapevoli che avete scelto di darci una
                      possibilità acquistando il nostro abbonamento, e per
                      questo vi siamo immensamente grati. Per dimostrare il
                      nostro apprezzamento, abbiamo deciso di regalarvi il
                      vostro abbonamento per sempre. Sì, avete letto bene! Non
                      dovrete preoccuparvi di rinnovare l'abbonamento ogni mese,
                      perché ve lo regaliamo noi.
                      <br />
                      <br />
                      Questo è solo un piccolo gesto per dimostrarvi la nostra
                      gratitudine per averci dato fiducia fin dall'inizio.
                      Speriamo di potervi offrire ancora di più in futuro e di
                      potervi aiutare a ottenere i risultati desiderati
                      attraverso la nostra piattaforma.
                      <br />
                      <br />
                      Grazie ancora per il vostro sostegno e la vostra fiducia
                      in noi.
                    </div>
                  ) : isSubscriptionExpired ? (
                    <>
                      <div className="subscription-date">
                        {`Non è stato possibile rinnovare l'abbonamento in data: `}
                        <span className="subscription-date">{`${moment(
                          subscription?.validTo
                        ).format("DD/MM/YYYY")}`}</span>
                      </div>
                    </>
                  ) : isSubscriptionCancelled ? (
                    <div className="subscription-date">
                      {`L'abbonamento è stato disattivato in data: `}
                      <span className="subscription-date">{`${moment(
                        subscription?.updatedAt
                      ).format("DD/MM/YYYY")}`}</span>

                      <div style={{ paddingTop: 8 }}>
                        {`Potrai continuare ad accedere all'abbonamento fino al: `}
                        <span className="subscription-date">{`${moment(
                          subscription?.validTo
                        ).format("DD/MM/YYYY")}`}</span>
                      </div>
                    </div>
                  ) : subscription?.toBeDeleted ? (
                    <>
                      <div className="subscription-date">
                        {`L'abbonamento si rinnoverà un'ultima volta in data: `}
                        <span>{`${moment(subscription?.validTo).format(
                          "DD/MM/YYYY"
                        )}`}</span>
                        <p>
                          {`Potrai continuare ad accedere all'abbonamento fino al: `}
                          <span className="subscription-date">{`${moment(
                            subscription?.validTo
                          )
                            .add(1, "M")
                            .format("DD/MM/YYYY")}`}</span>
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="subscription-date">
                        {`Rinnovo automatico in data: `}
                        <span>{`${moment(subscription?.validTo).format(
                          "DD/MM/YYYY"
                        )}`}</span>
                      </div>
                    </>
                  )}
                  {!isSubscriptionForever ? (
                    isSubscriptionExpired ? (
                      <button
                        onClick={() => _renewSubscription()}
                        className="btn orange"
                        style={{ alignSelf: "flex-start" }}
                      >
                        Rinnova
                      </button>
                    ) : isSubscriptionCancelled ? (
                      <button
                        onClick={() => _subscribe()}
                        className="btn orange"
                        style={{ alignSelf: "flex-start" }}
                      >
                        Riabbonati
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={() => setPopupOpen(true)}
                          className="btn empty empty-orange"
                          style={{ alignSelf: "flex-start" }}
                        >
                          Annulla abbonamento
                        </button>
                      </>
                    )
                  ) : null}
                  {!!error && (
                    <div
                      style={{
                        marginTop: 12,
                        color: "red",
                        fontSize: "0.8rem",
                      }}
                    >
                      Errore durante l'annullamento dell'abbonamento, riprova
                      tra qualche minuto. Se il problema persiste contattata il
                      supporto clienti.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {subscription?.transactions && (
            <div className="subscription-container">
              <h4>Storico transazioni</h4>
              <div className="data-box">
                {subscription && (
                  <div className="transactions-table">
                    <div
                      className="transaction-row"
                      style={{
                        paddingTop: 0,
                        //  paddingRight: 14
                      }}
                    >
                      <div
                        className="transaction-title"
                        style={{ flex: 2, justifyContent: "flex-start" }}
                      >
                        N°TRANSAZIONE
                      </div>
                      <div className="transaction-title">DATA</div>
                      <div className="transaction-title">IMPORTO</div>
                      <div className="transaction-title">STATUS</div>
                    </div>
                    <div className="transaction-body">
                      {subscription.transactions.map((transaction, i) => {
                        return (
                          <div className="transaction-row" key={transaction.id}>
                            <div
                              className="transaction-cell"
                              style={{ flex: 2, justifyContent: "flex-start" }}
                            >
                              {transaction.transactionCode}
                            </div>
                            <div className="transaction-cell">
                              {moment(transaction.payedDate).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                            <div className="transaction-cell">
                              {`${transaction.amount} €`}
                            </div>
                            <div className="transaction-cell">
                              {transaction.status === "OK" ? (
                                <FaCheckCircle
                                  size={20}
                                  style={{ color: "limegreen" }}
                                />
                              ) : (
                                <FaTimesCircle
                                  size={20}
                                  style={{ color: "crimson" }}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Fade>
    </>
  );
};

export default AccountSubscription;

const PopupConfirmDelete = (props) => {
  return (
    <Popup>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          position: "absolute",
          top: "16px",
          right: "16px",
        }}
      >
        <Close onClick={() => props.handleClose()} width={24} height={24} />
      </div>
      <h2
        style={{
          color: "#ff580a",
          fontWeight: 500,
          paddingBottom: "1rem",
          fontSize: "1.5rem",
        }}
      >
        Cancellazione abbonamento
      </h2>
      <p>
        L'annullamento sarà effettivo al termine del periodo di abbonamento in
        corso, se richiesto almeno 48 (quarantotto) ore prima del termine del
        periodo. Sicuro di voler procedere?
      </p>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "1rem",
        }}
      >
        <button
          type="button"
          className="btn empty "
          onClick={() => props.handleClose()}
        >
          Annulla
        </button>
        <button
          type="button"
          className="btn orange "
          onClick={() => props.handleConfirm()}
        >
          Conferma
        </button>
      </div>
    </Popup>
  );
};
