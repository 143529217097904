import { Select } from "components/inputs/inputs";
import {
  CalendarArrowLeft,
  CalendarArrowRight,
} from "components/navigation/NavigationArrow";
import moment from "moment";
import { formatCalendar, weekDays } from "pages/calendar/function";
import { CalendarBlank, X } from "phosphor-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAllVisits, requestVisit } from "reducers/visits";
import "./Booking.scss";
import { isEqual } from "lodash";

const Booking = ({ realEstateId, setIsComponentVisible, refp }) => {
  const user =
    useSelector(({ user }) => user?.data?.user?.role) === "paying_user";
  const history = useHistory();
  const visits = useSelector(({ visits }) => visits.data);
  const checkAlreadyVisit = visits.some(
    ({ realEstate }) => realEstate.id === realEstateId
  );

  const handleClick = () =>
    user
      ? setIsComponentVisible(
          <BookingOverlay
            setIsComponentVisible={setIsComponentVisible}
            realEstateId={realEstateId}
            refp={refp}
          />
        )
      : history.push("/inizia");

  return (
    <button
      onClick={handleClick}
      className={`info-real-estate-page__booking btn orange center ${
        checkAlreadyVisit ? "done" : ""
      }`}
    >
      <CalendarBlank weight="bold" />

      <span>
        {checkAlreadyVisit
          ? "Prenotazione visita richiesta"
          : "Richiedi prenotazione visita"}
      </span>
    </button>
  );
};

export default Booking;

const initialSlot = { label: "Slot Orario", value: "" };

const BookingOverlay = ({ realEstateId, setIsComponentVisible, refp }) => {
  const dispatch = useDispatch();
  const [monthActive, setMonthActive] = useState(moment());
  const [feedback, setFeedback] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(initialSlot);

  const sendRequestVisit = async () => {
    await dispatch(requestVisit(realEstateId, selectedDay, selectedSlot.value));
    dispatch(getAllVisits());
    document.body.classList.remove("no-scroll");
    setFeedback(true);
  };

  const addMonth = () => setMonthActive(moment(monthActive).add(1, "months"));

  const subtractMonth = () =>
    setMonthActive(moment(monthActive).subtract(1, "months"));

  const close = () => {
    setIsComponentVisible(false);
    document.body.classList.remove("no-scroll");
  };

  const isSelectedMonth = (date) =>
    moment(monthActive).format("MMMM YYYY") ===
    moment(date).format("MMMM YYYY");

  const calendar = formatCalendar(monthActive);

  return (
    <div className="overlay__booking" ref={refp}>
      {feedback ? (
        <>
          <div className="feedback">Prenotazione inviata!</div>
          <div onClick={close} className="close">
            Chiudi
          </div>
        </>
      ) : (
        <>
          <X className="close-banner" onClick={close} />

          <div className="month-section">
            <CalendarArrowLeft
              small
              leftDisabled={
                moment().format("MMMM YYYY") ===
                moment(monthActive).format("MMMM YYYY")
              }
              handleClick={subtractMonth}
            />

            <div className="month">
              {moment(monthActive).format("MMMM YYYY")}
            </div>

            <CalendarArrowRight small handleClick={addMonth} />
          </div>

          <div className="heading-calendario-aste">
            {weekDays.map((element, i) => (
              <div className="day" key={i}>
                {window.innerWidth > 768 ? element : element.slice(0, 1)}
              </div>
            ))}
          </div>

          <div className="blocks">
            {calendar.map((week) =>
              week.map((day) => (
                <div
                  key={`day_${day}`}
                  className={`block ${
                    isSelectedMonth(day) && moment(day) > moment()
                      ? ""
                      : "disabled"
                  } ${isEqual(moment(day), selectedDay) ? "active" : ""}`}
                  onClick={() => setSelectedDay(moment(day))}
                >
                  {moment(day).format("DD")}
                </div>
              ))
            )}
          </div>

          {selectedDay && (
            <Select
              simple
              noReset
              value={selectedSlot}
              onChange={setSelectedSlot}
              options={options}
            />
          )}

          <div className="buttons-booking">
            <button onClick={close} className="btn white uppercase">
              annulla prenotazione
            </button>

            <button
              disabled={!selectedDay || selectedSlot.value === ""}
              className="btn orange"
              onClick={sendRequestVisit}
            >
              richiedi prenotazione
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const options = [
  { label: "08:00 - 10:00", value: "08:00 - 10:00" },
  { label: "10:00 - 12:00", value: "10:00 - 12:00" },
  { label: "12:00 - 14:00", value: "12:00 - 14:00" },
  { label: "14:00 - 16:00", value: "14:00 - 16:00" },
  { label: "16:00 - 18:00", value: "16:00 - 18:00" },
];
