import { CaretDown, X } from "phosphor-react";
// import { isMobile } from "react-device-detect";
import useComponentVisible from "utils/useComponentVisible";
import "./Select.scss";

export const Select = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible, toggling } =
    useComponentVisible(false);

  const handleChangeSimple = (option) => {
    props.onChange(option);
    setIsComponentVisible(false);
  };

  const handleChange = (option) => {
    if (props.target2) {
      props.onChange(
        props.target,
        {
          ...option,
          value: +option.value,
        },
        props.target2,
        {
          ...option,
          value: +option.value - props.delta,
        }
      );
    } else {
      props.onChange(props.target, option);
    }
    setIsComponentVisible(false);
  };

  const handleReset = (event) => {
    event.stopPropagation();
    if (props.target2) {
      props.onReset(
        props.target,
        { value: "", label: props.placeholder },
        props.target2,
        { value: "", label: props.placeholder }
      );
    } else {
      props.onReset(props.target, { value: "", label: props.placeholder });
    }
  };

  return (
    <div
      ref={ref}
      className={`select-component ${props.focused ? "focused" : ""}`}
    >
      <div
        onClick={toggling}
        className={`placeholder ${isComponentVisible ? "active" : ""}`}
      >
        {props.value.label}

        {!props.noReset && props.value.value ? (
          <X onClick={handleReset} className="icon-select" />
        ) : (
          <CaretDown
            weight="bold"
            className={`icon-select ${isComponentVisible ? "" : "active"}`}
          />
        )}
      </div>

      {isComponentVisible && (
        <div className="options-container">
          {props.options
            .filter((value) => value.value !== props.value.value)
            .map((option, i) => (
              <div
                key={`option_${i}`}
                onClick={() =>
                  props.simple
                    ? handleChangeSimple(option)
                    : handleChange(option)
                }
                className="option"
              >
                {props.measure} {option.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
