import "./NavigationArrow.scss";
import { ArrowLeft, ArrowRight } from "phosphor-react";

export const NavigationArrow = (props) => (
  <div className="navigation-arrow">
    <NavigationArrowLeft
      small={props.small}
      refArrow={`prev-${props.refArrow}`}
    />
    <NavigationArrowRight
      small={props.small}
      refArrow={`next-${props.refArrow}`}
    />
  </div>
);

export const NavigationArrowLeft = (props) => (
  <ArrowLeft
    onClick={props.handleClick && props.handleClick}
    weight="bold"
    className={`arrow-left ${props.refArrow ? props.refArrow : ""} ${
      props.small ? " small" : ""
    }`}
  />
);

export const NavigationArrowRight = (props) => (
  <ArrowRight
    weight="bold"
    className={`arrow-right ${props.small ? "small" : ""} ${
      props.orange ? "orange" : ""
    } ${props.refArrow ? props.refArrow : ""}`}
  />
);

export const CalendarArrow = (props) => (
  <div className="navigation-arrow">
    <CalendarArrowLeft
      small={props.small}
      leftDisabled={props.leftDisabled}
      handleClick={props.handleClickLeft}
    />
    <CalendarArrowRight
      small={props.small}
      handleClick={props.handleClickRight}
    />
  </div>
);

export const CalendarArrowLeft = (props) => (
  <ArrowLeft
    className={`arrow-left prev-home ${props.small ? "small" : ""} ${
      props.leftDisabled ? "disabled" : ""
    }`}
    weight="bold"
    onClick={props.handleClick && (() => props.handleClick())}
  />
);

export const CalendarArrowRight = (props) => (
  <ArrowRight
    className={`arrow-right next-home ${props.small ? "small" : ""} ${
      props.orange ? "orange" : ""
    }`}
    weight="bold"
    onClick={() => props.handleClick()}
  />
);
