import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useParams } from "react-router";
import { addFavorite, deleteFavorite } from "reducers/favorites";
import { getAllRealEstates, getRealEstateById } from "reducers/real-estate";
import useComponentVisible from "utils/useComponentVisible";
import Attachments from "./attachments/Attachments";
import Booking from "./booking/Booking";
import Carousel from "./carousel/Carousel";
import Description from "./description/Description";
import Faqs from "./faqs/Faqs";
import HeadingBottom from "./heading-bottom/HeadingBottom";
import HeadingTop from "./heading-top/HeadingTop";
import InfoBar from "./infobar/InfoBar";
import "./InfoRealEstate.scss";
import IntroSpecs from "./introspecs/IntroSpecs";
import JoinAuction from "./join-auction/JoinAuction";
import MapSection from "./map-section/MapSection";
import Placeholder from "./Placeholder";
import Specs from "./specs/Specs";
import CarouselHome from "pages/home/carousel/Carousel";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";

const InfoRealEstate = () => {
  const dispatch = useDispatch();
  const id = +useParams().id;
  const singleRealEstate = useSelector(
    ({ realEstate }) => realEstate.singleRealEstate
  );
  const error = useSelector(({ realEstate }) => realEstate.error);
  const loading = useSelector(({ realEstate }) => realEstate.loading);
  const favorites = useSelector(({ favorites }) => favorites.data);
  const realEstates = useSelector(({ realEstate }) => realEstate.data);
  const daysToAuction =
    singleRealEstate?.auction &&
    moment(singleRealEstate?.auction.date).diff(moment(), "days") + 1;
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false, true);
  const isDesktop = useMediaQuery({ minWidth: 678 });
  const user = useSelector(({ user }) => user.data.user);
  const history = useHistory();

  const isAuctionAvailable = singleRealEstate?.auction
    ? moment(singleRealEstate?.auction.date).format("YYYY-MM-DD") >=
      moment().format("YYYY-MM-DD")
    : true;

  useEffect(() => {
    if (!isAuctionAvailable) {
      history.replace("/immobile-non-trovato");
    }
  }, [isAuctionAvailable, history]);

  useEffect(() => {
    if (!!error && !loading && isEmpty(singleRealEstate)) {
      history.replace("/immobile-non-trovato");
    }
  }, [error, loading, history, singleRealEstate]);

  useEffect(() => dispatch(getRealEstateById(id)), [dispatch, id]);

  useEffect(() => {
    if (!isEmpty(singleRealEstate) && realEstates.length === 0) {
      dispatch(getAllRealEstates(null, singleRealEstate));
    }
  }, [dispatch, singleRealEstate, realEstates.length]);

  const handleFavorite = () => {
    const isFavorite = favorites.find((value) => value.id === id);
    if (user) {
      if (isFavorite) {
        dispatch(deleteFavorite(id));
      } else {
        dispatch(addFavorite(id));
      }
    } else {
      history.push("/inizia");
    }
  };

  const current = realEstates.find((value) => value.id === id);

  const setIsComponentVisibleScroll = (content) => {
    setIsComponentVisible(content);
    document.body.classList.add("no-scroll");
  };

  return (
    <div className="info-real-estate">
      <div
        className={
          !isAuctionAvailable
            ? "info-real-estate-page blur"
            : "info-real-estate-page"
        }
      >
        {!singleRealEstate || loading ? (
          <div className="container pad-responsive">
            <Placeholder />
          </div>
        ) : isEmpty(singleRealEstate) ? (
          <div className="container info-real-estate-page__no-result">
            <p>Immobile non trovato</p>
            <Link to="/immobili">Vai alla ricerca immobili</Link>
          </div>
        ) : (
          <>
            <div className="container pad-responsive">
              {isComponentVisible && (
                <div className="overlay">{isComponentVisible}</div>
              )}

              <HeadingTop
                singleRealEstate={singleRealEstate}
                daysToAuction={daysToAuction}
                current={current}
              />

              <HeadingBottom
                singleRealEstate={singleRealEstate}
                handleFavorite={handleFavorite}
              />
              <div
                className={`intro${
                  singleRealEstate.saleType !== "auction" ? "-free" : ""
                } section`}
              >
                <Carousel
                  gallery={singleRealEstate.gallery}
                  saleType={singleRealEstate.saleType === "auction"}
                  virtualTour={singleRealEstate.virtualToor}
                />

                {singleRealEstate.saleType === "auction" && (
                  <IntroSpecs singleRealEstate={singleRealEstate} />
                )}
              </div>

              <div className="info-real-estate-page__content section only-bottom">
                <div className="info">
                  <InfoBar singleRealEstate={singleRealEstate} />

                  <div className="text">
                    <Description
                      description={singleRealEstate.description}
                      procedureNumber={
                        singleRealEstate.auction?.pvpProcedureNumber
                      }
                    />

                    <Specs singleRealEstate={singleRealEstate} />

                    {!!singleRealEstate.lat &&
                      !!singleRealEstate.lon &&
                      !!singleRealEstate.lat !== "0" && (
                        <MapSection
                          position={{
                            lat: singleRealEstate.lat,
                            lon: singleRealEstate.lon,
                          }}
                          saleType={singleRealEstate.saleType}
                        />
                      )}
                  </div>

                  {singleRealEstate.saleType === "auction" && isDesktop && (
                    <Faqs />
                  )}
                </div>

                <div className="side-bar">
                  <Booking
                    setIsComponentVisible={setIsComponentVisibleScroll}
                    realEstateId={id}
                    refp={ref}
                  />

                  {singleRealEstate.auction && (
                    <JoinAuction
                      refp={ref}
                      setIsComponentVisible={setIsComponentVisibleScroll}
                      realEstateId={singleRealEstate.auction.id}
                    />
                  )}

                  {singleRealEstate.attachments?.length > 0 && (
                    <Attachments attachments={singleRealEstate.attachments} />
                  )}
                </div>

                {singleRealEstate.saleType === "auction" && !isDesktop && (
                  <Faqs />
                )}
              </div>
            </div>

            {realEstates.filter((realEstate) => realEstate.id !== id).length >
              0 && (
              <CarouselHome
                simple
                doNotShowAll
                list={realEstates.filter((realEstate) => realEstate.id !== id)}
                title="Potrebbero interessarti"
              />
            )}
          </>
        )}
      </div>
      {!isAuctionAvailable && (
        <div className="expired-auction-layer">
          {" "}
          <div className="expirated-auction-advert">
            Ci dispiace, quest'asta è scaduta!
          </div>
          <Link className="btn" to="/">
            TORNA ALLA HOME
          </Link>
          {/* <div className="btn">TORNA ALLA HOME</div> */}
        </div>
      )}
    </div>
  );
};

export default InfoRealEstate;
