import "./CarouselExtended.scss";
import Map from "../Map";
import { CaretLeft, CaretRight } from "phosphor-react";
import { Swiper, SwiperSlide } from "swiper/react";
import apartmentPlaceholder from "assets/scene/casa.svg";

const CarouselExtended = (props) => (
  <Swiper
    navigation={{
      prevEl: ".prev-mini-img",
      nextEl: ".next-mini-img",
    }}
    className="card-real-estate-extended__carousel"
  >
    {props.auction && props.daysToAuction > 0 && (
      <div className="auction-date">
        <span>
          {props.daysToAuction}{" "}
          {props.daysToAuction > 1 ? "giorni all'asta" : "giorno all'asta"}
        </span>
        <div
          className="dot"
          style={{
            background: renderColor(props.daysToAuction),
            color: renderColor(props.daysToAuction),
          }}
        ></div>
      </div>
    )}

    <SwiperSlide>
      <div
        className="apartment-placeholder"
        style={{
          background:
            props.saleType === "free"
              ? "var(--colorLightBlue)"
              : "var(--colorOrange)",
        }}
      >
        <img src={apartmentPlaceholder} alt="" />
      </div>
    </SwiperSlide>

    {props.gallery.length !== 0 &&
      props.gallery.map((img, i) => (
        <SwiperSlide key={i}>
          <img className="apartment-img" src={img} alt="" />
        </SwiperSlide>
      ))}

    <SwiperSlide>
      {({ isActive }) =>
        isActive && <Map extended lat={props.lat} lon={props.lon} />
      }
    </SwiperSlide>

    <CaretLeft className="prev-mini-img" weight="bold" />
    <CaretRight className="next-mini-img" weight="bold" />
  </Swiper>
);

export default CarouselExtended;

const renderColor = (days) => {
  if (days > 30) {
    return "limegreen";
  } else if (days > 15) {
    return "gold";
  } else if (days > 5) {
    return "orangered";
  } else if (days > 0) {
    return "red";
  }
};
