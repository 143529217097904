import Number from "assets/benefits/icona-oltre-30000-immobili.svg";
import Grow from "assets/benefits/icona-metti-a-fuoco-le-tue-competenze.svg";
import Customer from "assets/benefits/icona-aumenta-la-tua-clientela.svg";
import Easy from "assets/benefits/icona-un-sistema-semplice-facile-e-chiaro.svg";
import heroImg from "assets/scene/8.svg";
import Img1 from "assets/scene/9.svg";
import Img2 from "assets/scene/8.svg";

export const hero = {
  img: heroImg,
  destination: "Per gli Agenti Immobiliari",
  title: "Trova più clienti e vendi più immobili",
  description: `Grazie al network di astaéxpert, mettersi in contatto con chi è
  interessato a comprare un immobile non è mai stato così semplice.
  Unisci la tua professionalità a quella di uno studio legale, trova più
  clienti e vendi più immobili.`,
};

export const services = [
  {
    img: Img1,
    title: "Apertura pratica di ricerca",
    text: `Il cliente sta cercando un immobile e qui entri in gioco tu: aprire la pratica di ricerca, suggerendo al cliente la registrazione tramite app iOS o Android oppure tramite sitoweb, a soli 9.99€/mese. Il cliente potrà cosi scegliere tra oltre 30.000 immobili sul mercato dalle aste oppure valutare una compravendita sul mercato libero, scegliendo anche tra gli immobili a tuo portafoglio! Convertire un potenziale in cliente in una vendita eseguita con noi diventa ancora più facile.`,
  },
  {
    img: Img2,
    title: "Definizione delle preferenze immobiliari ",
    text: `E' il momento di fare l'idenkit dell'immobile perfetto per il tuo cliente. Utilizza i numerosi filtri offerti dal nostro sistema per trovare la soluzione migliore`,
  },
  {
    img: Img1,
    title: "Trova sul nostro portale interno l’immobile adatto al cliente",
    text: `Quadrilocali, giardino, box auto, classe energetica. Lo sai meglio di noi quanto possa essere difficile soddisfare tutte le richieste di un potenziale cliente. Fortunamente astaéxpert è pensato per offrirti un'esperienza di ricerca semplice ed immediata, e studiato per dare al cliente un confronto immediato tra aste e mercato libero, cosi che tu possa accompagnarlo verso la migliore scelta, quella della casa più adatta alle sue esigenze.`,
  },
  {
    img: Img2,
    title: "Visita dell’immobile con il cliente",
    text: `Il tuo ultimo passaggio. Guida il cliente nella visita dell'immobile che hai scelto. Da questo punto seguiranno le diverse analisi tecnico/legali passando per l'asta fino ad arrivare alla vendita vera e propria. `,
  },
];

export const benefits = [
  {
    img: Number,
    title: "Oltre 30.000 immobili",
    text: `Le liste immobili di astaéxpert sono in continuo aggiornamento ed ogni immobile rappresenta una opportunità`,
  },
  {
    img: Grow,
    title: " Metti a frutto le tue compentenze: ai contatti pensiamo noi",
    text: `astaéxpert unisce le competenze di studi legali con agenzie immobiliari per portare il cliente dritto al punto. Alla generazione dei contatti pensiamo noi, tu dovrai occuparti degli aspetti legali della partecipazione all'asta`,
  },
  {
    img: Customer,
    title: "Aumenta la tua clientela in un mercato in espansione",
    text: `Il mercato delle aste è in costante crescita nel nostro paese. L'incremento del numero di immobili battuti in asta anno dopo supera il +25%`,
  },
  {
    img: Easy,
    title: "Un sistema semplice, facile e chiaro",
    text: `Con astaéxpert avrai accesso ad un'area riservata dove troverai i tuoi clienti, la documentazione del caso e quanto ti servirà per poter portare a termine il tuo lavoro`,
  },
];
