import { CaretDown } from "phosphor-react";
import { useState } from "react";

const Description = ({ description, procedureNumber }) => {
  const [active, setActive] = useState(true);

  return (
    <div className="description">
      <div
        onClick={() => setActive((state) => !state)}
        className="heading-section"
      >
        <h4>Descrizione</h4>

        <CaretDown weight="bold" className={active ? "active" : ""} />
      </div>
      {active && (
        <>
          <p>{description}</p>
          {procedureNumber && <p>{`Numero procedura: AE${procedureNumber}`}</p>}
        </>
      )}
    </div>
  );
};

export default Description;
