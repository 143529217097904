import "./Carousel.scss";
import Map from "../Map";
import { CaretLeft, CaretRight } from "phosphor-react";
import { Swiper, SwiperSlide } from "swiper/react";
import apartmentPlaceholder from "assets/scene/casa.svg";

const Carousel = (props) => (
  <Swiper
    navigation={{
      prevEl: ".prev-mini-img",
      nextEl: ".next-mini-img",
    }}
    className="card-real-estate__carousel"
  >
    <SwiperSlide>
      <div
        className="apartment-placeholder"
        style={{
          background:
            props.saleType === "free"
              ? "var(--colorLightBlue)"
              : "var(--colorOrange)",
        }}
      >
        <img src={apartmentPlaceholder} alt="" />
      </div>
    </SwiperSlide>

    {props.gallery.length !== 0 &&
      props.gallery.map((img, i) => (
        <SwiperSlide key={`swiper_apt_${i}`}>
          <img className="apartment-img" src={img} alt="" />
        </SwiperSlide>
      ))}

    <SwiperSlide>
      {({ isActive }) =>
        isActive && (
          <Map lat={props.lat} lon={props.lon} saleType={props.saleType} />
        )
      }
    </SwiperSlide>

    <CaretLeft className="prev-mini-img" weight="bold" />
    <CaretRight className="next-mini-img" weight="bold" />
  </Swiper>
);

export default Carousel;
