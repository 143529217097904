import "./Hero.scss";
import { Fade } from "react-reveal";

const Hero = ({ hero }) => (
  <Fade>
    <div className="why-asta-destinations__hero pad-responsive container section sub-navbar bg-container">
      <div
        className="img"
        style={{ backgroundImage: `url(${hero.img})` }}
      ></div>
      <div className="description ">
        {hero.destination && (
          <span className="category">{hero.destination}</span>
        )}
        <h2 className="general">{hero.title}</h2>
        <p>{hero.description}</p>
      </div>
    </div>
  </Fade>
);
export default Hero;
