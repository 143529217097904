import { GoogleMap, Marker } from "@react-google-maps/api";
import OrangePin from "assets/map-pin-orange.png";
import BluePin from "assets/map-pin-lightblue.png";
import { mapStyle } from "utils/general";

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "300px",
};

const Map = ({ position, saleType }) => (
  <GoogleMap
    mapContainerClassName="map-scheda"
    mapContainerStyle={containerStyle}
    center={position}
    options={{
      styles: mapStyle,
    }}
    zoom={14}
  >
    <Marker
      position={position}
      icon={{
        url: saleType === "auction" ? OrangePin : BluePin,
        scaledSize: {
          width: 50,
          height: 50,
        },
      }}
    />
  </GoogleMap>
);

export default Map;
