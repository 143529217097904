import "./PaymentFeedback.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { XCircle } from "phosphor-react";
import { loginUser } from "reducers/user";
import { useEffect } from "react";

const PaymentFailure = () => {
  const dispatch = useDispatch();

  useEffect(() => dispatch(loginUser()), [dispatch]);

  return (
    <div className="payment-feedback section">
      <XCircle className="failed" />

      <h1 className="general failure" style={{ textAlign: "center" }}>
        Errore di pagamento
      </h1>

      <p style={{ textAlign: "center" }}>
        É avvenuto un errore durante il pagamento. Ti chiediamo gentilmente di
        riprovare o di contattarci.
      </p>

      <Link to="/" className="btn orange">
        TORNA ALLA HOME
      </Link>
    </div>
  );
};

export default PaymentFailure;
