import "./AppSection.scss";
import AppIllustration1 from "assets/scene/app-immobile.png";
import { AppleLogo, GooglePlayLogo } from "phosphor-react";
import AppStore from "assets/app-store.svg";
import GooglePlay from "assets/google-play.svg";
import { QRCodeSVG } from "qrcode.react";
import { useMediaQuery } from "react-responsive";

const AppSection = () => {
  const isDesktop = useMediaQuery({ minWidth: 1096 });
  return (
    <div className="home-page__app-section section container pad-responsive">
      <div className="app-description">
        <h2 className="general">
          Con l'App di astaéxpert è ancora tutto più facile.
        </h2>

        <p>
          Scarica l'app astaéxpert, avrai accesso al più completo database di
          immobili all'asta e potrai monitorare le tue trattative, le visite
          richieste e non perdere neanche un'occasione. Ricevi notifiche push
          quando i tuoi immobili preferiti vanno in asta e godi di un canale
          privilegiato di comunicazione con i tuoi consulenti dedicati.
        </p>

        <div className="app-description__download">
          <LinkQr
            url="https://apps.apple.com/us/app/asta%C3%A9xpert/id1587909501"
            image={AppStore}
            altImage="Scarica su App Store"
            isMobile={!isDesktop}
          />

          <LinkQr
            url="https://play.google.com/store/apps/details?id=com.astaexpert.app"
            image={GooglePlay}
            altImage="Disponibile su Google Play"
            isMobile={!isDesktop}
          />
        </div>
      </div>

      <div className="app-illustration">
        <img src={AppIllustration1} alt="" />
      </div>
    </div>
  );
};

export default AppSection;

const LinkQr = ({ isMobile, url, image, altImage }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    style={
      !isMobile
        ? {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
            padding: 16,
            borderRadius: 16,
            gap: 16,
          }
        : {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }
    }
  >
    <img style={{ width: 160, height: 48 }} alt={altImage} src={image} />
    {!isMobile && <QRCodeSVG size={140} level="L" includeMargin value={url} />}
  </a>
);
