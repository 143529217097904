import { Fade } from "react-reveal";
import "./Introduction.scss";

const Introduction = () => (
  <div className="why-asta__introduction container section standard pad-responsive">
    <div className="description">
      <h2 className="general">
        astaéxpert offre un servizio completo e senza pensieri.
      </h2>

      <p>
        Scarica l'app e registrati, con solo 9.99€ al mese senza vincoli di
        durata, avrai accesso alla piattaforma astaéxpert. Potrai ricercare in
        autonomia su tutto il mercato l'immobile che desideri: sarà nostra cura
        analizzare l'affare da te selezionato, accompagnandoti passo dopo passo,
        dalla visita dell'immobile fino alla definizione di tutti i documenti
        legali per arrivare alla partecipazione il giorno dell'asta.
      </p>
    </div>

    <Fade right>
      <div className="img"></div>
    </Fade>
  </div>
);

export default Introduction;
