import "./Payment.scss";
import Form from "components/form/Form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "reducers/user";

const Payment = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const user = useSelector(({ user }) => user.data.user);

  const isDisabled = () => {
    const inputStatus = inputs.map(
      (input) => !!form[input.target] && form[input.target] !== ""
    );
    const inputAreValid = inputStatus.some((input) => !!input);
    return !inputAreValid;
  };

  const handleDataSubmit = async () => {
    try {
      const {
        taxCode,
        address,
        city,
        zipcode,
        province,
        country,
        phone,
        vatCode,
        sdiCode,
      } = form;
      await dispatch(
        updateUser({
          taxCode,
          address,
          city,
          zipcode,
          province,
          country,
          phone,
          vatCode,
          sdiCode,
        })
      );
      setForm({
        taxCode: "",
        address: "",
        city: "",
        zipcode: "",
        province: "",
        country: "",
        phone: "",
        vatCode: "",
        sdiCode: "",
      });
    } catch (err) {
      console.log(err);
      console.log(err.response);
      console.log(err.message);
    }
  };

  const changeDataButton = [
    {
      text: "Modifica Dati",
      handleClick: handleDataSubmit,
    },
  ];

  const inputs = [
    {
      target: "taxCode",
      placeholder: user.taxCode
        ? `Codice fiscale - ${user.taxCode}`
        : "Codice fiscale",
      type: "text",
      value: form.taxCode,
    },
    {
      target: "address",
      placeholder: user.address ? `Indirizzo - ${user.address}` : "Indirizzo",
      type: "text",
      value: form.address,
    },
    {
      target: "city",
      placeholder: user.city ? `Città - ${user.city}` : "Città",
      type: "text",
      value: form.city,
    },
    {
      target: "zipcode",
      placeholder: user.city ? `CAP - ${user.zipcode}` : "CAP",
      type: "text",
      value: form.zipcode,
    },
    {
      target: "province",
      placeholder: user.province ? `Provincia - ${user.province}` : "Provincia",
      type: "text",
      value: form.province,
    },
    {
      target: "country",
      placeholder: user.country ? `Paese - ${user.province}` : "Paese",
      type: "text",
      value: form.country,
    },
    {
      target: "phone",
      placeholder: user.phone ? `Telefono - ${user.phone}` : "Telefono",
      type: "phone",
      value: form.phone,
    },
    {
      target: "vatCode",
      placeholder: user.vatCode
        ? `Partita Iva - ${user.vatCode}`
        : "Partita Iva",
      type: "vatCode",
      value: form.vatCode,
    },
    {
      target: "sdiCode",
      placeholder: user.sdiCode ? `Codice SDI - ${user.sdiCode}` : "Codice SDI",
      type: "sdiCode",
      value: form.sdiCode,
    },
  ];

  const handleChangeInput = (target, value) =>
    setForm({ ...form, [target]: value });

  return (
    <Form
      title={"Dati fatturazione"}
      inputs={inputs}
      handleChangeInput={handleChangeInput}
      buttons={changeDataButton}
      disabled={isDisabled}
    />
  );
};

export default Payment;
