import { Fragment } from "react";
import { Fade } from "react-reveal";
import "./Services.scss";

const Services = ({ services }) => (
  <Fade distance="1%">
    <div className="why-asta-destinations__services section short">
      <h2 className="general">Come funziona?</h2>

      <div className="servizi-list">
        {services.map((element, i) => (
          <Fragment key={i}>
            {i % 2 === 0 ? (
              <div className="section standard">
                <div className="container service pad-responsive">
                  <img src={element.img} alt="" />
                  <div className="number">0{i + 1}</div>
                  <div className="description">
                    <h3>{element.title}</h3>
                    <p>{element.text}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="section bg-white standard pad-responsive">
                <div className="container service">
                  <div className="number">0{i + 1}</div>
                  <div className="description">
                    <h3>{element.title}</h3>
                    <p>{element.text}</p>
                  </div>
                  <img src={element.img} alt="" />
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  </Fade>
);

export default Services;
