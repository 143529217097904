import { Heart, MapPin } from "phosphor-react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { capitalize } from "utils/general";
import priceFormatter from "utils/priceFormatter";
import "./HeadingBottom.scss";

const HeadingBottom = ({ singleRealEstate, handleFavorite }) => {
  const favorites = useSelector(({ favorites }) => favorites.data);
  // const user =
  //   useSelector(({ user }) => user?.data?.user?.role) === "paying_user";
  const isDesktop = useMediaQuery({ minWidth: 678 });

  return (
    <div className="info-real-estate-page__heading-bottom">
      <div className="info-real-estate-page__left">
        <h1 className="general">
          <span>{singleRealEstate.title}</span>

          {!isDesktop && (
            <Heart
              className={`heart-icon ${
                singleRealEstate &&
                favorites.find(({ id }) => id === singleRealEstate.id)
                  ? "active"
                  : ""
              }`}
              onClick={handleFavorite}
            />
          )}
        </h1>

        <div className="address">
          <MapPin weight="bold" />

          <p>
            {!singleRealEstate.type
              ? capitalize(singleRealEstate.fullAddress)
              : singleRealEstate.city &&
                capitalize(
                  `${singleRealEstate.city}, ${singleRealEstate.address}`
                )}
          </p>
        </div>
      </div>

      <div className="info-real-estate-page__right">
        <div className="add-favorite" title="Aggiungi alla wishlist">
          {isDesktop && (
            <Heart
              className={`heart-icon ${
                singleRealEstate &&
                favorites.find(({ id }) => id === singleRealEstate.id)
                  ? "active"
                  : ""
              }`}
              onClick={handleFavorite}
            />
          )}
        </div>

        <div className="price">
          {singleRealEstate.auction && (
            <div className="price__starting-price">
              Prezzo{" "}
              <span>
                €{" "}
                <span>
                  {singleRealEstate.auction &&
                    priceFormatter(singleRealEstate.auction.startingPrice)}
                </span>
              </span>
            </div>
          )}

          {(!!singleRealEstate.auction?.minimumBidPrice ||
            !!singleRealEstate.price) && (
            <div className="price__minimum-bid-price">
              {singleRealEstate.auction?.startingPrice && "Offerta minima "}
              <span>
                €{" "}
                {priceFormatter(
                  singleRealEstate.auction?.minimumBidPrice
                    ? singleRealEstate.auction?.minimumBidPrice
                    : singleRealEstate.price
                )}
              </span>
            </div>
          )}

          {!!singleRealEstate.auction &&
            !!singleRealEstate.auction.minimumBidIncrement && (
              <div className="price__minimum-bid-increment">
                Rialzo minimo{" "}
                <span>
                  €{" "}
                  {priceFormatter(singleRealEstate.auction.minimumBidIncrement)}
                </span>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default HeadingBottom;
