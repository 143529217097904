import "./GetStarted.scss";
import PricePlans from "components/price-plans/PricePlans";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const GetStarted = () => {
  const user = useSelector(({ user }) => user?.data.user);
  return (
    <div className="get-stated-page bg-section">
      <div className="get-stated-page__wrapper container sub-navbar pad-responsive">
        <h1 className="big">Inizia da qui</h1>

        {!user && (
          <div className="already-logged">
            Già registrato? <Link to="/accedi">Accedi</Link>
          </div>
        )}

        {/* <div className="selection">
          <span className="category">Seleziona</span> il pacchetto adatto a te
        </div> */}

        <PricePlans target="user" />
      </div>
    </div>
  );
};

export default GetStarted;
