import { combineReducers } from "redux";

import user from "./user";
import users from "./users";
import realEstate from "./real-estate";
import cart from "./cart";
import favorites from "./favorites";
import researches from "./researches";
import visits from "./visits";
import participations from "./participations";
import viewResearch from "./view-research";
import cities from "./cities";
import researchParams from "./researchParams";
import subscriptions from "./subscriptions";

export default combineReducers({
  user,
  realEstate,
  cart,
  favorites,
  researches,
  visits,
  participations,
  viewResearch,
  cities,
  researchParams,
  subscriptions,
  users,
});
