import "./Values.scss";
import { values } from "./items";
import { Fade } from "react-reveal";

const Values = () => (
  <Fade>
    <div className="about-us-page__values">
      <div className="container">
        <div className="section only-bottom pad-responsive">
          <div className="title">
            <h2 className="general">Il DNA di astaéxpert</h2>
          </div>

          <div className="values-list section">
            {values.map((element, i) => (
              <div className="value" key={i}>
                <img src={element.icon} alt="" />
                <h4>{element.title}</h4>
                <p>{element.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Fade>
);

export default Values;
