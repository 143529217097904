import { Fade } from "react-reveal";
import "./How.scss";

const How = () => (
  <Fade>
    <div className="for-customers-page__how bg-white standard pad-responsive section">
      <div className="for-customers-page__how__wrapper container">
        <div className="description">
          <h2 className="general">Come funziona un'asta?</h2>

          <p>
            Scelto l’immobile, e ottenuto il via libera a procedere
            dall’avvocato astaéxpert, il cliente può presentare la domanda di
            partecipazione all’asta.
          </p>
        </div>

        <table>
          <tbody>
            <tr>
              <td>0 OFFERTE</td>
              <td>
                L’asta va deserta e viene rimandata di circa 2 mesi con ribasso
                ulteriore 25%
              </td>
            </tr>
            <tr>
              <td>SOLO 1 OFFERTA</td>
              <td>
                Con un solo offerente l’asta viene vinta al prezzo base d’asta!
              </td>
            </tr>
            <tr>
              <td>2 O + OFFERTE</td>
              <td>
                Con 2 e + offerte inizia l’asta al rialzo con notaio come
                garante, banditore e timer di 60 secondi per rilanci
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Fade>
);

export default How;
