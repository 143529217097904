import Form from "components/form/Form";
import "./FormContact.scss";
import { CheckCircle } from "phosphor-react";
// import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import requests from "api";
import axios from "axios";
// import { useSelector } from "react-redux";

const inputs = [
  { target: "firstname", placeholder: "Nome", type: "text" },
  { target: "lastname", placeholder: "Cognome", type: "text" },
  { target: "email", placeholder: "Email", type: "email" },
  { target: "phone", placeholder: "Numero di telefono", type: "tel" },
];

const checkboxes = [
  {
    id: "privacy",
    target: "hasAcceptedPrivacyPolicy",
    label: "Accetto il trattamento dei dati sulla privacy",
    required: true,
  },
  {
    id: "marketing",
    target: "hasAcceptedMarketing",
    label: "Accetto il trattamento dei dati per finalità di marketing",
  },
];

const textareas = [
  {
    id: "message",
    placeholder: "Messaggio",
    target: "text",
  },
];

const FormContact = () => {
  // const authToken = useSelector(({ user }) => user?.data?.authToken);6
  const [form, setForm] = useState({ hasAcceptedMarketing: false });
  const [feedback, setFeedback] = useState(false);
  const [ip, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  const handleChangeInput = (target, value) => {
    setForm({ ...form, [target]: value });
  };

  const isDisabled = () => {
    const fullInputs = [...inputs, ...textareas];
    const inputStatus = fullInputs.map(
      (input) => !!form[input.target] && form[input.target] !== ""
    );
    const inputAreValid = inputStatus.every((input) => !!input);
    const checkboxesAreValid = form.hasAcceptedPrivacyPolicy;
    return !inputAreValid || !checkboxesAreValid;
  };

  const createIubendaContact = async (formData) => {
    const formattedData = {
      subject: {
        id: "formSubmit",
        email: formData?.email,
        first_name: formData?.firstname,
        last_name: formData?.lastname,
        verified: false,
      },
      legal_notices: [
        {
          identifier: "privacy_policy",
          version: 123,
        },
        {
          identifier: "term",
          version: 123,
        },
      ],
      proofs: [
        {
          content: "contact-form",
          form: '<div class="form-contact-component"><div class="form-component "><input type="text" placeholder="Nome" value="" style="border: none;"><input type="text" placeholder="Cognome" value="" style="border: none;"><input type="email" placeholder="Email" value="" style="border: none;"><input type="tel" placeholder="Numero di telefono" value="" style="border: none;"><textarea id="message" cols="30" rows="10" placeholder="Messaggio"></textarea><div class="form-component__checkbox"><input type="checkbox" id="checkbox_0"><label for="checkbox_0">Accetto il trattamento dei dati sulla privacy</label></div><div class="form-component__checkbox"><input type="checkbox" id="checkbox_1"><label for="checkbox_1">Accetto il trattamento dei dati per finalità di marketing</label></div><button class="btn orange center" disabled="">Invia Richiesta</button></div></div>',
        },
      ],

      preferences: {
        privacy_policy: true,
        newsletter: formData?.hasAcceptedMarketing,
      },
      ip_address: ip,
    };
    try {
      const response = await axios({
        url: `https://consent.iubenda.com/beta/consent`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ApiKey: "49WhUrsgwtAgWOwSXyKWNPwTmjJeVBZ1",
        },
        data: formattedData,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.message);
      console.log(error.response);
      return null;
    }
  };

  const handleSubmit = async () => {
    try {
      await createIubendaContact(form);
      const res = await requests.createContact(form);
      if (res) {
        setForm({});
        setFeedback(true);
      }
    } catch (err) {
      console.log("error sending form", err);
    }
  };

  const _handleClose = () => {
    setFeedback(false);
    setForm({ ...form, firstname: "" });
  };

  const buttons = [
    {
      text: "Invia Richiesta",
      handleClick: handleSubmit,
    },
  ];

  return (
    <div className="form-contact-component">
      {feedback && (
        <div className="overlay-message">
          <div className="text">
            <div className="feedback">
              <CheckCircle weight="bold" />
              <span>Messaggio inviato</span>
            </div>
            {/* <Link to="/">Chiudi</Link> */}
            <div
              className="btn orange"
              style={{ marginTop: 16 }}
              onClick={() => _handleClose()}
            >
              Chiudi
            </div>
          </div>
        </div>
      )}

      <Form
        feedback={feedback}
        textareas={textareas}
        inputs={inputs}
        checkboxes={checkboxes}
        buttons={buttons}
        handleChangeInput={handleChangeInput}
        disabled={isDisabled}
      />
    </div>
  );
};

export default FormContact;
