import "./Services.scss";
import BannerPremium from "components/banner-premium/BannerPremium";
import Hero from "./hero/Hero";
import Procedure from "./procedure/Procedure";
import ServicesSection from "./services-section/ServicesSection";
import LegalList from "./legal-list/LegalList";
import Footer from "./footer/Footer";
import Publicity from "./publicity/Publicity";
import { Fade } from "react-reveal";
import { useSelector } from "react-redux";

const Services = () => {
  const userRole =
    useSelector(({ user }) => user?.data?.user?.role) !== "paying_user";

  return (
    // <Fade>
    <div className="services-page">
      <Hero />

      <ServicesSection />

      <LegalList />

      <Footer />

      <Publicity />

      <Procedure />

      {userRole && <BannerPremium />}
    </div>
    // </Fade>
  );
};

export default Services;
