import moment from "moment";
import { Clock } from "phosphor-react";
import "./IntroSpecs.scss";

const voices = [
  { name: "Tipo di vendita", key: "saleType" },
  { name: "Modalità di vendita", key: "saleLocation" },
  { name: "Termine presentazione offerte", key: "offerExpireDate" },
];

const IntroSpecs = (props) => (
  <div className="info-real-estate__intro-specs">
    <h3>Dati di vendita</h3>

    <div className="voices">
      {props.singleRealEstate.saleType === "auction" && (
        <div className="voice">
          <h6>Data e ora di vendita</h6>
          <div className="date">
            <Clock weight="bold" />
            <span>
              {props.singleRealEstate.auction &&
                moment(props.singleRealEstate.auction.date).format("L")}
            </span>
          </div>
        </div>
      )}

      {voices.map((element, i) => (
        <div key={i} className="voice">
          <h6>{element.name}</h6>
          <div style={{ textTransform: "capitalize" }}>
            {formatName(props.singleRealEstate[element.key]) || "N/D"}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default IntroSpecs;

const formatName = (name) => {
  switch (name) {
    case "auction":
      return "all'asta";
    case "free":
      return "Non in asta";
    default:
      return name;
  }
};
