import { API_ROOT_URL, getHeaders } from "./index";

const api = {
  getOurTeamUsers: () => {
    console.log("url", `${API_ROOT_URL}/users`);
    return {
      url: `${API_ROOT_URL}/users`,
      method: "GET",
      headers: getHeaders(),
    };
  },
};

export default api;
