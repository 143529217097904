import "./Error.scss";
import { Link } from "react-router-dom";

const Error = () => (
  <div className="error-page pad-responsive">
    <p className="text">Non troviamo la pagina che hai richiesto.</p>

    <Link to="/">Torna alla Homepage</Link>
  </div>
);

export default Error;
