import "./CardRealEstateExtended.scss";
import moment from "moment";
import { Clock } from "phosphor-react";
import { Link } from "react-router-dom";
import CarouselExtended from "./carousel-extended/CarouselExtended";
import priceFormatter from "utils/priceFormatter";
import { capitalize } from "utils/general";

const CardRealEstateExtended = ({ element, index }) => {
  const daysToAuction =
    element?.auction &&
    moment(element?.auction.date).diff(moment(), "days") + 1;

  return (
    <div className="card-real-estate-extended-component">
      <CarouselExtended
        saleType={element.saleType}
        gallery={element.gallery}
        lat={element.lat}
        lon={element.lon}
        auction={element.auction}
        daysToAuction={daysToAuction}
      />

      <div className="description">
        <p className="address">
          {element.saleType === "free" ? element.title : element.auction?.lotto}
        </p>

        <div className="heading-bottom">
          <h4>
            {!element.type
              ? capitalize(element.fullAddress)
              : element.city &&
                capitalize(`${element.city}, ${element.address}`)}
          </h4>

          <div className="numbers">
            <span>
              {element.auction?.startingPrice && <span>da </span>}
              <span className="price">
                €{" "}
                {priceFormatter(
                  element.auction?.minimumBidPrice ||
                    element.auction?.startingPrice ||
                    element.price
                )}
              </span>
            </span>

            {element.squarefeet && element.squarefeet !== 0 && (
              <>
                <div className="line"></div>

                <span>
                  <span className="m2">{element.squarefeet}</span>{" "}
                  <span>
                    m<sup>2</sup>
                  </span>
                </span>
              </>
            )}
          </div>
        </div>

        <p className="text">{element.description}</p>

        <div className="bottom">
          <div className="bottom__left">
            {element.saleType === "free" ? (
              <span className="date">Non in asta</span>
            ) : (
              <span className="date">
                <Clock weight="bold" />
                <span>{moment(element.auction.date).format("L")}</span>
              </span>
            )}

            <p
              style={{
                visibility: element.saleType === "free" && "hidden",
              }}
            >
              <span className="semi-bold">{element.courthouse?.name}:</span>{" "}
              {element.courthouse?.code}
            </p>
          </div>

          <Link
            to={{
              pathname: "/immobile/" + element.id,
              state: {
                from: true,
                index,
              },
            }}
            className="btn orange"
          >
            Dettaglio
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardRealEstateExtended;
