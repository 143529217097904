import { CheckCircle, XCircle } from "phosphor-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  getAllParticipations,
  requestPartecipation,
} from "reducers/participations";
import "./JoinAuction.scss";

const JoinAuction = ({ realEstateId, setIsComponentVisible, refp }) => {
  const user =
    useSelector(({ user }) => user?.data?.user?.role) === "paying_user";
  const history = useHistory();
  const participations = useSelector(
    ({ participations }) => participations.data
  );
  const checkAlreadyJoin = participations.some(
    ({ auction }) => auction.id === realEstateId
  );

  const handleClick = () =>
    user
      ? setIsComponentVisible(
          <JoinAuctionOverlay
            refp={refp}
            setIsComponentVisible={setIsComponentVisible}
            realEstateId={realEstateId}
          />
        )
      : history.push("/inizia");

  return (
    <button
      onClick={handleClick}
      className={`join-auction-button btn orange full-width  ${
        checkAlreadyJoin ? "done" : ""
      }`}
    >
      {checkAlreadyJoin
        ? "richiesta di partecipazione inviata"
        : "richiedi di partecipare all'asta"}
    </button>
  );
};

export default JoinAuction;

const JoinAuctionOverlay = ({ realEstateId, setIsComponentVisible, refp }) => {
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState(false);

  const requestToJoin = async () => {
    await dispatch(requestPartecipation(realEstateId));
    dispatch(getAllParticipations());
    setFeedback(true);
  };

  const closeWindow = () => {
    setIsComponentVisible(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <div className="overlay__join-auction" ref={refp}>
      {feedback ? (
        <>
          <div className="feedback">Richiesta di partecipazione inviata</div>
          <div onClick={closeWindow} className="close">
            Chiudi
          </div>
        </>
      ) : (
        <>
          <div className="question">
            Sei sicuro di voler partecipare all'asta
          </div>
          <div className="controls-alert">
            <CheckCircle className="confirm" onClick={requestToJoin} />
            <XCircle className="undo" onClick={closeWindow} />
          </div>
        </>
      )}
    </div>
  );
};
