import "./BlogList.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import requests from "api";

const BlogList = ({ related, id, standard }) => {
  const [list, setList] = useState([]);
  const [to, setTo] = useState(9);

  useEffect(() => {
    const getBlogList = async () => {
      const response = await requests.getBlogList();
      setList(response || []);
    };
    getBlogList();
  }, []);

  return (
    <div
      className={`blog-list-component section bg-grey ${
        standard ? "standard" : ""
      } `}
    >
      <div className="container">
        <div className="pad-responsive">
          {related && <h2 className="general">Articoli correlati</h2>}
        </div>
        <div className="article-list pad-responsive">
          {list
            .filter((item) => (related ? item.id !== +id : item))
            .filter((element) => (!related ? !element.isChosen : element))
            .slice(0, related ? 3 : to)
            .map((element, i) => (
              <div className="article" key={i}>
                <Link to={"/blog/" + element.id + generateUrl(element.title)}>
                  <img src={element.image} alt="" />
                </Link>

                <div className="heading-article">
                  <span className="category">Blog</span>
                  <span className="date">
                    {moment(element.createdAt).format("L")}
                  </span>
                </div>

                <Link to={"/blog/" + element.id + generateUrl(element.title)}>
                  <h4>{element.title}</h4>
                </Link>
              </div>
            ))}
        </div>

        {!related && list.length > 9 && (
          <div className="container show-more">
            <button className="btn margin empty" onClick={() => setTo(to + 9)}>
              Mostra di più
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const generateUrl = (string) => {
  if (!string) return "";
  string = cleanString(string);
  return string;
};

const cleanString = (string) => {
  const toRemove = ["%", "'", '"', "*", "_", "/"];
  string = toRemove.reduce((acc, curr) => {
    return acc.split(curr).join("");
  }, string);
  return ("-" + string.split(" ").join("-")).toLowerCase();
};

export default BlogList;
