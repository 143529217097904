import { Fade } from "react-reveal";
import "./Hero.scss";

const Hero = () => (
  <Fade>
    <div className="services-page__hero container section">
      <h1 className="big">Servizi</h1>

      <div className="illustration">
        <div className="illustration-left"></div>

        <div className="illustration-right"></div>
      </div>
    </div>
  </Fade>
);

export default Hero;
