import "./Footer.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Astaexpert } from "assets/logo/astaexpert.svg";
import { ReactComponent as Bespoken } from "assets/logo/bespoken.svg";
import { menu, social } from "./items";

const Footer = () => (
  <div className="footer">
    <div className="container-fluid footer-container">
      <div className=" footer__top">
        <div className="footer__logo">
          <Astaexpert />
        </div>

        <div className="footer__menu">
          {menu.map(({ title, voices }, i) => (
            <div key={i} className="menu">
              <h6>{title}</h6>
              {voices.map(({ to, title, pathname, state, externalLink }, i) => {
                if (externalLink)
                  return (
                    <a key={i} href={to} target="_blank" rel="noreferrer">
                      {title}
                    </a>
                  );
                else if (pathname)
                  return (
                    <Link
                      key={i}
                      to={{ pathname: pathname, state: { title: state } }}
                      className="white"
                    >
                      {title}
                    </Link>
                  );
                else
                  return (
                    <Link key={i} to={to} className="white">
                      {title}
                    </Link>
                  );
              })}
            </div>
          ))}
        </div>

        <div className="social-list">
          {social.map(({ href, icon }, i) => (
            <a key={i} href={href} target="_blank" rel="noreferrer">
              {icon}
            </a>
          ))}
        </div>
      </div>

      <div className=" footer__bottom">
        <div className="footer-bottom__left">
          <p style={{ lineHeight: "0.8rem" }}>
            Astaexpert S.R.L. è iscritta al Registro degli Operatori di
            Comunicazione nr. 37924 e{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://www.giustizia.it/giustizia/it/mg_1_8_1.page?contentId=SDC409828&previsiousPage=mg_1_18"
            >
              autorizzata dal Ministero della Giustizia.
            </a>
          </p>
          <p style={{ lineHeight: "0.8rem" }}>
            astaéxpert è un marchio di proprietà di Astaexpert S.R.L. - P.iva
            04586470165
          </p>
        </div>

        <div className="footer-bottom__right">
          <a href="https://www.bspkn.it" target="_blank" rel="noreferrer">
            <Bespoken />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
