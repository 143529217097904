import { Fade } from "react-reveal";
import "./Hero.scss";

const Hero = () => (
  <Fade>
    <div className="about-us-page__hero container-fluid section pad-responsive">
      <h1 className="big">Chi siamo</h1>

      <p>
        astaéxpert ha un solo obiettivo, quello di aiutarti a trovare l'immobile
        giusto al miglior prezzo. Dalla registrazione e contestuale apertura
        della pratica di ricerca immobile, alla selezione del giusto immobile,
        alla definizione dei vantaggi economici dell’operazione, fino
        all’aggiudicazione dell’asta. Comprare casa tramite asta immobiliare non
        è mai stato così semplice.
      </p>
    </div>
  </Fade>
);

export default Hero;
