import "./CardRealEstate.scss";
import { Link } from "react-router-dom";
import { Clock } from "phosphor-react";
import moment from "moment";
import Carousel from "./carousel/Carousel";
import priceFormatter from "utils/priceFormatter";
import { capitalize } from "utils/general";

const sliceString = (string) => {
  if (string.length > 45) {
    return string.slice(0, 45).concat("...");
  } else return string;
};

const CardRealEstate = ({ element, index }) => {
  const auctionDate = moment(element?.auction?.date);
  const daysToAuction = auctionDate.diff(moment(), "days") + 1;

  return (
    <div className="card-real-estate-component">
      <div className="card-carousel">
        <Carousel
          saleType={element.saleType}
          gallery={element.gallery}
          lat={element.lat}
          lon={element.lon}
        />
      </div>

      <div className="description">
        <p className="address">
          {element.saleType === "free"
            ? sliceString(element.title)
            : element.auction?.lotto.toLowerCase()}
        </p>

        <Link
          to={{
            pathname: `/immobile/${element.id}/${element.title}`,
            state: {
              from: true,
              index,
            },
          }}
        >
          <h4>
            {element.fullAddress
              ? capitalize(element.fullAddress)
              : "Indirizzo non disponibile"}
          </h4>
        </Link>

        <div className="numbers">
          {element.auction?.minimumBidPrice > 0 ||
          element.auction?.startingPrice > 0 ||
          element.price > 0 ? (
            <span>
              {element.auction?.startingPrice && <span>da </span>}
              <span className="price">
                €{" "}
                {priceFormatter(
                  element.auction?.minimumBidPrice
                    ? element.auction?.minimumBidPrice ||
                        element.auction?.startingPrice
                    : element.price
                )}
              </span>
            </span>
          ) : (
            <span className="price">Prezzo non disponibile</span>
          )}

          {!!element.squarefeet && element.squarefeet != 0 && (
            <>
              <div className="line"></div>
              <span>
                <span className="m2">{element.squarefeet}</span>{" "}
                <span>
                  m<sup>2</sup>
                </span>
              </span>
            </>
          )}
        </div>

        <p
          style={{
            visibility: element.saleType === "free" && "hidden",
          }}
        >
          <span className="semi-bold">{element.courthouse?.name}:</span>{" "}
          {element.courthouse?.code}
        </p>
      </div>

      <div className="bottom">
        {element.saleType === "free" ? (
          <span>Non in asta</span>
        ) : (
          <span>
            <Clock />
            <span>{moment(element.auction.date).format("L")}</span>
          </span>
        )}

        {element.auction && daysToAuction > 0 && (
          <div className="auction-date">
            <span>
              {`In
              ${daysToAuction} ${daysToAuction > 1 ? "giorni" : "giorno"}`}
            </span>
            <div
              className="dot"
              style={{
                background: renderColor(daysToAuction),
                color: renderColor(daysToAuction),
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardRealEstate;

const renderColor = (days) => {
  if (days > 30) {
    return "limegreen";
  } else if (days > 15) {
    return "gold";
  } else if (days > 5) {
    return "orangered";
  } else if (days > 0) {
    return "red";
  }
};
