export const splitNumbers = (array) => {
  const odd = [];
  const even = [];

  array.forEach((element, i) => {
    i % 2 === 0 ? odd.push(element) : even.push(element);
  });

  return [odd, even];
};
