import { useState } from "react";
import { CaretRight, CaretLeft, Camera } from "phosphor-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { CornersOut } from "phosphor-react";
import apartmentPlaceholder from "assets/scene/casa.svg";
import "./Carousel.scss";
import CarouselFull from "./carousel-full/CarouselFull";

const Carousel = ({ gallery, saleType, virtualTour }) => {
  const [sliderFull, setSliderFull] = useState(false);

  const zoom = () => {
    setSliderFull(!sliderFull);
    document.body.classList.toggle("no-scroll");
  };

  return (
    <div className="info-real-estate__slider-immobile">
      {sliderFull && <CarouselFull gallery={gallery} zoom={zoom} />}

      {saleType && <div className="starting-price__discount">-25%</div>}

      <div className="controls">
        {gallery?.length !== 0 && (
          <div className="wrapper-icon" title="Fullscreen">
            <CornersOut className="in" onClick={zoom} />
          </div>
        )}
        {virtualTour && (
          <a
            className="wrapper-icon"
            href={virtualTour}
            target="_blank"
            rel="noreferrer"
            title="Virtual Tour"
          >
            <Camera />
          </a>
        )}
      </div>

      <Swiper
        navigation={{
          prevEl: ".swiper-immobile-prev",
          nextEl: ".swiper-immobile-next",
        }}
      >
        {gallery?.length > 0 ? (
          gallery.map((element, i) => (
            <SwiperSlide style={{ backgroundColor: "white" }} key={i}>
              <img
                src={element}
                alt=""
                style={{
                  maxHeight: "50vh",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <div
              className="apartment-placeholder"
              style={{
                background: saleType
                  ? "var(--colorOrange)"
                  : "var(--colorLightBlue)",
              }}
            >
              <img src={apartmentPlaceholder} alt="" />
            </div>
          </SwiperSlide>
        )}

        <CaretLeft className="swiper-immobile-prev" weight="bold" />
        <CaretRight className="swiper-immobile-next" weight="bold" />
      </Swiper>
    </div>
  );
};

export default Carousel;
