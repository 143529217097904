import requests from "api";
import { InputSelect } from "components/inputs/input-select/InputSelect";
import { FileArrowDown } from "phosphor-react";
import { useEffect, useState } from "react";
import "./Forms.scss";

const initialCourthouse = { label: "Seleziona il tribunale", value: "" };

const Forms = () => {
  const [courthouseList, setCourthouseList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [courthouse, setCourthouse] = useState(initialCourthouse);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const getCourthouses = async () => {
      const response = await requests.getCourthouses();
      setCourthouseList(response);
    };
    getCourthouses();
  }, []);

  useEffect(() => {
    handleSubmit();
  }, [courthouse]);

  const onChangeCourthouse = (value) => {
    setSearch(value.label);
    const selectedCourthouse = courthouseList.find(
      (e) => e.name === value.label
    );
    if (!!selectedCourthouse) {
      setCourthouse({ label: value.label, value: value.value });
    }
  };

  const handleSubmit = async () => {
    try {
      if (courthouse.value !== "") {
        const response = await requests.getForms(courthouse.value);
        setDocumentList(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onReset = () => {
    setCourthouse({ label: "", value: "" });
  };

  return (
    <div className="forms-page bg-pink">
      <div className="intro bg-section pad-responsive">
        <div className="container section sub-navbar">
          <h1 className="big">Modulistica</h1>

          <div className="select-container">
            <InputSelect
              simple
              onReset={onReset}
              value={{ label: search }}
              onChange={onChangeCourthouse}
              placeholder={initialCourthouse.label}
              options={
                courthouseList &&
                courthouseList
                  .filter((element) => !!element.name)
                  .map((element) => ({
                    value: element.id,
                    label: element.name,
                  }))
                  .filter((element) =>
                    element.label.toLowerCase().includes(search.toLowerCase())
                  )
              }
            />
          </div>
        </div>
      </div>

      {courthouse.value !== "" &&
        courthouse.label !== "Seleziona il tribunale" && (
          <div className="section bg-grey standard pad-responsive">
            <div className="moduli container">
              <h2 className="general" style={{ textAlign: "center" }}>
                Moduli {courthouse.label}
              </h2>

              <div className="moduli-list">
                {documentList.map((element, i) => (
                  <a
                    href={element.file}
                    target="_blank"
                    rel="noreferrer"
                    key={i}
                    className="btn between text-xl"
                    style={{ marginBottom: 16 }}
                  >
                    <span>{element.title}</span>
                    <FileArrowDown />
                  </a>
                ))}
                {documentList.length === 0 && (
                  <div style={{ textAlign: "center" }}>
                    Non ci sono ancora moduli per questo tribunale.
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default Forms;
