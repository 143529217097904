import { Check } from "phosphor-react";
import "./InputCheck.scss";

export const InputCheck = (props) => {
  const handleChange = () => {
    if (props.state.value === "") {
      props.onChange(props.target, props.state, !props.state.value);
    } else {
      props.onChange(props.target, props.state, "");
    }
  };

  return (
    <div className="input-check-component" onClick={handleChange}>
      <div>{props.placeholder}</div>

      <Check className={props.state.value ? "active" : ""} weight="bold" />
    </div>
  );
};

export const InputCheck2 = ({ handleChange, placeholder, active }) => (
  <div className="input-check-component toggle-free" onClick={handleChange}>
    <div>{placeholder}</div>

    <Check className={active ? "active" : ""} weight="bold" />
  </div>
);
