import "./Calendar.scss";
import moment from "moment";
import { MagnifyingGlass } from "phosphor-react";
import { InputSelect } from "components/inputs/input-select/InputSelect";
import { formatCalendar, weekDays } from "./function";
import { CalendarArrow } from "components/navigation/NavigationArrow";
import { Link } from "react-router-dom";
import { paramsToString } from "utils/paramsToString";
import { useEffect, useState } from "react";
import requests from "api";

const initialCourthouse = { label: null, value: null };

const Calendar = () => {
  const [courthouseList, setCourthouseList] = useState([]);
  const [auctions, setAuctions] = useState([]);
  const [selectedTime, setSelectedTime] = useState(moment());
  const [courthouse, setCourthouse] = useState(initialCourthouse);
  const [search, setSearch] = useState("");

  const onChangeCourthouse = (value) => {
    setSearch(value.label);
    const selectedCourthouse = courthouseList.find(
      (e) => e.name === value.label
    );
    if (!!selectedCourthouse) {
      setCourthouse({ label: value.label, value: value.value });
    }
  };

  useEffect(() => {
    const getCourthouses = async () => {
      const response = await requests.getCourthouses();
      setCourthouseList(response);
    };
    getCourthouses();
  }, []);

  useEffect(() => {
    setAuctions([]);
    handleSearch();
  }, [courthouse, selectedTime]);

  // TODO - Ottimizzare salvando i mesi di cui si è già preso il dato per evitare di fare troppo richieste
  const handleSearch = async () => {
    if (courthouse.value !== null) {
      try {
        const dateRange = {
          "auction.dateMin": moment(selectedTime)
            .startOf("month")
            .toISOString(),
          "auction.dateMax": moment(selectedTime).endOf("month").toISOString(),
        };
        const params = {
          limit: 100,
          "auction.courthouseId": courthouse.value,
          ...dateRange,
        };
        const response = await requests.getCalendar(params);
        setAuctions(response.realEstates);
      } catch (err) {
        console.log(err.response);
        setAuctions([]);
      }
    }
  };

  const currentMonth = () => setSelectedTime(moment());

  const addMonth = () => setSelectedTime(moment(selectedTime).add(1, "months"));

  const subtractMonth = () =>
    setSelectedTime(moment(selectedTime).subtract(1, "months"));

  const isSelectedMonth = (date) => {
    return (
      moment(selectedTime).format("MMMM YYYY") ===
      moment(date).format("MMMM YYYY")
    );
  };
  const onReset = () => {
    setCourthouse({ label: "", value: "" });
  };

  const calendar = formatCalendar(selectedTime);

  return (
    <div className="calendar-page">
      <div className="container pad-responsive">
        <div className="calendario-header section">
          <h1 className="general">Calendario Aste</h1>

          <div className="select-container">
            <InputSelect
              simple
              value={{ label: search }}
              onChange={onChangeCourthouse}
              onReset={onReset}
              // target="courthouse"
              placeholder="Seleziona il Tribunale"
              options={
                courthouseList &&
                courthouseList
                  .filter((element) => !!element.name)
                  .map((element) => ({
                    value: element.id,
                    label: element.name,
                  }))
                  .filter((element) =>
                    element.label.toLowerCase().includes(search.toLowerCase())
                  )
              }
            />

            {/* Commento bottone come su "modulistica", la call parte attraverso useEffect STXPRT-15
            <button onClick={handleSearch} className="btn orange">
              <MagnifyingGlass weight="bold" />

              <span>TROVA</span>
            </button> */}
          </div>
        </div>

        <div className="calendario section only-bottom">
          <div className="month-section">
            <div className="month">
              {moment(selectedTime).format("MMMM YYYY")}
            </div>

            <div className="months-change">
              {!isSelectedMonth(moment()) && (
                <div onClick={currentMonth} className="current-month">
                  Mese Corrente
                </div>
              )}

              <CalendarArrow
                small={true}
                leftDisabled={
                  moment().format("MMMM YYYY") ===
                  moment(selectedTime).format("MMMM YYYY")
                }
                handleClickRight={addMonth}
                handleClickLeft={subtractMonth}
              />
            </div>
          </div>

          <div className="heading-calendario-aste">
            {weekDays.map((element, i) => (
              <div className="day" key={i}>
                {window.innerWidth > 768 ? element : element.slice(0, 1)}
              </div>
            ))}
          </div>

          <div className="blocks">
            {calendar.map((week) => {
              return week.map((day) => {
                const auctionsList = auctions.filter(
                  (auction) =>
                    moment(auction.auction.date).format("YYYY-MM-DD") ===
                    moment(day).format("YYYY-MM-DD")
                );
                return (
                  <div
                    key={`day_${day}`}
                    className={`block ${
                      isSelectedMonth(day) ? "" : "disabled"
                    }`}
                  >
                    <div className="day">{moment(day).format("DD")}</div>

                    {auctionsList.length > 0 && (
                      <Link
                        to={{
                          pathname: "/immobili",
                          search: paramsToString({
                            "auction.courthouseId": courthouse.value,
                            "auction.dateMin": moment(day).format("YYYY-MM-DD"),
                            "auction.dateMax": moment(day).format("YYYY-MM-DD"),
                          }),
                          state: true,
                        }}
                        className="btn orange"
                      >
                        {auctionsList.length}
                      </Link>
                    )}
                  </div>
                );
              });
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
