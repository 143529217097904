import "./Carousel.scss";
import {
  NavigationArrowLeft,
  NavigationArrowRight,
} from "components/navigation/NavigationArrow";
import { Swiper, SwiperSlide } from "swiper/react";

const Carousel = ({ images }) => (
  <Swiper
    className="blog-single__carousel"
    navigation={{
      prevEl: ".arrow-left",
      nextEl: ".arrow-right",
    }}
  >
    {images.map((element, i) => (
      <SwiperSlide key={i}>
        <img src={element} alt="" />
      </SwiperSlide>
    ))}

    {images.length > 1 && (
      <>
        <NavigationArrowLeft />
        <NavigationArrowRight />
      </>
    )}
  </Swiper>
);

export default Carousel;
