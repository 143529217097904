import Img1 from "assets/scene/2.svg";
import Img2 from "assets/scene/8.svg";

export const destinations = [
  {
    img: Img1,
    title: "Per i Clienti",
    text: `Tra oltre 30mila immobili all'asta e grazie al confronto con il mercato libero tramite il nostro portale potrai valutare tra le migliaia di opportunità quella migliore per te. `,
    to: "/perche-astaexpert/per-i-clienti",
  },
  {
    img: Img2,
    title: "Per gli Agenti",
    text: `Un portale unico di ricerca in costante aggiornamento che mette in contatto domanda e offerta e ti garantisce un territorio di lavoro in esclusiva. Confrontare le opportunità di mercato e trovare clienti interessati ad un acquisto in asta non è mai stato così semplice. `,
    to: "/perche-astaexpert/per-gli-agenti",
  },
];
