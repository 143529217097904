import MapDensity from "components/map-density/MapDensity";
import { Fade } from "react-reveal";
import "./Contacts.scss";

const Contacts = () => (
  <div className="why-asta-destinations__contacts container section only-bottom pad-responsive">
    <Fade>
      <MapDensity left />
    </Fade>
    <Fade>
      <div className="description">
        <h2 className="general">Con astaéxpert cresciamo insieme.</h2>

        <p>Entrando a far parte del network astaéxpert otterrai:</p>

        <ul>
          <li>N°1 licenza d’uso piattaforma software astaéxpert</li>
          <li>Assegnazione di un’area territoriale dedicata</li>
          <li>Segnalazione giornaliera di nuovi clienti attivi nell’area</li>
          <li>
            Possibilità di sfruttare quasi 30.000 immobili in asta sui propri
            clienti
          </li>
          <li>Formazione specifica sulle esecuzioni immobiliari</li>
          <li>Formazione sui processi di gestione astaéxpert</li>
          <li>Assegnazione studio legale di zona con avvocato dedicato</li>
          <li>Interessanti piani di sviluppo territoriale</li>
          <li>
            Possibilità di caricare su mappa astaéxpert i propri immobili cosi
            da poter offrire ai clienti la migliore soluzione disponibile sulla
            zona, anche la tua!
          </li>
        </ul>
      </div>
    </Fade>
  </div>
);

export default Contacts;
