import PricePlans from "components/price-plans/PricePlans";
import { Fade } from "react-reveal";
import "./App.scss";

const App = () => (
  <Fade>
    <div className="for-customers-page__app-section section short pad-responsive">
      <h2>
        Scarica l'app e acquista il pacchetto Premium e controlla subito quali
        affari immobiliari ci sono nella tua zona!
      </h2>

      <PricePlans target="user" />
    </div>
  </Fade>
);

export default App;
