import "./TeamCard.scss";
import networkMap from "../../../assets/network-map.png";
import { headLineCity, headLineRole } from "../headline";

import { Fade } from "react-reveal";
import { Buildings, Gear } from "phosphor-react";
import { FaBalanceScale, FaGavel } from "react-icons/fa";

const TeamCard = ({ agentsAndLawyers, operators }) => (
  <Fade>
    <div className="our-team-page__cards container section standard pad-responsive">
      <div className="map-and-operators-container">
        <div className="map">
          <img
            alt="network-map"
            src={networkMap}
            style={{ maxHeight: 480, objectFit: "contain" }}
          />
        </div>
        {!!operators.length && (
          <section className="operators-board">
            {operators.map((item) => (
              <div
                key={`operator_${item.id}`}
                className={`single-team-card ${item.role}`}
              >
                <header>
                  <div className="user-image-container">
                    <div className="internal-border">
                      {!!item.profileImage ? (
                        <img
                          alt={`profile-${item.role}`}
                          src={item.profileImage}
                        />
                      ) : (
                        <Gear size={48} />
                      )}
                    </div>
                  </div>
                  <div className="user-info">
                    <h4>{item.teamRole}</h4>
                    <p>
                      {`${headLineRole(item.role)} ${item.firstname} ${
                        item.lastname
                      }`}
                    </p>
                  </div>
                </header>

                <footer className="user-city">
                  {item.city && (
                    <p>
                      {`${headLineCity(item.city)} ${item.city.toUpperCase()}

                        `}
                    </p>
                  )}
                </footer>
              </div>
            ))}
          </section>
        )}
      </div>
      {!!agentsAndLawyers.length && (
        <section className="agents-lawyers-board">
          {agentsAndLawyers.map((item) => (
            <div
              key={`agent_lawyer_${item.id}`}
              className={`single-team-card ${item.role}`}
            >
              <header>
                <div className="user-image-container">
                  <div className="internal-border">
                    {!!item.profileImage ? (
                      <img
                        alt={`profile-${item.role}`}
                        src={item.profileImage}
                      />
                    ) : item.role === "agent" ? (
                      <Buildings size={48} />
                    ) : (
                      <FaBalanceScale size={48} />
                    )}
                  </div>
                </div>
                <div className="user-info">
                  <h4>{item.teamRole}</h4>
                  <p>
                    {`${headLineRole(item.role)} ${item.firstname} ${
                      item.lastname
                    }`}
                  </p>
                </div>
              </header>

              <footer className="user-city">
                {item.city && (
                  <p>
                    {`${headLineCity(item.city)} ${item.city.toUpperCase()}

                        `}
                  </p>
                )}
              </footer>
            </div>
          ))}
        </section>
      )}
    </div>
  </Fade>
);

export default TeamCard;
