import { CaretLeft, CaretRight, X } from "phosphor-react";
import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./CarouselFull.scss";

const CarouselFull = ({ gallery, zoom }) => {
  const [active, setActive] = useState(0);
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      zoom();
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      zoom();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div className="carousel__carousel-full">
      <div className="container section">
        <div className="heading-slider-immobile-overlay">
          {gallery && (
            <div className="position">
              {active + 1}/{gallery.length}
            </div>
          )}

          <div className="close">
            <X onClick={zoom} />
          </div>
        </div>

        <div ref={ref} className="slider-immobile__slider-full">
          <Swiper
            onSwiper={(swiper) => setActive(swiper.activeIndex)}
            onSlideChange={(swiper) => setActive(swiper.activeIndex)}
            navigation={{
              prevEl: ".swiper-full-immobile-prev",
              nextEl: ".swiper-full-immobile-next",
            }}
            keyboard={{
              enabled: true,
            }}
          >
            {gallery &&
              gallery.map((element, i) => (
                <SwiperSlide key={i}>
                  <img src={element} alt="" style={{ objectFit: "contain" }} />
                </SwiperSlide>
              ))}
          </Swiper>

          <CaretLeft className="swiper-full-immobile-prev" weight="bold" />
          <CaretRight className="swiper-full-immobile-next" weight="bold" />
        </div>
      </div>
    </div>
  );
};

export default CarouselFull;
