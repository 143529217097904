import { Fade } from "react-reveal";
import "./BannerBenefits.scss";

const BannerBenefits = ({ benefits }) => (
  <Fade>
    <div className="banner-benefits-component">
      <div className="container section space">
        <h2 className="general">
          I vantaggi di comprare casa tramite asta giudiziaria
        </h2>

        <div className="benefits-list">
          {benefits.map((element, i) => (
            <div className="benefit" key={i}>
              <img src={element.img} alt="" />

              <h6>{element.title}</h6>

              <p>{element.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Fade>
);

export default BannerBenefits;
