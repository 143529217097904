import "./AccountResearches.scss";
import { useDispatch, useSelector } from "react-redux";
import Fade from "react-reveal";
import {
  getAllFavoriteResearches,
  deleteFavoriteResearch,
} from "reducers/researches";
import { ArrowUpRight, Trash } from "phosphor-react";
import { Link } from "react-router-dom";
import { paramsToString } from "utils/paramsToString";
import { useEffect } from "react";

const AccountResearches = () => {
  const dispatch = useDispatch();
  const researches = useSelector(({ researches }) => researches.data);

  useEffect(() => dispatch(getAllFavoriteResearches()), [dispatch]);

  const deleteResearch = async (id) => {
    await dispatch(deleteFavoriteResearch(id));
    dispatch(getAllFavoriteResearches());
  };

  return (
    <Fade>
      {researches.length > 0 ? (
        <div className="researches-list">
          {researches.map((element, i) => (
            <div key={i} className="research">
              {Object.entries(filterRealEstateData(element))
                .filter((value) => formatValue(value[1]) !== null)
                .map((elem, i) => (
                  <div key={i} className="spec">
                    <span>{`${formatName(elem[0])}: ${formatValue(
                      elem[1]
                    )}`}</span>
                  </div>
                ))}

              <div className="controls">
                <Link
                  to={{
                    pathname: "/immobili",
                    search: paramsToString(filterRealEstateData(element)),
                  }}
                  title="Vai alla ricerca"
                >
                  <ArrowUpRight className="search" />
                </Link>
                <div title="Elimina la ricerca">
                  <Trash
                    className="delete"
                    onClick={() => deleteResearch(element.id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-list ">
          Non hai ancora inserito nessuna ricerca tra i preferiti
        </div>
      )}
    </Fade>
  );
};

export default AccountResearches;

const filterRealEstateData = (data) => {
  const allowed = [
    "acousticCertification",
    "balcony",
    "bathrooms",
    "cellar",
    "energyEfficiency",
    "floors",
    "furnished",
    "garage",
    "garden",
    "heating",
    "lift",
    "rooms",
    "squarefeetMin",
    "squarefeetMax",
    "swimmingPool",
    "type",
    "virtualTour",
    "startingPriceMin",
    "startingPriceMax",
    "realEstateCity",
  ];

  return Object.keys(data)
    .filter((key) => allowed.includes(key))
    .reduce((obj, key) => {
      if (data[key]) {
        if (key === "realEstateCity") {
          obj["realEstate.city"] = data[key];
        } else {
          obj[key] = data[key];
        }
      }
      return obj;
    }, {});
};

const formatName = (name) => {
  switch (name) {
    case "acousticCertification":
      return "Certificazione acustica";
    case "balcony":
      return "Terrazzo";
    case "bathrooms":
      return "Bagni";
    case "cellar":
      return "Cantina";
    case "energyEfficiency":
      return "Effienca Energetica";
    case "floors":
      return "Piani";
    case "furnished":
      return "Arredata";
    case "garage":
      return "Posto auto";
    case "garden":
      return "Giardino";
    case "heating":
      return "Riscaldamento";
    case "lift":
      return "Ascensore";
    case "rooms":
      return "Locali";
    case "squarefeet":
      return "Superficie";
    case "swimmingPool":
      return "Piscina";
    case "type":
      return "Tipologia";
    case "virtualTour":
      return "Virtual toor";
    case "startingPriceMin":
      return "Prezzo minimo";
    case "startingPriceMax":
      return "Prezzo massimo";
    case "squarefeetMin":
      return "Superficie minima";
    case "squarefeetMax":
      return "Superficie massima";
    case "realEstate.city":
      return "Città";
    default:
      return name;
  }
};

const formatValue = (value) => {
  if (typeof value === "boolean") {
    return "Sì";
  }
  if (typeof value === "string") {
    return capitalize(value);
  }
  return value;
};

const capitalize = (string) => {
  return string[0].toUpperCase() + string.substring(1).toLowerCase();
};
