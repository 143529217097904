import { Fade } from "react-reveal";
import "./ServicesSection.scss";

const ServicesSection = () => (
  <div className="services-page__services-section container section short pad-responsive">
    <Fade>
      <div className="description">
        <h2 className="general">
          Servizio legale per clienti astaéxpert Premium: assistenza e
          consulenza per acquisto all'asta
        </h2>

        <p>
          Una volta selezionato l'immobile in asta e completata la visita
          dell'immobile, si attiva il servizio di assistenza e consulenza
          legale, necessario per analizzare nel dettaglio la perizia e tutti gli
          aspetti tecnico/legali riferiti alla procedura. Passata positivamente
          questa verifica il cliente viene guidato verso il successivo processo
          di partecipazione all'asta.
        </p>
      </div>
    </Fade>

    <Fade right>
      <div className="illustration"></div>
    </Fade>
  </div>
);

export default ServicesSection;
