import BannerBenefits from "components/banner-benefits/BannerBenefits";
import { benefits, hero, services } from "./items";
import Hero from "../components/hero/Hero";
import Services from "../components/services/Services";
import Contacts from "../components/contacts/Contacts";
import ContactUs from "../components/contact-us/ContactUs";
import { Fade } from "react-reveal";

const ForLawers = () => (
  <div className="for-lawers-page">
    {/* <Fade> */}
    <Hero hero={hero} />

    <Contacts />

    <Services services={services} />

    <BannerBenefits benefits={benefits} />

    <ContactUs />
    {/* </Fade> */}
  </div>
);

export default ForLawers;
