import "./Story.scss";
import { numbers } from "./items";
import { Fade } from "react-reveal";

const Story = () => (
  <Fade>
    <div className="about-us-page__story container section standard pad-responsive">
      <div className="description">
        <span className="category">Storia</span>
        <h2 className="general">
          Comprare la casa dei tuoi sogni deve essere un'operazione semplice,
          senza rischi ed immediata.
        </h2>
        <p>
          Tu scegli l'immobile, al resto pensiamo noi. Dalla visita preliminare
          fino al giorno di partecipazione all’asta, saprai sempre di essere in
          mani sicure.
        </p>
      </div>

      <div className="numbers section">
        {numbers.map((element, i) => (
          <div key={i} className="number">
            <h4>{element.value}</h4>
            <p>{element.text}</p>
          </div>
        ))}
      </div>
    </div>
  </Fade>
);

export default Story;
