import axios from "axios";
import api from "api/visits";

const GET_ALL_VISITS_REQUEST = "GET_ALL_VISITS_REQUEST";
const GET_ALL_VISITS_SUCCESS = "GET_ALL_VISITS_SUCCESS";
const GET_ALL_VISITS_FAILURE = "GET_ALL_VISITS_FAILURE";

const REQUEST_VISIT_REQUEST = "REQUEST_VISIT_REQUEST";
const REQUEST_VISIT_SUCCESS = "REQUEST_VISIT_SUCCESS";
const REQUEST_VISIT_FAILURE = "REQUEST_VISIT_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const reducerVisits = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_VISITS_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_ALL_VISITS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_ALL_VISITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REQUEST_VISIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case REQUEST_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state, action.payload],
      };
    case REQUEST_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducerVisits;

export const getAllVisits = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_VISITS_REQUEST,
  });

  try {
    const { url, method, headers } = api.getAllVisits();

    const response = await axios({
      url,
      method,
      headers,
    });
    dispatch({
      type: GET_ALL_VISITS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_VISITS_FAILURE,
      payload: error,
    });
  }
};

export const requestVisit =
  (realEstateId, scheduledDate, scheduledSlot) => async (dispatch) => {
    dispatch({
      type: REQUEST_VISIT_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.requestVisit(
        realEstateId,
        scheduledDate,
        scheduledSlot
      );

      const response = await axios({
        url,
        method,
        headers,
        data,
      });
      if (response) {
        window.dataLayer.push({
          event: "visit_request",
        });
      }
      dispatch({
        type: REQUEST_VISIT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: REQUEST_VISIT_FAILURE,
        payload: error,
      });
    }
  };
