const SET_PARAMS = "SET_PARAMS";

const initialState = null;

export default function reducerViewResearch(state = initialState, action) {
  switch (action.type) {
    case SET_PARAMS:
      return action.payload;

    default:
      return state;
  }
}

export const setParams = (params) => async (dispatch) => {
  dispatch({
    type: SET_PARAMS,
    payload: params,
  });
};
