const development = {
  apiUrl: "http://localhost:8087/frontend",
  adminUrl: "http://localhost:3001/",
  frontendUrl: "http://localhost:3000",
};

const staging = {
  apiUrl: "https://api.astaexpert.thecove.it/frontend",
  adminUrl: "https://admin.astaexpert.thecove.it/",
  frontendUrl: "https://astaexpert.thecove.it",
};

const production = {
  apiUrl: "https://api.astaexpert.it/frontend",
  adminUrl: "https://admin.astaexpert.it/",
  frontendUrl: "https://astaexpert.it",
};

const configs = {
  development,
  staging,
  production,
};

const env = process.env.NODE_ENV || "development";

export default configs[env];
