// import { House } from "phosphor-react";
import "./InfoBar.scss";

const InfoBar = ({ singleRealEstate }) =>
  (!!singleRealEstate.rooms ||
    !!singleRealEstate.square ||
    !!singleRealEstate.bathrooms) && (
    <div className="info-real-estate__info-bar">
      {/* <div className="item unita">
        <House weight="bold" />
        <span className="number">1</span> <span>Unità</span>
      </div> */}

      {!!singleRealEstate.rooms && (
        <div className="item">
          <span className="number">{singleRealEstate.rooms} Locali</span>
        </div>
      )}

      {!!singleRealEstate.square && (
        <div className="item">
          <span className="number">
            {singleRealEstate.squarefeet} m<sup>2</sup>
          </span>
        </div>
      )}

      {!!singleRealEstate.bathrooms && (
        <div className="item">
          <span className="number">
            {singleRealEstate.bathrooms}{" "}
            {singleRealEstate.bathrooms === 1 ? "Bagno" : "Bagni"}
          </span>
        </div>
      )}
    </div>
  );

export default InfoBar;
