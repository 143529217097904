import { InputCheck, InputSelect, Select } from "components/inputs/inputs";
import Logo from "assets/logo/astaexpert.svg";
import { isEqual } from "lodash";
import {
  Backspace,
  GridFour,
  List,
  MagnifyingGlass,
  MapPin,
  Minus,
} from "phosphor-react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCities } from "reducers/cities";
import { setParams } from "reducers/researchParams";
import { setViewResearch } from "reducers/view-research";
import { paramsToString } from "utils/paramsToString";
import { filters } from "./filters";
import "./SearchBar.scss";
import { useLocation } from "react-router-dom";
import moment from "moment";

export const initialSearchParams = {
  type: { value: "", label: "Tipo di immobile" },
  "realEstate.city": { value: "", label: "" },
  rooms: { value: "", label: "Locali" },
  bathrooms: { value: "", label: "Bagni" },
  swimmingPool: { value: "", label: "Piscina" },
  saleType: { value: "", label: "Vendita" },
  garage: { value: "", label: "Garage" },
  garden: { value: "", label: "Giardino" },
  virtualTour: { value: "", label: "Virtual Tour" },
  acousticCertification: { value: "", label: "Acustica" },
  cellar: { value: "", label: "Cantina" },
  balcony: { value: "", label: "Terrazzo e Balcone" },
  furnished: { value: "", label: "Arredata" },
  lift: { value: "", label: "Ascensore" },
  heating: { value: "", label: "Riscaldamento" },
  energyEfficiency: { value: "", label: "Classe energetica" },
  startingPriceMax: { value: "", label: "Prezzo" },
  startingPriceMin: { value: "", label: "Prezzo" },
  squarefeetMax: { value: "", label: "Superficie" },
  squarefeetMin: { value: "", label: "Superficie" },
  isChosen: { value: "", label: "Preferite" },
};

const SearchBar = (props) => {
  const { state } = useLocation();
  const savedParams = useSelector(({ researchParams }) => researchParams);
  const initialSearchParamsComplete =
    savedParams && props.search && !state
      ? savedParams
      : {
          ...initialSearchParams,
          ...props.barParams,
          "realEstate.city": {
            value:
              props.barParams && props.barParams["realEstate.city"]
                ? props.barParams["realEstate.city"].value
                : "",
            label:
              props.barParams && props.barParams["realEstate.city"]
                ? decodeURIComponent(props.barParams["realEstate.city"].value)
                : "",
          },
        };
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState(initialSearchParamsComplete);
  const viewResearch = useSelector(({ viewResearch }) => viewResearch);
  const savedFilters = useSelector(({ researchParams }) => researchParams);
  const cities = useSelector(({ cities }) => cities.data);
  const user =
    useSelector(({ user }) => user?.data?.user?.role) === "paying_user";

  useEffect(() => {
    if (props.search) {
      handleFetchRealEstates(false, true);
    }

    // eslint-disable-next-line
  }, [props.sort, props.pageStart, props.assuredPartecipation]);

  // useEffect(() => {
  //   cities.length === 0 && dispatch(getCities());
  // }, [cities, dispatch]);

  useEffect(() => {
    if (savedFilters) {
      setSearchParams(savedFilters);
    }
  }, [savedFilters]);

  useEffect(() => {
    // if (searchParams === initialSearchParams) {
    //   handleFetchRealEstates();
    // }
    const checkAdvancedFilters = () =>
      Object.keys(searchParams)
        .filter((key) => advancedFilters.includes(key))
        .filter(
          (value) =>
            searchParams[value].value !== "" &&
            searchParams[value].value !== false
        ).length;

    props.setActiveAdvancedFilters &&
      props.setActiveAdvancedFilters(checkAdvancedFilters());
  }, [props, searchParams]);

  const handleFetchRealEstates = async (resetPage = false, loadMap = true) => {
    const params = await Object.entries(searchParams)
      .filter((arr) => isValid(arr[1].value))
      .reduce((acc, curr) => {
        if (curr[1].isProvince) {
          return {
            ...acc,
            "realEstate.province": curr[1].province,
          };
        }
        return {
          ...acc,
          [curr[0]]: curr[1].value,
        };
      }, {});

    if (resetPage) {
      props.handleChangePage(0);
    }
    props.search(
      {
        ...{
          ...params,
          "realEstate.city": params["realEstate.city"]
            ? decodeURIComponent(params["realEstate.city"])
            : null,
          ...(props.assuredPartecipation
            ? {
                "auction.dateMin": moment()
                  .add(1, "month")
                  .format("YYYY-MM-DD"),
              }
            : {}),
        },
        pageStart: resetPage ? 0 : props.pageStart,
      },
      loadMap
    );

    // props.closeAdvanced();

    dispatch(
      setParams({
        ...searchParams,
        // pageStart: resetPage ? 0 : props.pageStart,
      })
    );

    dispatch(setParams(searchParams));
  };

  const onChange = (target, value) =>
    setSearchParams({ ...searchParams, [target]: value });

  const onChange2 = (target1, value1, target2, value2) =>
    setSearchParams({ ...searchParams, [target1]: value1, [target2]: value2 });

  const onReset = (target, value) => {
    const params = Object.entries({ ...searchParams, [target]: value })
      .filter((arr) => isValid(arr[1].value))
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr[0]]: curr[1].value,
        };
      }, {});
    setSearchParams({ ...searchParams, [target]: value });
    if (props.search) {
      props.search({ ...params });
    }
  };
  const onReset2 = (target1, value1, target2, value2) => {
    const params = Object.entries({
      ...searchParams,
      [target1]: value1,
      [target2]: value2,
    })
      .filter((arr) => isValid(arr[1].value))
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr[0]]: curr[1].value,
        };
      }, {});
    setSearchParams({ ...searchParams, [target1]: value1, [target2]: value2 });
    if (props.search) {
      props.search({ ...params });
    }
  };

  const onResetCity = (target, value) => {
    const params = Object.entries({ ...searchParams, [target]: value })
      .filter((arr) => isValid(arr[1].value))
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr[0]]: curr[1].value,
        };
      }, {});
    setSearchParams({ ...searchParams, [target]: value });
    if (props.search) {
      props.search({ ...params });
    }
    setSearchParams({ ...searchParams, [target]: value });
    dispatch(getCities());
  };

  const onChangeCity = (target, value) => {
    setSearchParams({ ...searchParams, [target]: value });
    dispatch(getCities(value.value));
  };

  const onCheckChange = (target, obj, value) =>
    setSearchParams({ ...searchParams, [target]: { ...obj, value: value } });

  const handleTotalReset = () => {
    setSearchParams(initialSearchParams);
    dispatch(getCities());
    props.setActiveAdvancedFilters(0);
    const params = Object.entries(initialSearchParams)
      .filter((arr) => isValid(arr[1].value))
      .reduce((acc, curr) => {
        return {
          ...acc,
          [curr[0]]: curr[1].value,
        };
      }, {});
    props.search(params);
  };

  const showReset = isEqual(searchParams, initialSearchParams);

  const renderFilters = (filter) => {
    switch (filter.type) {
      case "select":
        return (
          <Select
            placeholder={initialSearchParams[filter.name].label}
            value={searchParams[filter.name]}
            target={filter.name}
            onChange={onChange}
            onReset={onReset}
            options={filter.options}
          />
        );

      case "select2":
        return (
          <Select
            placeholder={initialSearchParams[filter.name].label}
            value={searchParams[filter.name]}
            target={filter.name}
            target2={filter.name2}
            delta={filter.delta}
            measure={filter.measure}
            onChange={onChange2}
            onReset={onReset2}
            options={filter.options}
          />
        );
      case "input-check":
        return (
          <InputCheck
            placeholder={initialSearchParams[filter.name].label}
            state={searchParams[filter.name]}
            target={filter.name}
            onChange={onCheckChange}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div
      id="wrapper-search-bar"
      className={`container-fluid ${props.homepage ? "homepage" : ""}`}
    >
      <div className="simple">
        <Link to="/" className="logo">
          <img src={Logo} alt="" />
        </Link>

        <div className="simple-filters">
          <div className="filters">
            <InputSelect
              value={searchParams["realEstate.city"]}
              onChange={onChangeCity}
              onReset={onResetCity}
              target="realEstate.city"
              placeholder="Dove?"
              isDesktop={props.isDesktop}
              options={cities.map((city) => ({
                value: city.comune,
                label:
                  city.comune +
                  (city.provinciaLungo
                    ? city.isProvince
                      ? " - Provincia"
                      : " - " +
                        (city.provinciaLungo === city.comune
                          ? "Comune"
                          : city.provinciaLungo)
                    : ""),
                fullLabel: (
                  <div style={{ display: "flex" }}>
                    {city.comune}{" "}
                    {city.provinciaLungo && (
                      <div
                        style={{
                          color: "#333",
                          marginLeft: 4,
                          fontWeight: "normal",
                        }}
                      >
                        {city.isProvince
                          ? " - Provincia"
                          : " - " +
                            (city.provinciaLungo === city.comune
                              ? "Comune"
                              : city.provinciaLungo)}
                      </div>
                    )}
                  </div>
                ),
                isProvince: city.isProvince,
                province: city.provincia,
              }))}
            />
            {filters
              .filter((value) => !value.advanced)
              .map((element, i) => (
                <Fragment key={i}>{renderFilters(element)}</Fragment>
              ))}
          </div>

          <div
            className={`search-button ${showReset ? "" : "active"} ${
              props.advancedSearch ? "hide" : ""
            }`}
          >
            {props.search ? (
              <>
                {/* <div title="Reset filtri" className="reset">
                  <Backspace onClick={handleTotalReset} />
                </div> */}

                <button
                  onClick={() => handleFetchRealEstates(true, true)}
                  style={{
                    width: "100%",
                    minWidth: 160,
                    height: "55px",
                    marginLeft: "0.5rem",
                  }}
                  className="btn orange"
                >
                  <MagnifyingGlass weight="bold" />

                  <span>Trova</span>
                </button>
              </>
            ) : (
              <Link
                style={{ width: "100%", height: "55px", marginLeft: "0.5rem" }}
                className="btn orange"
                to={{
                  pathname: "/immobili",
                  search: paramsToString({
                    ...{
                      type: searchParams.type.value,
                      startingPriceMax: searchParams.startingPriceMax.value,
                      startingPriceMin: searchParams.startingPriceMin.value,
                      squarefeetMax: searchParams.squarefeetMax.value,
                      squarefeetMin: searchParams.squarefeetMin.value,
                    },
                    ...(searchParams["realEstate.city"].isProvince
                      ? {
                          "realEstate.province":
                            searchParams["realEstate.city"].province,
                        }
                      : {
                          "realEstate.city":
                            searchParams["realEstate.city"].value,
                        }),
                  }),
                  state: true,
                }}
              >
                <MagnifyingGlass weight="bold" />

                <span>Trova</span>
              </Link>
            )}
          </div>
        </div>

        <div className="view-options">
          <MapPin
            className={viewResearch === "map" ? "active" : ""}
            weight="bold"
            onClick={() => dispatch(setViewResearch("map"))}
          />
          <GridFour
            className={viewResearch === "box" ? "active" : ""}
            weight="bold"
            onClick={() => dispatch(setViewResearch("box"))}
          />
          <List
            className={viewResearch === "list" ? "active list" : "list"}
            weight="bold"
            onClick={() => dispatch(setViewResearch("list"))}
          />
        </div>
      </div>

      {props.advancedSearch && (
        <div className="advanced">
          <div className="advanced-filters">
            <div className="logo"></div>

            <div className="advanced-filters-section">
              <div className="advanced-filters">
                {filters
                  .filter((value) => (user ? value : !value.logged))
                  .filter((value) => value.advanced)
                  .map((element, i) => (
                    <Fragment key={i}>{renderFilters(element)}</Fragment>
                  ))}
              </div>

              <div className="button">
                <div className="advanced-search" onClick={props.toggleAdvanced}>
                  <span>Ricerca semplice</span>

                  <Minus />
                </div>
              </div>
            </div>

            <div className="view-options"></div>
          </div>

          <div
            className={`search-button advanced-button ${
              showReset ? "" : "active"
            }`}
          >
            <div title="Reset filtri" className="reset">
              <Backspace onClick={handleTotalReset} />
            </div>
            <button onClick={handleFetchRealEstates} className="btn orange">
              <MagnifyingGlass weight="bold" />

              <span>Trova</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;

const isValid = (val) => !!val && val !== "";

const advancedFilters = [
  "rooms",
  "bathrooms",
  "heating",
  "energyEfficiency",
  "swimmingPool",
  "garage",
  "lift",
  "cellar",
  "furnished",
  "virtualTour",
  "acousticCertification",
  "balcony",
  "garden",
  "saleType",
  "isChosen",
];
