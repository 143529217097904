import { CaretDown } from "phosphor-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Dropdown.scss";

const Dropdown = (props) => {
  const [dropdown, setDropdown] = useState(false);

  return (
    <>
      <div
        className="voice"
        style={{ cursor: "pointer" }}
        onClick={() => setDropdown(!dropdown)}
      >
        <div style={{ cursor: "pointer" }}>{props.element.name}</div>
        <CaretDown
          className={`caret ${dropdown ? "active" : ""}`}
          // onClick={() => setDropdown(!dropdown)}
        />
      </div>

      <div className={`dropdown-hamburger-menu ${dropdown ? "active" : ""}`}>
        {props.element.dropdown.map((subElement, i) =>
          subElement.function ? (
            <div key={i} className="logout" onClick={subElement.function}>
              {subElement.name}
            </div>
          ) : (
            <Link
              style={{ display: "flex" }}
              onClick={() => props.function()}
              to={subElement?.to}
              key={i}
              dangerouslySetInnerHTML={{ __html: subElement.name }}
            />
          )
        )}
      </div>
    </>
  );
};

export default Dropdown;
