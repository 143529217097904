import { FaFacebook, FaInstagram } from "react-icons/fa";

export const menu = [
  {
    title: "Compravendita",
    voices: [
      {
        title: "Immobili",
        to: "/immobili",
      },
      {
        title: "Calendario aste",
        to: "/calendario-aste",
      },
      {
        title: "Blog",
        to: "/blog",
      },
    ],
  },
  {
    title: "Aste Giudiziarie",
    voices: [
      {
        title: "Accesso Autorità Giudiziaria",
        to: "https://admin.astaexpert.it/#/login",
        externalLink: true,
      },
      {
        title: "FAQ",
        to: "/faq",
      },
      {
        title: "Modulistica",
        to: "/modulistica",
      },
      {
        title: "Contattaci",
        to: "/contattaci",
      },
    ],
  },
  {
    title: "Informativa",
    voices: [
      {
        title: "Privacy Policy",
        pathname: "/privacy-policy",
        state: "privacy",
      },
      {
        title: "Cookie Policy",
        pathname: "/cookies",
        state: "cookies",
      },
      {
        title: "Termini e Condizioni PREMIUM",
        pathname: "/termini-e-condizioni-premium",
        state: "premium",
      },
      // {
      //   title: "Termini e Condizioni PRO",
      //   pathname: "/termini-e-condizioni-pro",
      //   state: "pro",
      // },
    ],
  },
];

export const social = [
  {
    icon: <FaFacebook />,
    href: "https://www.facebook.com/astaexpert",
  },
  {
    icon: <FaInstagram />,
    href: "https://www.instagram.com/astaexpert/?hl=it",
  },
];
