import { CaretDown } from "phosphor-react";
import { useState } from "react";
import Map from "./Map";

const MapSection = ({ position }) => {
  const [active, setActive] = useState(false);

  return (
    <div className="info-real-estate-page__map-section">
      <div
        onClick={() => setActive((state) => !state)}
        className="heading-section"
      >
        <h4>Mappa</h4>
        <CaretDown weight="bold" className={active ? "active" : ""} />
      </div>

      {active && (
        <Map
          position={{
            lat: +position.lat,
            lng: +position.lon,
          }}
        />
      )}
    </div>
  );
};

export default MapSection;
