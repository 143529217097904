import Img1 from "assets/scene/9.svg";
import Img2 from "assets/scene/05smart.png";
import heroImg from "assets/scene/6.svg";

export const hero = {
  img: heroImg,
  title: "Immobili in asta o sul mercato libero?",
  description:
    "Il confronto crea opportunità. Sulla base di questa considerazione abbiamo sviluppato il portale e l’App astaéxpert. Il cliente può ricercare in autonomia i migliori affari immobiliari in asta, controllando immobili in tutta Italia, in più può anche ricercare sul medesimo portale le disponibilità e confrontare i prezzi di immobili simili sul mercato libero. Il consulente immobiliare astaéxpert dedicato aiuterà il cliente nella selezione del miglior affare, considerando il budget di spesa a disposizione, il tempo a disposizione e l’offerta generale di mercato.",
};

export const services = [
  {
    img: Img1,
    text: `Entrando nella sezione di ricerca del portale web o nell’app puoi effettuare le tue ricerche personalizzate.
    Durante la ricerca, se necessario puoi attivare tra i risultati di ricerca anche gli immobili disponibili sul mercato libero, così da poter confrontare in autonomia i prezzi degli immobili sui diversi mercati.`,
  },
  {
    img: Img2,
    title: "Arancione o azzurro?",
    text: `Abbiamo identificato due colori differenti per i due diversi tipi di mercato: gli immobili in arancione identificano le aste, mentre gli immobili in azzurro identificano gli immobili disponibili sul mercato libero.
    La visibilità degli immobili sul mercato aiuta anche ad indentificare i risparmi che si possono ottenere acquistando in asta oltre che a valutare l’offerta di mercato nel suo complesso.
    Il consulente immobiliare astaéxpert a te dedicato, sarà il tuo referente per la tua ricerca e ti supporterà per aiutarti a trovare il miglior immobile, che sia o meno in asta, accompagnandoti dalla selezione fino all’effettiva aggiudicazione o proprietà del tuo prossimo affare immobiliare.`,
  },
];
