import { Check, X } from "phosphor-react";
import { Link } from "react-router-dom";
import "./Advice.scss";
import { useRef } from "react";
import { useEffect } from "react";

const Advice = (props) => {
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      props.closeBanner();
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.closeBanner();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div ref={ref} className="price-plans-component__advice">
      <X className="close-advice" onClick={props.closeBanner} />

      <div className="heading-banner">
        Sei sicuro di non volere provare la nostra{" "}
        <span className="category">offerta Premium</span>?
      </div>

      <div className="price-section">
        A soli{" "}
        <span className="price">
          <sup>€</sup>
          {props.cards[1].price}
        </span>
        <span className="time">al mese</span>
      </div>

      <div className="features">
        {props.cards[1].features
          .filter((element) => !!element)
          .map((element, i) => (
            <div key={i} className="feature">
              <Check weight="bold" />
              <span>{element}</span>
            </div>
          ))}
      </div>

      <div
        onClick={() => props.handleBuyClick(props.cards[1])}
        className="acquista btn orange"
      >
        acquista
      </div>

      <Link className="free-plan" to="/registrati">
        scegli il piano gratuito
      </Link>
    </div>
  );
};

export default Advice;
