import Number from "assets/benefits/icona-oltre-30000-immobili.svg";
import Like from "assets/benefits/icona-scegli-quello-che-ti-piace.svg";
import Cheap from "assets/benefits/icona-massimo-risparmio.svg";
import Easy from "assets/benefits/icona-un-sistema-semplice-facile-e-chiaro.svg";
import heroImg from "assets/scene/2.svg";
import Img1 from "assets/scene/1.svg";
import Img5 from "assets/scene/5.svg";
import Img9 from "assets/scene/9.svg";
import Img10 from "assets/scene/10a.svg";
import Img7 from "assets/scene/7.svg";

export const hero = {
  img: heroImg,
  destination: "Per i Clienti",
  title: "Compra immobili ed ottieni il massimo risparmio possibile.",
  description:
    "Con astaéxpert hai un servizio che ti segue a 360°: un portale completo con immobili in asta in tutte le regioni d'Italia, un agente immobiliare dedicato ed un avvocato specializzato pronti per guidarti alla scoperta del tuo prossimo affare immobiliare e ad aiutarti a conseguire il massimo vantaggio nella compravendita.",
};

export const services = [
  {
    img: Img5,
    title: "Scarica l'app astaéxpert disponibile su iOS o Android e registrati",
    text: "Con soli 9,99€/mese senza vincoli di durata, puoi avere accesso al mercato degli affari immobiliari in asta  che conta oltre 30.000 immobili in Italia.",
  },
  {
    img: Img1,
    title: "Registrazione profilo utente e ricerca",
    text: "Dopo aver completato la sottoscrizione dell'account premium, seguirà una procedura guidata tramite la quale potrai definire le tue preferenze immobiliari ed iniziare anche a selezionare gli immobili più interessanti.",
  },
  {
    img: Img9,
    title: "Tu scegli l'immobile e noi organizziamo la visita",
    text: "Se tra gli immobili disponibili sul mercato delle aste trovi l'affare che ti interessa, a questo punto interviene l'agente immobiliare che organizza la visita sul posto per tuo conto.",
  },
  {
    img: Img7,
    title: "Valutazione Tecnico-Legale Preliminare",
    text: "Una volta visto l'immobile e analizzate con l'agente immobiliare le opportunità di acquisto, attivando il servizio di assistenza e consulenza legale convenzionato svolto da un avvocato astaéxpert, verranno analizzati tutti i documenti e definiti i dettagli tecnici ed economici dell'intera operazione.",
  },
  {
    img: Img10,
    title: "E' il momento dell'asta",
    text: "Sei arrivato al momento decisivo. Solo l'asta ti separa dall'immobile dei tuoi sogni. Grazie ad astaéxpert verrai portato al risultato senza rischi e con la tranquillità di essere seguito in tutte le fasi da personale altamente qualificato che saprà indicarti come e quanti rilanci effettuare durante l’asta, e qual è il valore massimo a cui spingerti, per garantirti sempre il migliore affare.",
  },
];

export const benefits = [
  {
    img: Number,
    title: "Oltre 30.000 immobili",
    text: "Le liste immobili di astaéxpert sono in continuo aggiornamento ed ogni immobile rappresenta una opportunità da non perdere.",
  },
  {
    img: Like,
    title: "Tu scegli quello che ti piace: al resto ci pensiamo noi",
    text: "astaéxpert unisce le competenze tecniche di studi legali con le conoscenze territoriali delle agenzie immobiliari, per portare il cliente dritto al punto. Scegli l'immobile giusto per te e lascia fare il resto a noi",
  },
  {
    img: Cheap,
    title: "Concreta possibilità di risparmio",
    text: "Il 97% dei clienti che acquista immobili in asta riesce ad ottenere un vantaggio medio del 25% rispetto ad un acquisto standard sul mercato immobiliare.",
  },
  {
    img: Easy,
    title: "Un sistema semplice, facile e chiaro",
    text: "Con astaéxpert oltre ad essere seguito da un legale dedicato e da un agente immobiliare, avrai accesso anche ad un'area riservata dove potrai salvare gli immobili che più ti interessano, potendo cosi valutare la documentazione del caso e quanto ti servirà per poter decidere se e quando proseguire con l'acquisto.",
  },
];
