import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { destinations } from "./items";
import "./Purpose.scss";

const Purpose = () => (
  <div className="why-asta__purpose section">
    <Fade bottom>
      <div className="container pad-responsive">
        <h2 className="general">A chi si rivolge</h2>

        <p>
          astaéxpert: il portale italiano dedicato a clienti, investitori e
          agenti per l'acquisto in asta semplificato in costante aggiornamento.
          Scopri come possiamo aiutarti a trovare l'occasione giusta.
        </p>

        <div className="destinations section">
          {destinations.map((element, i) => (
            <div key={i} className="destination">
              <div>
                <div
                  className="img"
                  style={{ backgroundImage: `url(${element.img})` }}
                ></div>
                <h5>{element.title}</h5>

                <p>{element.text}</p>
              </div>

              <Link
                to={element.to}
                className="btn orange"
                style={{ alignSelf: "center" }}
              >
                Scopri di più
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Fade>
  </div>
);

export default Purpose;
