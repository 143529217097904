import { API_ROOT_URL, getAuthHeaders } from "./index";

const requests = {
  // SUBSCRIPTIONS

  getSubscription: () => {
    return {
      url: `${API_ROOT_URL}/account/subscription`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  subscribe: (subscriptionId) => {
    return {
      url: `${API_ROOT_URL}/subscription/subscribe`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { subscriptionId },
    };
  },
  renewSubscription: () => {
    return {
      url: `${API_ROOT_URL}/subscription/renew-subscription`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  deleteSubscription: () => {
    return {
      url: `${API_ROOT_URL}/subscription/delete-subscription`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
};

export default requests;
