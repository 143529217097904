import MapDensity from "components/map-density/MapDensity";
import { Fade } from "react-reveal";
import "./Publicity.scss";

const Publicity = () => (
  <div className="services-page__publicity container section pad-responsive">
    <Fade>
      <div className="description">
        <h2 className="general">
          Servizio per agenzie immobiliari: visibilità immobili mercato libero
          su mappa astaéxpert
        </h2>

        <p>
          astaéxpert mette a disposizione per le proprie agenzie partner
          un'opportunità unica. Ogni agenzia immobiliare affiliata ad astaéxpert
          condivide il proprio portafoglio di immobili in vendita sul mercato
          libero. Grazie a questo servizio sulla mappa generale il cliente
          astaéxpert può visionare sia gli immobili in asta (in arancione) che
          quelli disponibili nella stessa zona sul mercato libero (in azzurro)
          per confrontare in tempo reale tutte le opportunità di risparmio
          disponibili.
        </p>
      </div>
    </Fade>

    <Fade right>
      <MapDensity />
    </Fade>
  </div>
);

export default Publicity;
