import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { InputCheck2 } from "components/inputs/input-check/InputCheck";
import OrangePin from "assets/map-pin-orange.png";
import BluePin from "assets/map-pin-lightblue.png";
import Cluster from "./cluster.png";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { mapStyle } from "utils/general";
import priceFormatter from "utils/priceFormatter";
import "./Map.scss";

const Map = (props) => {
  const totalResultsAuction = useSelector(
    ({ realEstate }) => realEstate.pagination.totalResultsAuction
  );
  const totalResultsFree = useSelector(
    ({ realEstate }) => realEstate.pagination.totalResultsFree
  );
  const mapData = useSelector(({ realEstate }) => realEstate.mapData);
  const [mapRef, setMapRef] = useState(null);
  const [freeRealEstates, setFreeRealEstates] = useState(
    totalResultsAuction === 0 && totalResultsFree > 0
  );
  const user =
    useSelector(({ user }) => user?.data?.user?.role) === "paying_user";

  // const filterFreeRealEstates = (list) =>
  //   freeRealEstates ? list : list.filter(({ saleType }) => saleType !== "free");

  const list = mapData
    // .filter((element) =>
    //   freeRealEstates ? element : element.saleType !== "free"
    // )
    .map((element) => ({
      // const list = mapData.map((element) => ({
      ...element,
      position: {
        // lat: +element.lat + getRandomArbitrary(),
        // lng: +element.lon + getRandomArbitrary(),
        lat: +element.lat,
        lng: +element.lon,
      },
    }));

  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    list.forEach(({ position }) => bounds.extend(position));
    // mapData.forEach(({ lat, lon }) => bounds.extend({ lat, lon }));
    map.fitBounds(bounds);
  };

  useEffect(() => {
    if (mapRef) {
      fitBounds(mapRef);
    }
    // eslint-disable-next-line
  }, [list]);
  // }, [mapData]);

  return (
    <GoogleMap
      mapContainerClassName={
        props.mobile
          ? "info-real-estate-page__map-box__mobile"
          : "info-real-estate-page__map-box"
      }
      options={mapOptions}
      onLoad={(map) => setMapRef(map)}
    >
      {/* {user && totalResultsFree > 0 && (
        <InputCheck2
          placeholder="Non in asta"
          active={freeRealEstates}
          handleChange={() => setFreeRealEstates((state) => !state)}
        />
      )} */}

      <MarkerClusterer styles={clusterStyles} minimumClusterSize={5}>
        {(clusterer) =>
          list.map((element, i) => (
            <InfoWindowMap
              key={i}
              clusterer={clusterer}
              element={{
                ...element,
                position: {
                  // lat: +element.lat + getRandomArbitrary(),
                  // lng: +element.lon + getRandomArbitrary(),
                  lat: +element.lat,
                  lng: +element.lon,
                },
              }}
              // user={user}
            />
          ))
        }
      </MarkerClusterer>
    </GoogleMap>
  );
};

export default Map;

const InfoWindowMap = ({ element, clusterer, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const url = element.saleType === "auction" ? OrangePin : BluePin;
  return (
    <Marker
      key={createKey(element.position)}
      position={element.position}
      clusterer={clusterer}
      icon={{ ...icon, url }}
      onClick={() => setIsOpen((state) => !state)}
    >
      {isOpen && (
        <InfoWindow>
          {true ? (
            <Link to={"/immobile/" + element.id} className="info-window anchor">
              <div>{element.title}</div>
              <div>{element.fullAddress}</div>
              <div>
                Prezzo:{" "}
                {element.auction
                  ? priceFormatter(
                      element.auction.minimumBidPrice ||
                        element.auction.startingPrice
                    )
                  : priceFormatter(element.price)}{" "}
                €
              </div>
              <div>
                {element.saleType === "auction"
                  ? `Data asta: ${moment(element.auction.date).format("L")}`
                  : "Non in asta"}
              </div>
            </Link>
          ) : (
            <div className="info-window">
              {/* <div>{element.title}</div>
              <div>{element.fullAddress}</div>
              <div>
                Prezzo:{" "}
                {element.auction &&
                  priceFormatter(element.auction.startingPrice)}{" "}
                €
              </div>
              <div>
                {element.saleType === "auction"
                  ? `Data asta: ${moment(element.auction.date).format("L")}`
                  : "Non in asta"}
              </div> */}
              <span className="subscribe-for-more">
                <Link to="/inizia">Iscriviti</Link> per vedere tutti i dettagli
                dell'immobile
              </span>
            </div>
          )}
        </InfoWindow>
      )}
    </Marker>
  );
};

const mapOptions = {
  styles: mapStyle,
};

const clusterStyles = [
  {
    textColor: "white",
    url: Cluster,
    height: 40,
    width: 40,
  },
];

const icon = {
  scaledSize: {
    width: 50,
    height: 50,
  },
};

const createKey = ({ lat, lng }) => lat + lng;

// const getRandomArbitrary = () =>
//   (Math.round(Math.random() * 10) / 1000000) *
//   (Math.round(Math.random()) ? 1 : -1);

// const getRandomArbitrary = (min, max) => Math.random() * (max - min) + min;

// const getRandomArbitrary3 = (value) =>
//   value * (Math.round(Math.random()) ? 1 : -1);
