import Pagination from "components/pagination/Pagination";
import SearchBar from "components/search-bar/SearchBar";
import Spinner from "components/spinner/Spinner";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getAllRealEstates, getAllRealEstatesMap } from "reducers/real-estate";
import { getParamsFromUrl } from "utils/getParamsFromUrl";
import "./ResearchRealEstate.scss";
import ResultsBox from "./results-box/ResultsBox";
import ResultsList from "./results-list/ResultsList";
import Map from "./results-map/map/Map";
import ResultsMap from "./results-map/ResultsMap";
import StatusBar from "./status-bar/StatusBar";

const initialSort = { value: "auction.date:asc", label: "Data asta" };

const cleanParams = (params) => {
  const copy = { ...params };
  delete copy.utm_campaign;
  delete copy.utm_medium;
  delete copy.utm_source;
  return copy;
};

const ResearchRealEstate = ({ location }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let params = Object.fromEntries(urlSearchParams.entries());
  params = cleanParams(params);
  const queryString = { value: params?.sort };
  const dispatch = useDispatch();
  const user =
    useSelector(({ user }) => user?.data?.user?.role) === "paying_user";
  const pagination = useSelector(({ realEstate }) => realEstate.pagination);
  const realEstates = useSelector(({ realEstate }) => realEstate.data);
  const loading = useSelector(({ realEstate }) => realEstate.loading);
  const [pageStart, setPageStart] = useState(0);
  const [sort, setSort] = useState(params?.sort ? queryString : initialSort);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [activeAdvancedFilters, setActiveAdvancedFilters] = useState(0);
  const viewResearch = useSelector(({ viewResearch }) => viewResearch);
  const barParams = getParamsFromUrl(location.search);
  const [assuredPartecipation, setAssuredPartecipation] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 678 });

  useEffect(() => {
    const searchBar = document.getElementById("wrapper-search-bar");
    const statusBar = document.getElementById("wrapper-status-bar");
    const results = document.getElementById("wrapper-results");
    const sticky = searchBar.offsetTop;

    document.addEventListener("scroll", () => {
      if (isDesktop && window.pageYOffset > sticky) {
        statusBar.classList.add("sticky");
        results.classList.add("sticky");
        searchBar.classList.add("sticky");
        searchBar.classList.remove("container-fluid");
      } else {
        statusBar.classList.remove("sticky");
        results.classList.remove("sticky");
        searchBar.classList.remove("sticky");
        searchBar.classList.add("container-fluid");
      }
    });
  }, [isDesktop]);

  const handleGetAllRealEstates = (params, loadMap = true) => {
    console.log("called");
    const newParams = {
      ...params,
      sort: sort.value,
      pageStart: pageStart + 1,
    };

    dispatch(
      getAllRealEstates({
        ...newParams,
        //commento limite dalla chiamata (arrivano tutti gli annunci di un determinato luogo ) STXPRT-20
        // limit: 12
      })
    );
    if (loadMap) {
      dispatch(getAllRealEstatesMap(newParams));
    }
  };

  const handleChangePage = (page) => {
    // if (page !== pageStart) {
    window.scrollTo(0, 0);
    setPageStart(page);
    // }
  };

  return (
    <div className="research-real-estate-page">
      <SearchBar
        search={handleGetAllRealEstates}
        advancedSearch={advancedSearch}
        toggleAdvanced={() => setAdvancedSearch((state) => !state)}
        closeAdvanced={() => setAdvancedSearch(false)}
        sort={sort.value}
        pageStart={pageStart}
        handleChangePage={handleChangePage}
        setActiveAdvancedFilters={setActiveAdvancedFilters}
        barParams={barParams}
        assuredPartecipation={assuredPartecipation}
        isDesktop={isDesktop}
      />

      <StatusBar
        search={handleGetAllRealEstates}
        pagination={pagination}
        advancedSearch={advancedSearch}
        toggleAdvanced={() => setAdvancedSearch((state) => !state)}
        activeAdvancedFilters={activeAdvancedFilters}
        sort={sort}
        onChange={setSort}
        params={params}
        assuredPartecipation={assuredPartecipation}
        setAssuredPartecipation={setAssuredPartecipation}
      />

      <div id="wrapper-results">
        {loading ? (
          <Spinner />
        ) : realEstates.length > 0 ? (
          isDesktop ? (
            <>
              {renderView(viewResearch)}

              {pagination.totalPages > 1 && (
                <Pagination
                  totalPages={pagination.totalPages}
                  handlePageClick={handleChangePage}
                  pageNumber={pageStart}
                />
              )}
            </>
          ) : viewResearch === "map" ? (
            <>
              <Map mobile={true} />
              <ResultsBox />
              {pagination.totalPages > 1 && (
                <Pagination
                  totalPages={pagination.totalPages}
                  handlePageClick={handleChangePage}
                  pageNumber={pageStart}
                />
              )}
            </>
          ) : (
            <>
              <ResultsBox />

              {pagination.totalPages > 1 && (
                <Pagination
                  totalPages={pagination.totalPages}
                  handlePageClick={handleChangePage}
                  pageNumber={pageStart}
                />
              )}
            </>
          )
        ) : (
          <div className="no-results">Nessun immobile trovato</div>
        )}
      </div>
    </div>
  );
};

export default ResearchRealEstate;

const renderView = (view) => {
  switch (view) {
    case "map":
      return <ResultsMap />;
    case "box":
      return <ResultsBox />;
    case "list":
      return <ResultsList />;
    default:
  }
};
