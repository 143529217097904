import Logo from "assets/logo/astaexpert.svg";
import { List, ShoppingBag, User } from "phosphor-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, NavLink, useLocation } from "react-router-dom";
import { logout } from "reducers/user";
import Dropdown from "./dropdown/Dropdown";
import HamburgerMenu from "./hamburger-menu/HamburgerMenu";
import "./Navbar.scss";
import { getOurTeamUSers } from "reducers/users";

const menuVoices = [
  {
    name: "Immobili",
    to: "/immobili",
    show: () => true,
  },
  {
    name: "Chi siamo",
    to: "/chi-siamo",
    show: () => true,
  },
  {
    name: "La nostra rete",
    to: "/la-nostra-rete",
    show: (team) => !!team.length,
  },
  {
    name: "Servizi",
    to: "/servizi",
    show: () => true,
  },
  {
    name: "Confronto e Risparmio",
    to: "/confronto-e-risparmio",
    show: () => true,
  },
  {
    name: "Blog",
    to: "/blog",
    show: () => true,
  },
  // {
  //   name: "Vantaggi",
  //   to: "/perche-astaexpert",
  //   dropdown: [
  //     {
  //       name: "Perché <h2 style='margin-left:4px'>asta</h2>éxpert",
  //       to: "/perche-astaexpert",
  //     },
  //     {
  //       name: "Per i Clienti",
  //       to: "/perche-astaexpert/per-i-clienti",
  //     },
  //     {
  //       name: "Per gli Agenti",
  //       to: "/perche-astaexpert/per-gli-agenti",
  //     },
  //   ],
  // },
];

const Navbar = () => {
  const { pathname } = useLocation();
  const [navbarColor, setNavbarColor] = useState(colorNavbar(pathname));
  const [hamburger, setHamburger] = useState(false);
  const dispatch = useDispatch();
  const cart = useSelector(({ cart }) => cart?.cart);
  const user = useSelector(({ user }) => user?.data);
  const authToken = user.authToken;
  const role = user.user?.role;
  const isDesktop = useMediaQuery({ minWidth: 1096 });

  const operators = useSelector(({ users }) => users.data.operators);
  const agentsAndLawyers = useSelector(
    ({ users }) => users.data.agentsAndLawyers
  );
  const team = [...operators, ...agentsAndLawyers];

  useEffect(
    () => setNavbarColor(colorNavbar(pathname.split("/")[1])),
    [pathname]
  );

  useEffect(() => dispatch(getOurTeamUSers()), []);

  const handleLogoutClick = async () => {
    await dispatch(logout());
    window.location.href = "/";
  };

  const account = {
    icon: <User />,
    name: "Il mio account",
    logged: true,

    dropdown: [
      {
        name: "Profilo",
        to: "/account",
      },
      {
        name: "Esci",
        function: handleLogoutClick,
      },
    ],
  };

  const toggleHamburger = () => {
    setHamburger((state) => !state);
    document.body.classList.toggle("no-scroll");
  };

  const goHome = () => hamburger && toggleHamburger();

  return (
    <div className="navbar" style={{ background: navbarColor }}>
      <div className="container-fluid">
        <Link onClick={goHome} to="/">
          <img src={Logo} alt="astaéxpert" />
        </Link>

        {isDesktop ? (
          <div className="navbar-items">
            {[...menuVoices.filter((item) => item.show(team)), account]
              .filter((value) => (!authToken ? !value.logged : value))
              .map((element, i) =>
                element.dropdown ? (
                  <Dropdown key={`${i}_${element.name}`} element={element} />
                ) : (
                  <NavLink key={`${i}_${element.name}`} to={element?.to}>
                    {element.name}
                  </NavLink>
                )
              )}
            {role !== "paying_user" && role !== "agent" && (
              <>
                {authToken || cart ? (
                  <NavLink
                    className="cart-link"
                    title="Carrello"
                    to="/carrello"
                  >
                    {cart && <div className="cart-dot">1</div>}
                    <ShoppingBag weight="bold" />
                  </NavLink>
                ) : (
                  <Link className="cart-link" title="Carrello" to="/inizia">
                    {cart && <div className="cart-dot">1</div>}
                    <ShoppingBag weight="bold" />
                  </Link>
                )}

                {role === "user" ? (
                  <NavLink to="/inizia" className="btn bold">
                    Premium
                  </NavLink>
                ) : (
                  <NavLink to="/accedi" className="btn bold">
                    Inizia
                  </NavLink>
                )}
              </>
            )}
          </div>
        ) : (
          <>
            <List
              className="hamburger"
              weight="bold"
              onClick={toggleHamburger}
            />

            <HamburgerMenu
              toggleVisibility={toggleHamburger}
              visibility={hamburger}
              color={navbarColor}
              logged={authToken}
              user={user}
              voices={[
                ...menuVoices.filter((item) => item.show(team)),
                account,
              ]}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;

const colorNavbar = (address) => {
  switch (address) {
    case "blog":
    case "faq":
    case "modulistica":
      return "var(--colorPink)";
    default:
      return "var(--colorBackground)";
  }
};
