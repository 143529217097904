import "./BannerPremium.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import requests from "api";
import { Fade } from "react-reveal";

const BannerPremium = () => {
  const [premium, setPremium] = useState({});

  useEffect(() => {
    const getPremium = async () => {
      const response = await requests.getPricePlans("user");
      if (response) {
        setPremium(response[0]);
      }
    };
    getPremium();
  }, []);

  return (
    <div className="banner-premium-component">
      <Fade>
        <h2 className="general">Trova il tuo affare</h2>

        {/* <div className="price">
          a soli <span>€ {premium?.price}</span> al mese
        </div> */}

        <Link className="btn big" to="/immobili">
          Cerca subito
        </Link>
      </Fade>
    </div>
  );
};

export default BannerPremium;
