import "./Social.scss";
import { Fragment } from "react";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { useLocation } from "react-router";
import configurations from "../../../../../configurations/index";

const base = configurations.frontendUrl;

const Social = () => {
  const { pathname } = useLocation();

  const social = [
    <FacebookShareButton url={base + pathname} children={<FaFacebook />} />,
    <TwitterShareButton url={base + pathname} children={<FaTwitter />} />,
    <LinkedinShareButton url={base + pathname} children={<FaLinkedin />} />,
  ];

  return (
    <div className="blog-single__social">
      {social.map((element, i) => (
        <Fragment key={i}>{element}</Fragment>
      ))}

      <span>Condividi</span>
    </div>
  );
};

export default Social;
