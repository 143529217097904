import { API_ROOT_URL, getAuthHeaders } from "./index";

const requests = {
  getAllFavorites: () => {
    return {
      url: `${API_ROOT_URL}/real_estate/favorite`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  addFavorite: (id) => {
    return {
      url: `${API_ROOT_URL}/real_estate/favorite`,
      method: "POST",
      headers: getAuthHeaders(),
      data: {
        realEstateId: id,
      },
    };
  },

  deleteFavorite: (id) => {
    return {
      url: `${API_ROOT_URL}/real_estate/favorite`,
      method: "DELETE",
      headers: getAuthHeaders(),
      data: {
        realEstateId: id,
      },
    };
  },
};

export default requests;
