import "./Spinner.scss";

const Spinner = () => (
  <div className="spinner-component">
    <div className="spinner"></div>
  </div>
);

export default Spinner;

export const SpinnerSmall = () => (
  <div className="spinner-component-small">
    <div className="spinner"></div>
  </div>
);
