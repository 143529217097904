import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "moment/locale/it";
import "styles/general.scss";
import ScrollToTop from "utils/scrollToTop";
import MyHelmet from "utils/MyHelmet";
import { LoadScript } from "@react-google-maps/api";
import SwiperCore, { Navigation, Keyboard } from "swiper/core";
import { useEffect } from "react";
import { getCart } from "reducers/cart";
import { getAllFavorites } from "reducers/favorites";
import { getAllVisits } from "reducers/visits";
import { loginUser } from "reducers/user";
import { getAllParticipations } from "reducers/participations";
import { getAllFavoriteResearches } from "reducers/researches";
import Navbar from "components/navbar/Navbar";
import Footer from "components/footer/Footer";
import Home from "pages/home/Home";
import AboutUs from "pages/about-us/AboutUs";
import Services from "pages/services/Services";
import WhyAsta from "pages/why-asta/general/WhyAsta";
import ForCustomers from "pages/why-asta/for-customers/ForCustomers";
import ForAgents from "pages/why-asta/for-agents/ForAgents";
import Blog from "pages/blog/Blog";
import BlogSingle from "pages/blog/blog-single/BlogSingle";
import Faq from "pages/faq/Faq";
import Forms from "pages/forms/Forms";
import ContactUs from "pages/contact-us/ContactUs";
import GetStarted from "pages/get-started/GetStarted";
import Login from "pages/login/Login";
import SignUp from "pages/signup/SignUp";
import ResearchRealEstate from "pages/research-real-estate/ResearchRealEstate";
import InfoRealEstate from "pages/info-real-estate/InfoRealEstate";
import RealEstateNotFound from "pages/real-estate-not-found/RealEstateNotFound";
import Cart from "pages/cart/Cart";
import PaymentSuccess from "pages/payments-feedback/PaymentSuccess";
import PaymentFailure from "pages/payments-feedback/PaymentFailure";
import PaymentCancel from "pages/payments-feedback/PaymentCancel";
import Calendar from "pages/calendar/Calendar";
import Error from "pages/error/Error";
import PrivateRoute from "utils/PrivateRoute";
import Account from "pages/account/Account";
import ComparisonSavings from "pages/comparison-savings/ComparisonSavings";
import GdprPage from "pages/gdpr/gdprPage";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ForLawers from "pages/why-asta/for-lawers/ForLawers";
import OurTeam from "pages/our-team/OurTeam";
SwiperCore.use([Navigation, Keyboard]);

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.data?.user?.role);
  // const { pathname } = useLocation();

  useEffect(() => {
    dispatch(loginUser());
    dispatch(getCart());
    if (localStorage.getItem("user") && user === "paying_user") {
      dispatch(getAllFavorites());
      dispatch(getAllFavoriteResearches());
      dispatch(getAllVisits());
      dispatch(getAllParticipations());
    }
  }, [dispatch, user]);

  // useEffect(() => {
  //   console.log("PATHNAME", window.location.pathname);
  // }, [window.location.pathname]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <MyHelmet />

      <Navbar />
      <LoadScript
        loadingElement={<div></div>}
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS}
        language="it"
      >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/chi-siamo" component={AboutUs} />
          <Route path="/la-nostra-rete" component={OurTeam} />
          <Route path="/servizi" component={Services} />
          <Route path="/confronto-e-risparmio" component={ComparisonSavings} />
          <Route
            path="/perche-astaexpert/per-i-clienti"
            component={ForCustomers}
          />
          <Route
            path="/perche-astaexpert/per-gli-agenti"
            component={ForAgents}
          />
          <Route
            path="/perche-astaexpert/per-gli-avvocati"
            component={ForLawers}
          />
          <Route path="/perche-astaexpert" component={WhyAsta} />
          <Route path="/blog/:id" component={BlogSingle} />
          <Route path="/blog" component={Blog} />
          <Route path="/faq" component={Faq} />
          <Route path="/modulistica" component={Forms} />
          <Route path="/contattaci" component={ContactUs} />
          <Route path="/immobile-non-trovato" component={RealEstateNotFound} />
          <Route path="/immobile/:id" component={InfoRealEstate} />
          <Route path="/immobili" component={ResearchRealEstate} />
          <Route path="/inizia" component={GetStarted} />
          <Route path="/calendario-aste" component={Calendar} />
          <Route path="/accedi" component={Login} />
          <Route path="/registrati" component={SignUp} />
          <Route path="/carrello" component={Cart} />
          <Route path="/payment-success" component={PaymentSuccess} />
          <Route path="/payment-failure" component={PaymentFailure} />
          <Route path="/payment-cancel" component={PaymentCancel} />
          <Route path="/privacy-policy" component={GdprPage} />
          <Route path="/cookies" component={GdprPage} />
          <Route path="/termini-e-condizioni-pro" component={GdprPage} />
          <Route path="/termini-e-condizioni-premium" component={GdprPage} />
          <PrivateRoute path="/account" children={<Account />} />
          <Route path="/pagina-non-trovata" component={Error} />
          <Redirect to="/pagina-non-trovata" />
        </Switch>
      </LoadScript>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
