import "./WhyAsta.scss";
import { benefits } from "./items";
import Hero from "./hero/Hero";
import Purpose from "./purpose/Purpose";
import Introduction from "./introduction/Introduction";
import BannerBenefits from "components/banner-benefits/BannerBenefits";
import Benefits from "./benefits/Benefits";
import { Fade } from "react-reveal";

const WhyAsta = () => (
  <div className="why-asta-page">
    {/* <Fade> */}
    <Hero />

    <Purpose />

    <Introduction />

    <BannerBenefits benefits={benefits} />

    <Benefits />
    {/* </Fade> */}
  </div>
);

export default WhyAsta;
